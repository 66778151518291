
import { defineComponent, reactive, onMounted, computed, ref, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter } from "vue-router";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SignaturePadModal from "@/components/modal/SignaturePadModal.vue";
import SignatureUploadModal from "@/components/modal/SignatureUploadModal.vue";
export default defineComponent({
    name: "JobEstimationInvitationDetails",
    components: {
        SuccessfulModalCustom,
        ErrorList,
        SignaturePadModal,
        SignatureUploadModal
    },
    setup() {
        const router = useRouter();
        const {
            params: { estimationId },
            query: { uuid: estimationUuid }
        } = useRoute();
        const isInvalid = ref(false);
        const canvas: any = ref(null);
        const state = reactive({
            selectItems: [] as any,
            selectedCustomers: [] as any,
            singedCustomers: [] as any,
            estimationId: estimationId,
            selectUnitId: '' as any,
            selectSubItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectSubItem: '' as any,
            approveByAll: false,
            sendData: {} as any,
            companyData: {} as any,
            mainItem: '' as any,
            // projectUuid:'' as any,
            // estimationUuid:'' as any,
            estimationUuid: '',
            projectUuid: '',
            projectId: '' as any,
            projectName: '' as any,
            selectedDate: '' as any,
            // eslint-disable-next-line @typescript-eslint/camelcase
            prepared_by: '' as any,
            // eslint-disable-next-line @typescript-eslint/camelcase
            pdf_link: '' as any,
            fields: {

            } as any,
            isErrorStatus: false,
            errorList: [] as any,
            data: {
                estimationDetails: {} as any,
                haveProfileSign: false,
                useProfileSign: false,
                showSignaturePad: false,
                showSignatureUpload: false,
                uploadSignatureFile: "" as any,
                cropImage: "",
                newUploadImge: "" as any,
                cropSignature: "" as any,
                signatureLink: "" as any,
                lineItems: [] as any,
                unitLists: [] as any,
                signatureFileName: "" as any,
                declineMessage: "" as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
                selectedCustomers: [] as any,
                allCustomer: [] as any,
                sendarSignature: "" as any,
                uploadSignature: "" as any,
                showSuccesCustomModal: false,
                successFulMessage: '',
                // eslint-disable-next-line @typescript-eslint/camelcase
                signature_link: '' as any,
                isEstimationAccepted: false,
            },
            ui: {
                isLoading: {
                    isEdit: false,
                    getItemList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    signaturePadLoad: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                    checkSignature: false,
                    acceptJobEstimationInvitation: false,
                    declineInvitation: false,
                    isResonRequired: false,
                    redirectToChat:false,
                },
                errors: {
                    errorList: {
                        createUnit: [] as any,
                        acceptJobEstimationInvitation: [] as any,
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    hasError: false,
                },
            },
        });
        // watch();
        function gotoListJobEstimation() {
            router.push({
                name: "admin-invitation",
            });
        }
        


        function closeDecline() {
            $("#declineInvite").modal("hide");
        }

        function showDeclineModal() {
            state.data.declineMessage = "";
            $("#declineInvite").modal("show");
        }



        function readImage(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.file = e.target.result;
                data.name = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                console.log("CHECK DATA FOR UPLOAD>>", data);
                // state.imageList.push(data);
            };
            reader.readAsDataURL(file);
        }
        const dropFiles = (event: any) => {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                readImage(file);
            }
        };

        function readSignature(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.base64 = e.target.result;
                data.duplicateFile = false; // data.fileName = file.name;
                data.fileName = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                data.isuploded = false;
                data.tag = [] as any;
                data.size = common.formatBytes(parseInt(file.size));
                // state.data.uploadImageData = data;
                state.data.newUploadImge = data.base64;
                state.data.cropImage = data.base64;
                state.data.signatureFileName = data.fileName;
            };
            reader.readAsDataURL(file);
        }

        function onDocumentChange(event: any) {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // console.log("FILE CHECK >>>", file);
                // if(file.type)
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                readSignature(file);
                $("#updateUpload").modal("show");
            }
        }

        function showUploadImageModel() {
            $("#updateUpload").modal("show");
        }

        function hideShowUploadImageModel() {
            $("#profileUpdate").val("");
            $("#updateUpload").modal("hide");
        }


        function saveSignature() {
            hideShowUploadImageModel();
        }

        function clearSignature() {
            state.data.cropSignature = "";

        }

        function enableSignaturPad() {

            state.ui.isLoading.signaturePadLoad = !state.ui.isLoading.signaturePadLoad;
        }



        function getJobestimationDetail() {
            state.ui.isLoading.getItemList = true
            ethitransService
                .getJobestimationInviteDetail(estimationId)
                .then((res: any) => {
                console.log('The get job estimation detail is:', res.data.data)
                    state.selectItems = [];
                    state.sendData = res.data.data;
                    state.projectUuid = res.data.data.project.uuid;
                    state.estimationUuid = res.data.data.uuid;
                    state.projectId = res.data.data.project_id;
                    state.projectName = res.data.data.project.name;
                    state.selectedDate = common.formatDateAndTime(res.data.data.created_date);
                    // state.selectedDate = res.data.data.created_date;
                    state.fields = res.data.data.fields;
                    state.selectedCustomers = res.data.data.customers;
                    state.singedCustomers = res.data.data.singed_customers;
                    state.singedCustomers.forEach((customer: any) => {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        customer.signaute_date = common.localDateOnly(customer.signaute_date);
                    })
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.sendData.signature_date = common.localDateOnly(state.sendData.signature_date);
                    // state.data.createDate = moment(res.data.data.created_date).format("Y-MM-DD");
                    res.data.data.items.forEach((subItem: any) => {
                        state.mainItem = subItem.mainitem;
                        subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                        if (subItem.markup_type == 1) {
                            const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
                            subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                            subItem.total = common.usdPriceFormate(subItem.total);
                            subItem.markup = common.takeLimitDigits(subItem.markup ? subItem.markup : 0);

                        } else {
                            subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
                            subItem.total = common.usdPriceFormate(subItem.total);
                            subItem.markup = common.usdPriceFormate(subItem.markup ? subItem.markup : 0);
                        }
                        subItem.cost = common.usdPriceFormate(subItem.cost);
                        subItem.allowance = common.usdPriceFormate(subItem.allowance ? subItem.allowance : 0);
                    });
                    state.selectItems = res.data.data.items;
                    state.data.estimationDetails = JSON.parse(JSON.stringify(res.data.data.items));
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.prepared_by = res.data.data.prepared_by;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.pdf_link = res.data.data.pdf_link;
                })
                .catch((error: any) => {
                    common.sdCatchErr(error, state.errorList).then((res: any) => {
                        console.log(res);
                        state.isErrorStatus = true;

                        for (const [key, value] of Object.entries(res)) {
                            if (Array.isArray(value)) {
                                router.push({
                                    name: "admin-invitation",
                                });
                                state.errorList.push(value[0]);
                            }
                        }
                    });
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getItemList = false;
                });
        }

        function addCustomerEmail(data: any) {
            isInvalid.value = false;
            const email = data;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email == localStorage.getItem("current_user_email")) {
                isInvalid.value = true;
                return false;
            }
            if (!emailRegex.test(email)) {
                isInvalid.value = true;
                return false;
            }
            const addData = {
                email: data,
                code: data,
            };
            state.data.allCustomer.push(addData);
            state.data.selectedCustomers.push(addData);
            //  state.data.allCustomer.push(data);
        }


        function changeValue() {
            isInvalid.value = false;
        }

        function closeSuccessCustomModal() {
            $("#SuccessfulModalCustom").modal("hide");
            gotoListJobEstimation();
        }


        function validateSendInvitation() {
            state.ui.errors.errorList.acceptJobEstimationInvitation = [];
            state.ui.errors.hasError = false;

            if (!state.data.uploadSignature && !state.data.signatureLink) {
                state.ui.errors.errorList.acceptJobEstimationInvitation.push("Use/Upload/Draw Your valid Signature");
                state.ui.isPostLoading.checkSignature = true;
            }

            if (
                !state.ui.errors.errorList.acceptJobEstimationInvitation &&
                !state.ui.errors.errorList.acceptJobEstimationInvitation.length
            ) {
                state.ui.errors.hasError = false;
            } else if (state.ui.errors.errorList.acceptJobEstimationInvitation.length != 0) {
                state.ui.errors.hasError = true;
            }
        }

        function acceptJobEstimation() {
            state.ui.isPostLoading.acceptJobEstimationInvitation = false;
            state.ui.errors.hasError = false;
            validateSendInvitation();
            if (state.ui.isPostLoading.acceptJobEstimationInvitation || state.ui.errors.hasError) return false;
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                estimation_id: state.sendData.uuid,
                signature: state.data.uploadSignature,
                // eslint-disable-next-line @typescript-eslint/camelcase
                signature_link: state.data.signatureLink

            };
            state.ui.isPostLoading.acceptJobEstimationInvitation = true;
            ethitransService
                .acceptJobEstimationInvitation(payLoad)
                .then((res: any) => {
                    console.log(res);
                    state.data.isEstimationAccepted = true;
                    state.data.successFulMessage = "Accept Job Estimation Successfully.";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.acceptJobEstimationInvitation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.acceptJobEstimationInvitation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.acceptJobEstimationInvitation = false;

                });
        }


        function declineInvitation() {
            state.ui.isPostLoading.isResonRequired = false;
            if (!state.data.declineMessage) {
                state.ui.isPostLoading.isResonRequired = true;
                return false;
            }
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                estimation_id: state.sendData.uuid,
                description: state.data.declineMessage,
            };
            state.ui.isPostLoading.declineInvitation = true;
            ethitransService
                .declineJobEstimationInvitation(payLoad)
                .then((res: any) => {
                    closeDecline();
                    state.data.successFulMessage = "Invitation Declined";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isPostLoading.declineInvitation = false;
                });
        }

        function redirectToChat() {
        state.ui.errors.hasError = false;
        if (state.ui.isPostLoading.redirectToChat) return false;
        state.ui.isPostLoading.redirectToChat = true;
        ethitransService
            .estimationDiscussionChannel(estimationId.toString())
            .then((res: any) => {
            router.push({
                name: 'admin-channel',
                params: { channelId: res.data.data.channel.uuid.toString() },
            });
            console.log("Acknowledge Project Response:>>> ", res.data.data.channel);
            })
            .catch((error: any) => {
            common
                .sdCatchErr(
                error,
                state.ui.errors.errorList.acceptJobEstimationInvitation
                )
                .then((res: any) => {
                state.ui.errors.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                    if (Array.isArray(value)) {
                    state.ui.errors.errorList.acceptJobEstimationInvitation.push(
                        value[0]
                    );
                    }
                }
                });
            })
            .finally(() => {
            state.ui.isPostLoading.redirectToChat = false;
            });
        }

        function redirectToDashboard() {
            closeSuccessCustomModal();
            
      router.push({
        name: "admin-dashboard",
      });
    }

        function redirectToEstimationList() {
        closeSuccessCustomModal();
        router.push({
            name: "list-job-estimation",
            query: {
                uuid: state.estimationUuid,
            },
            params: {
                projectId: state.projectUuid,
            }
        });
    }
    // for new design
    // function redirectToEstimationList() {
    //     closeSuccessCustomModal();
    //     router.push({
    //         name: "admin-project-details-new",
    //         query: {
    //             // uuid: state.estimationUuid,
    //             tab: "jobEstimation"
    //         },
    //         params: {
    //             projectId: state.projectUuid,
    //         }
    //     });
    // }

        const totalCost = computed(() => {
            // return state.selectItems.reduce((total: any, item: any) => total + item.total, 0);
            const total = state.selectItems.reduce((total: any, item: any) => total + parseFloat(item.total.replace(/[$,]/g, '').trim()), 0);
            return common.usdPriceFormate(total);
        });

        const totalTd = computed(() => {
            return Object.values(state.fields).filter(value => value === 1).length;
        });

        function showSignaturePad() {
            state.data.showSignaturePad = !state.data.showSignaturePad;
        }

        function hideSignaturePad() {
            state.data.showSignaturePad = !state.data.showSignaturePad;
            $("#signaturePadModal").modal("hide");
        }

        function hideSignatureUpload() {
            state.data.showSignatureUpload = false;
            $("#signatureUploadModal").modal("hide");
        }
        function uploadSignature(data: any) {
            state.data.signatureLink = "";
            state.data.cropSignature = data;
            state.data.uploadSignature = data;
            state.data.useProfileSign = false;
        }

        function uploadSignatureFile(event: any) {
            state.data.showSignatureUpload = true;
            state.data.useProfileSign = false;
            state.data.uploadSignatureFile = event.target.files || event.dataTransfer.files;
        }

        function userSignature(from: any) {
            ethitransService
                .userSignature()
                .then((res: any) => {

                    if (from == 'onMount' && res.data.data.signature) {
                        state.data.haveProfileSign = true;
                    }
                    if (from == 'use' && res.data.data.signature) {
                        state.data.useProfileSign = true;
                        state.data.uploadSignature = "";
                        state.data.cropSignature = res.data.data.signature;
                        state.data.signatureLink = res.data.data.signature;
                    }
                })
                .catch((error: any) => {
                    //   common
                })
        }

        onMounted(() => {
            getJobestimationDetail();
            state.data.haveProfileSign = false;
            userSignature('onMount');

        });
        return {
            state,
            getJobestimationDetail,
            changeValue,
            isInvalid,
            addCustomerEmail,
            canvas,
            clearSignature,
            readImage,
            readSignature,
            onDocumentChange,
            showUploadImageModel,
            hideShowUploadImageModel,
            saveSignature,
            enableSignaturPad,
            closeSuccessCustomModal,
            validateSendInvitation,
            gotoListJobEstimation,
            totalCost,
            declineInvitation,
            closeDecline,
            showDeclineModal,
            acceptJobEstimation,
            totalTd,
            showSignaturePad,
            hideSignaturePad,
            uploadSignature,
            uploadSignatureFile,
            hideSignatureUpload,
            userSignature,
            redirectToDashboard,
            redirectToEstimationList,
            redirectToChat,
        };
    },
});
