
import { defineComponent, reactive, onMounted, computed, watch, ref, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import eventBus from "@/eventBus";

import { ethitransService } from "@/services/EthitransService";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";
import ProjectWorkflowListJobEstimation from "./ProjectWorkflowListJobEstimation.vue";
import ProjectWorkflowCreateJobEstimation from "./ProjectWorkflowCreateJobEstimation.vue";
import ProjectWorkflowSendJobEstimation from "./ProjectWorkflowSendJobEstimation.vue";
import ProjectWorkflowSmartContractList from "./ProjectWorkflowSmartContractList.vue";
import ProjectWorkflowSendEstimationInvitaion from "./ProjectWorkflowSendEstimationInvitaion.vue";

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Pusher from "pusher-js";
import { useStore } from "vuex";
import introJs from "intro.js";
import state from "pusher-js/types/src/core/http/state";
import { useCookie } from 'vue-cookie-next'
import { useI18n } from 'vue-i18n';
import ProjectWorkflowUpdateJobEstimation from "./ProjectWorkflowUpdateJobEstimation.vue";
import ProjectWorkflowSmartContractPreview from "./ProjectWorkflowSmartContractPreview.vue";
import ProjectWorkflowSendSmartContract from "./ProjectWorkflowSendSmartContract.vue";
import ProjectWorkflowToDoList from "./ProjectWorkflowToDoList.vue";
import ProjectWorkflowToDoPhaseAddMember from "./ProjectWorkflowToDoPhaseAddMember.vue";
import ProjectWorkflowToDoDetails from "./ProjectWorkflowToDoDetails.vue";
import ProjectWorkflowInvoiceList from "./ProjectWorkflowInvoiceList.vue"
import ProjectWorkflowList from "./ProjectWorkflowList.vue";
import ProjectWorkflowToDoPhaseInvoice from "./ProjectWorkflowToDoPhaseInvoice.vue";
import ProjectWorkflowToDoTaskLogList from "./ProjectWorkflowToDoTaskLogList.vue";
import ProjectWorkflowEditTask from "./ProjectWorkflowEditTask.vue";

export default defineComponent({
  name: "ProjectDetailDashboard",
  props: {
    msg: String,
  },
  components: {
    ProjectWorkflowListJobEstimation,
    ProjectWorkflowCreateJobEstimation,
    ProjectWorkflowSendJobEstimation,
    ProjectWorkflowUpdateJobEstimation,
    ProjectWorkflowSmartContractList,
    ProjectWorkflowSendEstimationInvitaion,
    ProjectWorkflowSmartContractPreview,
    ProjectWorkflowSendSmartContract,
    ProjectWorkflowToDoList,
    ProjectWorkflowToDoPhaseAddMember,
    ProjectWorkflowToDoDetails,
    ProjectWorkflowInvoiceList,
    ProjectWorkflowList,
    ProjectWorkflowToDoPhaseInvoice,
    ProjectWorkflowToDoTaskLogList,
    ProjectWorkflowEditTask
    // EmptyData,
    // ErrorList,

  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const showCreateJobEstimation = ref(false);
    const showSendJobEstimation = ref(false);
    const estimationId = ref<string>('');
    const projectId = route.params.projectId as string;
    const state = reactive({
      activePhaseId: '' as any,
      activeTaskId: '' as any,
      phaseDetails: '' as any,
      status: '' as any,
      data: {
        projectRole: '' as any,

      },

      fields: {

      } as any,
      projectId: projectId,
      hasShownDetails: false,
      selectUnitId: '' as any,
      selectJobEstimationItemIndex: '' as any,
      selectItemIndex: '' as any,
      selectJobEstimationItem: '' as any,
      workflowList: [] as any[],
      isLoading: {
        getUserList: false,
        getProjectDetail: false,
        getProjectRoleList: false,
      },
      postLoading: {
        getUserList: false,
        changeProjectRole: false,
        removeProjectRole: false,
        getProjectDetail: false,

      },
    });

    const activeTab = ref("chat");
    const activeSubTab = ref<string>("jobEstimation");
    const activeComponent = ref('listJobEstimation');


    const currentEstimationId = ref(null);
    const currentUuid = ref(null);
    const previousComponent = ref(""); 


    const switchTab = (tabName: string) => {
      activeTab.value = tabName;
      if (tabName === "workflow") {
        activeSubTab.value = "jobEstimation";
      }
    };

    const openCreateJobEstimation = () => {
      activeComponent.value = 'createJobEstimation';
    };


    const openSendJobEstimation = (estimationId: any) => {
      currentEstimationId.value = estimationId;
      activeComponent.value = 'sendJobEstimation';
    };


    const openListJobEstimation = () => {
      activeComponent.value = 'listJobEstimation';
      currentEstimationId.value = null;
    };


    const redirectListJobEstimation = () => {
      activeSubTab.value = 'jobEstimation';
      activeComponent.value = 'listJobEstimation';
      // currentEstimationId.value = null;
    };

    const openListSmartContract = () => {
      activeComponent.value = 'listSmartContract';
      if (route.query.contractId && route.query.estimationId) {
        router.replace({
          query: {
            ...route.query,
            contractId: undefined,
            estimationId: undefined
          },
        });
      }
      // currentEstimationId.value = null;
    };

    const openEditJobEstimation = (itemId: any) => {
      currentEstimationId.value = itemId;
      activeComponent.value = "createJobEstimation";
    };

    const openUpdateJobEstimation = (itemId: any) => {
      currentEstimationId.value = itemId;
      activeComponent.value = "updateJobEstimation";
    };
    const openSmartContractPreview = (contractId: any) => {
      currentEstimationId.value = contractId;
      activeComponent.value = 'smartContractPreview';
    };
    const handleCreateEstimationInvitation = (uuid: any) => {
      currentEstimationId.value = uuid;
      activeSubTab.value = 'smartContract';
      activeComponent.value = 'sendEstimationInvitation';
    };
    const openSendSmartContract = (data: any) => {
      // currentEstimationId.value = contractId;
      // currentUuid.value = estimationId;
      activeComponent.value = 'sendSmartContract';
    };

    const openToDoList = (uuid: any, back: string) => {
      currentUuid.value = uuid;
      previousComponent.value = back;
      activeSubTab.value = 'subWorkFlow';
      activeComponent.value = 'toDoList';
    };

    const openToDoTaskLogList = (phaseId: any, id: any) => {
      state.activePhaseId  = phaseId;
      state.activeTaskId = id;
      activeSubTab.value = 'subWorkFlow';
      activeComponent.value = 'toDoTaskLogList';
    };

    const openToDoPhaseInvoice = (id: any) => {
      currentUuid.value = id;
      activeSubTab.value = 'invoices';
      activeComponent.value = 'toDoPhaseInvoice';
    };


    const switchWorkflowTab = (subTabName: string, uuid?: string) => {
      console.log('Switching to tab:', subTabName);
      activeSubTab.value = subTabName;
      console.log('The Active tab is:', activeSubTab.value)

      if (subTabName === "jobEstimation") {
        activeComponent.value = "listJobEstimation";
      } else if (subTabName === "smartContract") {
        activeComponent.value = "listSmartContract";
      } else if (subTabName === "invoices") {
        activeComponent.value = "invoiceList";
      }
      else if (subTabName === "subWorkFlow") {
        activeComponent.value = "workflowList";
        console.log('Tha active subTab in subWorkflow is>>>', activeComponent.value)
      }
    };


    const openToDoPhaseAddMember = (phaseId: any, taskId: any) => {
      console.log("Activating ToDoPhaseAddMember for Phase ID:", phaseId);
      activeComponent.value = "toDoPhaseAddMember";
      state.activePhaseId = phaseId;
      state.activeTaskId = taskId;
    };

    const openToDoDetails = (phaseId: any) => {
      activeComponent.value = "toDoDetails";
      console.log('The active component is>>>', activeComponent.value)
      state.activePhaseId = phaseId;
    }

    const openEditTask = (taskId: any) => {
      console.log('The Task Id is>>', taskId)
      activeComponent.value = "editTask";
      state.activeTaskId = taskId;
      console.log('The state activeTaskid is::', state.activeTaskId)
    }

    const backFromToDoList = () => {
      activeComponent.value = previousComponent.value;
      activeSubTab.value = 'smartContract';
      activeComponent.value = 'listSmartContract';
      // currentEstimationId.value = null;
    };
    const backFromToDoDetails = () => {
      activeComponent.value = "toDoList";
    }

    const updateActiveTab = () => {
      const tab = route.query.tab;
      console.log('The tab is>>>>>>>>', tab)
      if (tab === "smartContract") {
        activeTab.value = "workflow";
        activeSubTab.value = "smartContract";
        activeComponent.value = "listSmartContract";
      }
      else if (tab === "jobEstimation") {
        activeTab.value = "workflow";
        activeSubTab.value = "jobEstimation";
        activeComponent.value = "listJobEstimation";
      }
      router.replace({
        query: {
          ...route.query,
          tab: undefined,
        },
      });
    };

    const handleGoBack = ({ pathName, uuid }: { pathName: string; uuid: string }) => {
      if (pathName === "user-todo-list") {
        activeComponent.value = 'toDoList';
      }
      else if (pathName === "smart-contract-list") {
        activeSubTab.value = 'smartContract';
        activeComponent.value = 'listSmartContract';
      }
      else if (pathName === "workflow-list") {
        // activeSubTab.value = 'smartContract';
        activeComponent.value = 'workflowList';
      }
      else if (pathName === "user-todo-details") {
        activeSubTab.value = 'subWorkFlow';
        activeComponent.value = 'toDoDetails';
      }
    };

    function getProjectDetail() {
      state.isLoading.getProjectDetail = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
      };
      ethitransService
        .projectDetails(payLoad)
        .then((res: any) => {
          state.data.projectRole = res.data.data.project_role.code;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getProjectDetail = false;
          router.replace({ query: {} });
        });
    }


    onMounted(() => {
      getProjectDetail();
      eventBus.on("user-todo-add-member", openToDoPhaseAddMember);
      eventBus.on("user-todo-details", openToDoDetails);
      eventBus.on("user-todo-task-log-details", openToDoTaskLogList);
      eventBus.on("edit-task", openEditTask);
      eventBus.on("go-back", handleGoBack);
      if (route.query.tab) {
        updateActiveTab();
      }
    });

    onUnmounted(() => {
      eventBus.off("user-todo-add-member", openToDoPhaseAddMember);
      eventBus.off("user-todo-details", openToDoDetails);
      eventBus.off("user-todo-task-log-details", openToDoTaskLogList);
      eventBus.off("go-back", handleGoBack);
      eventBus.off("edit-task", openEditTask);

    });



    return {
      state,
      activeSubTab,
      switchWorkflowTab,
      projectId,
      estimationId,
      activeComponent,
      currentEstimationId,
      currentUuid,
      openCreateJobEstimation,
      openSendJobEstimation,
      openListJobEstimation,
      openEditJobEstimation,
      openUpdateJobEstimation,
      handleCreateEstimationInvitation,
      openListSmartContract,
      openSmartContractPreview,
      redirectListJobEstimation,
      openSendSmartContract,
      updateActiveTab,
      activeTab,
      switchTab,
      openToDoList,
      backFromToDoList,
      openToDoPhaseAddMember,
      backFromToDoDetails,
      previousComponent,
      openToDoPhaseInvoice,
      getProjectDetail,
      openToDoTaskLogList,
      openEditTask


    };
  },
});
