
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { config } from "@/Config";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import Pusher from "pusher-js";
import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'



export default defineComponent({
    name: "ListJobEstimation",
    components: {
        EmptyData,
        UpgradeSubscriptionModal,
    },
    setup() {
        const router = useRouter();
        const {
            params: { projectId },
            query: { uuid }
        } = useRoute();
        console.log("Extracted projectId from route params:", projectId);
        console.log("Extracted uuid from route query:", uuid);

        const isInvalid = ref(false);
        const state = reactive({
            status: '' as any,
            fields: {

            } as any,
            projectId: projectId,
            uuid: uuid,
            hasShownDetails: false,
            selectUnitId: '' as any,
            selectJobEstimationItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectJobEstimationItem: '' as any,
            data: {
                subscriptionDetails: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    appointment_numbers: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    archive_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_file_size: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_message_history: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    estimation_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    export_channel_pdf: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    files_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    folder_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    guests_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    import_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    invoice_payment: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_in_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_per_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    members_per_appointment: 0,
                    name: "Silver",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    pdf_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phase_per_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    private_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    project_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    sync_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    task_per_phase: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    template_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_channel_member: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_estimation: 0,
                } as any,
                role: '' as any,
                title: "data found",
                headings: "Job Estimation",
                note: '' as any,
                jobEstimationLists: [] as any,
                unitLists: [] as any,
                details: {} as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
                companyInfo: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_name: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_email: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_phone_number: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_address: "",
                    acknowledgement: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    legal_statement: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    logo: "",
                },
            },
            ui: {
                isActive: {
                    upgradeSubscription: false,
                },
                isLoading: {
                    packageDetailsByUser: false,
                    isEdit: false,
                    geJobEstimationList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    pusherUpdate: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                },
                errors: {
                    errorList: [] as any,
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    hasError: false,
                },
            },
        });

        function gotoCreateJobEstimation() {
            if (state.data.jobEstimationLists.length >= state.data.subscriptionDetails.total_estimation) {
                // if (state.data.subscriptionDetails.total_estimation >= 20000) {
                state.ui.isActive.upgradeSubscription = true;
            } else {
                router.push({
                    name: "create-job-estimation",
                    params: { projectId: state.projectId }
                });
            }
        }

        function editJobEstimation(itemId: any) {
            router.push({
                name: "edit-job-estimation",
                params: { projectId: state.projectId },
                query: { id: itemId }
            });
        }

        function updateJobEstimation(itemId: any) {
            router.push({
                name: "update-job-estimation",
                query: { id: itemId }
            });
        }

        function geJobEstimationList() {
            state.ui.isLoading.geJobEstimationList = true
            ethitransService
                .geJobEstimationList(state.projectId)
                .then((res: any) => {
                    console.log('The getGobEstimation list is:', res.data.data)
                    res.data.data.estimations.forEach((estimate: any) => {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        estimate.modifiedCreatedDate = common.formatDateAndTime(estimate.created_at)
                    })
                    state.data.jobEstimationLists = res.data.data.estimations;
                    state.data.role = res.data.data.project_role;
                    //     console.log("Current value of hasShownDetails:", state.hasShownDetails);
                    //                 if (state.uuid && !state.hasShownDetails) {
                    //     const matchedEstimation = state.data.jobEstimationLists.find(
                    //     (item: any) => item.uuid === state.uuid
                    //     );
                    //     if (matchedEstimation) {
                    //     detailsShow(matchedEstimation);
                    //     state.hasShownDetails = true; 
                    //     }
                    //     console.log("updated value of hasShownDetails:", state.hasShownDetails);
                    // }
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.geJobEstimationList = false;
                });
        }

        function geJobEstimationDetails(uuid: any) {
            state.data.details = {};
            state.ui.isLoading.geJobEstimationList = true
            ethitransService
                .geJobEstimationDetailsPreview(uuid)
                .then((res: any) => {
                    state.fields = res.data.data.fields;
                    res.data.data.total = 0;
                    state.data.note = res.data.data.note;
                    res.data.data.singed_customers.forEach((customer: any) => {
                        // eslint-disable-next-line @typescript-eslint/camelcase  
                        customer.signaute_date = common.localDateOnly(customer.signaute_date);
                    })
                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    res.data.data.signature_date = common.localDateOnly(res.data.data.signature_date ? res.data.data.signature_date : '');
                    res.data.data.phases.forEach((item: any) => {
                        item.sub_items.forEach((subItem: any) => {
                            subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                            if (subItem.markup_type == 1) {
                                const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
                                subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                                res.data.data.total += subItem.total;
                                subItem.total = common.usdPriceFormate(subItem.total);
                                subItem.markup = common.takeLimitDigits(subItem.markup ? subItem.markup : 0);
                            } else {
                                subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
                                res.data.data.total += subItem.total;
                                subItem.total = common.usdPriceFormate(subItem.total);
                                subItem.markup = common.usdPriceFormate(subItem.markup ? subItem.markup : 0);
                            }
                            subItem.subTotal = (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                            subItem.subTotal = common.usdPriceFormate(subItem.subTotal);
                            subItem.cost = common.usdPriceFormate(subItem.cost);
                            subItem.allowance = subItem.allowance ? common.usdPriceFormate(subItem.allowance) : '-';
                        });
                    });
                    res.data.data.total = common.usdPriceFormate(res.data.data.total);
                    res.data.data.modifiedCreatedDate = common.formatDateAndTime(res.data.data.created_at);
                    state.data.details = res.data.data;


                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.geJobEstimationList = false;
                });
        }

        function deleteJobEstimation() {

            ethitransService
                .deleteJobEstimation(state.selectJobEstimationItem.uuid)
                .then((res: any) => {

                    state.data.jobEstimationLists.splice(state.selectItemIndex, 1)

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.getUnit = false;
                });

        }

        function userStatus() {


            const payLoad = {
                email: localStorage.getItem("current_user_email"),
            };
            // alert('Hello');
            ethitransService
                .userStatus(payLoad)
                .then((res: any) => {
                    // console.log('The res is', res.data.data );
                    state.data.companyInfo.logo = res.data.data.company.logo;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_name = res.data.data.company.company_name;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_phone_number = res.data.data.company.company_phone_number;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_email = res.data.data.company.company_email;
                    state.data.companyInfo.acknowledgement = res.data.data.company.acknowledgement;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.legal_statement = res.data.data.company.legal_statement;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_address = res.data.data.company.company_address;
                    // console.log("User Detail Check:>>> ", res.data.data);
                })
                .catch((error: any) => {
                    //   common
                })
                .finally(() => {
                    //   state.ui.isLoading.userStatus = true;
                });
        }


        function detailsShow(data: any) {
            state.status = data.status;
            geJobEstimationDetails(data.uuid);
            userStatus();
            $("#showDetails").modal("show");
        }
        function detailsShowAdmin(data: any) {
            userStatus();
            state.status = data.status;
            geJobEstimationDetails(data.uuid);
            $("#showDetailsAdmin").modal("show");
        }
        function detailsCloseAdmin() {
            $("#showDetailsAdmin").modal("hide");
        }
        function detailsClose() {
            $("#showDetails").modal("hide");
        }

        function deleteJobEstimationShow(itemIndex: any, item: any) {
            state.selectItemIndex = itemIndex;
            state.selectJobEstimationItem = item;
            $("#deleteJobEstimation").modal("show");
        }
        function deleteJobEstimationClose() {
            $("#deleteJobEstimation").modal("hide");
        }

        function gotoJobEstimationSend(uuid: any) {
            router.push({
                name: "send-job-estimation",
                params: { estimationId: uuid },
            });
        }

        function downoadPdf(url: any) {

            window.open(url, '_blank');
        }

        function sendSmartContract(uuid: any) {
            router.push({
                name: "create-estimation-invitation",
                query: { estimationId: uuid },
            });
        }

        function redirectToProject() {
            router.push({
                name: "admin-project-details",
                params: { projectId: projectId },
            });
        }

        const token = localStorage.getItem("current_user_token");
        let newToken = "";
        if (token) {
            newToken = token.replace(/"/gi, "");
        }
        const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
            cluster: "ap2",
            authEndpoint: `${config.privateChannel}broadcasting/auth`,
            auth: {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + newToken,
                },
            },
        });

        const todoChannel = pusher.subscribe(
            `private-estimationList.${projectId.toString()}`
        );
        todoChannel.bind("estimationList.updated", function (data: any) {
            state.ui.isLoading.pusherUpdate = false;
            if (JSON.stringify(data)) {
                state.ui.isLoading.pusherUpdate = true;
                geJobEstimationList();
            }
        });


        onBeforeRouteLeave((to, from) => {
            detailsClose()
            deleteJobEstimationClose()
            detailsCloseAdmin()
        })
        function packageDetailsByUser() {
            state.ui.errors.errorList = [];
            state.ui.errors.hasError = false;
            state.ui.isLoading.packageDetailsByUser = true;
            ethitransService
                .packageDetailsByUser()
                .then((res: any) => {
                    console.log("Check response >>> ", res.data.data.package_features)
                    state.data.subscriptionDetails = res.data.data.package_features;
                    state.ui.isLoading.packageDetailsByUser = false;

                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            // console.log("CHECK for Error >>>", res)
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.push(value[0]);
                                }
                            }

                        });
                })
        }
        function hideUpdateSubscriptionModal() {
            state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
        }
        onMounted(() => {
            geJobEstimationList();
            packageDetailsByUser();
        });
        return {
            state,
            hideUpdateSubscriptionModal,
            gotoCreateJobEstimation,
            geJobEstimationList,
            deleteJobEstimationClose,
            deleteJobEstimationShow,
            deleteJobEstimation,
            editJobEstimation,
            gotoJobEstimationSend,
            detailsShow,
            detailsClose,
            geJobEstimationDetails,
            downoadPdf,
            sendSmartContract,
            redirectToProject,
            detailsShowAdmin,
            detailsCloseAdmin,
            userStatus,
            updateJobEstimation
        };
    },
});
