
import { defineComponent, watch, reactive, onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";
import ToDoPhaseAddMemberTask from "@/components/todo/ToDoPhaseAddMemberTask.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import CheckAvaibilityByUser from "@/components/todo/CheckAvaibilityByUser.vue";
import AddMemberToTaskEditPhase from "@/components/todo/AddMemberToTaskEditPhase.vue";
import AddMembeToTask from "@/components/todo/AddMembeToTask.vue";

import Multiselect from 'vue-multiselect';
// import QCalender from "@/components/projectDetail/QCalender.vue";
// import ProCalender from "@/components/projectDetail/ProCalender.vue";
// import CustomEventCalender from "@/components/projectDetail/CustomEventCalender.vue";
// import AppCheckbox from "@/components/todo/AppCheckbox.vue";

import AppGoBack from "@/components/commonDesign/AppGoBack.vue";

import moment from "moment";

// import { Data } from "@/components/todo/AddMember.ts";

import ToDoPhaseAddMemberIndividualTest from "@/components/todo/ToDoPhaseAddMemberIndividualTest.vue";
// import ToDoPhaseAddMemberIndividual from "@/components/todo/ToDoPhaseAddMemberIndividual.vue";
import { config } from "@/Config";
import Pusher from "pusher-js";
import ProjectWorkflowAppGoBack from "./ProjectWorkflowAppGoBack.vue";

export default defineComponent({
  name: "ProjectWorkflowToDoPhaseAddMember",
  props: {
    phaseId: {
      type: String,
      required: true,
    },
    parentTaskId: {
      type: String,
      required: true,
    },
  },
  components: {
    Multiselect,
    // AppCheckbox,
    ToDoPhaseAddMemberTask,
    // ToDoPhaseAddMemberIndividual,
    ToDoPhaseAddMemberIndividualTest,
    ErrorList,
    AppDeleteConfirmationModal,
    SuccessfulModalCustom,
    CheckAvaibilityByUser,
    AddMemberToTaskEditPhase,
    AddMembeToTask,
    ProjectWorkflowAppGoBack,
    // CustomEventCalender,
    // QCalender,
    // ProCalender,
    // EmptyData,
    // ToDoListBreadCrumb,
    // ToDoListPhaseBlock,
    // ToDoListAddPhaseModal,
    // SuccessfulModalCustom,
  },
  setup(props,{emit}) {
    // const {
    //   params: { id },
    // } = useRoute();
    const router = useRoute();
    const route = useRouter();
    const phaseId = ref(props.phaseId);
    const phaseDetails = ref({
      phase: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        child_phases: [] as any,
      } as any,
      roles: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      smart_contract: {
        uuid: "",
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      amount_types: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phase_members: [] as any,
      members: [] as any,
    });
    const state = reactive({
      members: [
        {
          id: 1,
          name: 'Member 1',
          permissions: [
            { id: 101, name: 'Add member to phase', isChecked: false },
            { id: 102, name: 'Add member to task', isChecked: false },
            { id: 103, name: 'View Phase Detail', isChecked: false },
          ],
        },
        {
          id: 2,
          name: 'Member 2',
          permissions: [
            { id: 201, name: 'Add member to phase', isChecked: false },
            { id: 202, name: 'Add member to task', isChecked: false },
            { id: 203, name: 'View Phase Detail', isChecked: false },
          ],
        },
        // Add more members as needed
      ],
      data: {
        loggedInUserUUID: "",
        selectedMemberIndex: 0,
        activeTab: "phase",
        minDate: "",
        maxDate: "",
        filterBySearch: "",
        membersAdded: false,
        phaseDetailsStatus: true,
        agreeToInvitation: false,
        showAddMemberModal: false,
        showSuccesCustomModal: false,
        showAvailibilityModal: false,
        selectedEmail: "",
        selectedUser: {},
        successFulMessage:
          "Selected Members are added with roles and amount type in Phase",
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        selectedMemberForRole: {},
        selectedToRemove: {},
        userRole: 1,
        selectedRole: {},
        selectedTask: {},
        showDeleteConfirmationModal: false,
        showTaskUpdateModal: false,
        invite: {
          newUsers: "",
          existingMembers: [] as any,
          existingMemberSelected: [] as any,
          selectedMembers: [] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            phaseDetails: [] as any,
          },
        },
        isLoading: {
          phaseDetails: false,
          changeDetailsByEmail: false,
          getPhaseDetails: false,
        },
        isPostLoading: {
          createPhase: false,
          addMembersInPhases: false,
        },
      },
    });
    // eslint-disable-next-line @typescript-eslint/camelcase
    const nameWithEmail = ({ full_name, email }: any) => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      return email ? `${full_name} — [${email}]` : '';
    };
    const emailMonitor = computed(() => {
      return state.data.invite.selectedMembers.map((member: any) => {
        if (validationService.isValidEmail(member.email)) {
          member.checkAvaibility = false;
        } else {
          member.checkAvaibility = true;
        }
        return member;
      });
    });
    function isActive(action: string) {
      return common.isActionAllowded(
        state.data.userRole,
        phaseDetails.value.phase.permissions,
        action
      );
    }
    const tabPermissionStatus = computed(() => {
      // if(state.data.phaseDetails && state.data.phaseDetails.role === 1){
      return true;
      // } else {
      // return false;
      // }
    });
    const taskId = computed(() => {
      // if(props.parentTaskId){
      //   state.data.activeTab = 'task'
      // }
      return props.parentTaskId;
    });
    const filteredChildPhases = computed(() => {
      return phaseDetails.value.phase.child_phases.filter(
        (childPhase: any) => childPhase.isChecked
      );
    });
    // function hasValidFilteredSlots(member: any) {
    //   return Array.isArray(member.filteredSlots) && member.filteredSlots.length > 0;
    // }
    function hasValidFilteredSlots(member: any) {
      return (
        Array.isArray(member.filteredSlots) &&
        member.filteredSlots.length > 0 &&
        member.amountTypeNumber !== undefined &&
        member.amountTypeNumber !== null &&
        member.amountTypeNumber !== "" &&
        typeof member.amountType === "object" &&
        member.amountType !== null &&
        member.role &&
        member.role.permissions &&
        member.role.permissions.some(
          (permission: any) => permission.isChecked === true
        )
      );
    }
    const memberFilteredSlotsValid = computed(() => {
      return (
        state.data.invite.selectedMembers.length > 0 &&
        state.data.invite.selectedMembers.every((member: any) =>
          hasValidFilteredSlots(member)
        )
      );
    });

    const filteredPhases = computed(() => {
      const searchTerm = state.data.filterBySearch.toLowerCase();
      phaseDetails.value.phase.child_phases.forEach((child: any) => {
        child.isCheckedDisabled = false;
        if (child.start_date === null || child.end_date === null) {
          child.isCheckedDisabled = true;
        }
      });

      return phaseDetails.value.phase.child_phases.filter((item: any) =>
        item.name.toLowerCase().startsWith(searchTerm)
      );

      // return phaseDetails.value.phase.child_phases.filter((item: any) =>
      //           item.name.toLowerCase().includes(searchTerm)
      // );
    });
    const filteredEvents = computed(() => {
      const dates: any = [
        {
          start: "2018-11-21 09:00",
          end: "2018-11-21 17:00",
          title: `ABC`,
          // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          // class: 'health'
        },
        {
          start: "2018-11-22 09:00",
          end: "2018-11-21 17:00",
          title: `DEF`,
          // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          // class: 'health'
        },
      ];
      // phaseDetails.value.phase.child_phases.forEach((child: any)=> {
      //   if(child.edit_end_date === null || child.edit_start_date === null){
      //     dates.push({
      //     start: '2018-11-21 09:00',
      //     end: '2018-11-21 17:00',
      //     title: `${child.name}`,
      //     // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
      //     // class: 'health'
      //   })

      //   }
      // })
      return dates;

      // return phaseDetails.value.phase.child_phases.filter((item: any) =>
      //           item.name.toLowerCase().includes(searchTerm)
      // );
    });
    function clickEvent(data: any) {
      console.log("PRABHU >>> ", data);
    }
    // function createdEvent(event: any) {
    //   console.log("TEsting the SLOTS >>> ", event);
    //   // event.forEach((item: any)=> {

    //   // })
    //   // event.formatedStart = common.formatDateAndTimeSlot(event.start);
    //   // event.formatedEnd = common.formatDateAndTimeSlot(event.end);
    //   // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
    //   // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
    //   // _eid
    //   const array2 =
    //     state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots;

    //   // Synchronize array2 with array1
    //   event.forEach((obj1: any) => {
    //     const obj2 = array2.find((obj: any) => obj._eid === obj1._eid);
    //     if (obj2) {
    //       // Object with the same id exists in array2, update its name and date
    //       obj2.title = obj1.title;
    //       obj2.startTimeMinutes = obj1.startTimeMinutes;
    //       obj2.start = obj1.start;
    //       obj2.formatedStart = obj1.formatedStart;
    //       obj2.formatedEnd = obj1.formatedEnd;
    //       obj2.endTimeMinutes = obj1.endTimeMinutes;
    //       obj2.end = obj1.end;
    //       obj2.daysCount = obj1.daysCount;
    //       obj2.content = obj1.content;
    //       obj2.class = obj1.class;
    //       obj2.background = obj1.background;
    //       obj2.allDay = obj1.allDay;
    //     } else {
    //       // Object with the same id does not exist in array2, add it
    //       array2.push({
    //         _eid: obj1._eid,
    //         title: obj1.title,
    //         startTimeMinutes: obj1.startTimeMinutes,
    //         start: obj1.start,
    //         formatedStart: obj1.formatedStart,
    //         formatedEnd: obj1.formatedEnd,
    //         endTimeMinutes: obj1.endTimeMinutes,
    //         end: obj1.end,
    //         daysCount: obj1.daysCount,
    //         content: obj1.content,
    //         class: obj1.class,
    //         background: obj1.background,
    //         allDay: obj1.allDay,
    //       });
    //     }
    //   });

    //   console.log("SYNCKED >>>>>", array2);

    //   // Synchronize targetArray with sourceArray
    //   state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
    //     array2;
    //   // state.data.invite.selectedMembers[
    //   //   state.data.selectedMemberIndex
    //   // ].slots = event.slice();

    //   // latest update code
    //   // state.data.invite.selectedMembers[
    //   //   state.data.selectedMemberIndex
    //   // ].slots = event;

    //   // old code
    //   // state.data.invite.selectedMembers[
    //   //   state.data.selectedMemberIndex
    //   // ].slots.push(event);

    //   // Create an object to store the latest end_time for each unique date and time
    //   const latestEndTimes: any = {};

    //   state.data.invite.selectedMembers[
    //     state.data.selectedMemberIndex
    //   ].slots.forEach((slot: any) => {
    //     const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    //     const time = slot.formatedStart.split(" ")[1]; // Extract the time part
    //     const formatedEnd = new Date(slot.formatedEnd);

    //     const dateTimeKey = `${date} ${time}`;

    //     if (
    //       !latestEndTimes[dateTimeKey] ||
    //       formatedEnd > latestEndTimes[dateTimeKey]
    //     ) {
    //       latestEndTimes[dateTimeKey] = formatedEnd;
    //     }
    //   });

    //   // Create a new array with the updated time slots
    //   const updatedTimeSlots = Object.values(latestEndTimes).map(
    //     (latestEndTime: any) => {
    //       return state.data.invite.selectedMembers[
    //         state.data.selectedMemberIndex
    //       ].slots.find(
    //         (slot: any) =>
    //           new Date(slot.formatedEnd).getTime() === latestEndTime.getTime()
    //       );
    //     }
    //   );

    //   // Filter the old slots to keep only the ones that are in updatedTimeSlots
    //   state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
    //     state.data.invite.selectedMembers[
    //       state.data.selectedMemberIndex
    //     ].slots.filter((slot: any) => {
    //       const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    //       const time = slot.formatedStart.split(" ")[1]; // Extract the time part
    //       const dateTimeKey = `${date} ${time}`;
    //       return (
    //         updatedTimeSlots.includes(slot) ||
    //         latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd)
    //       );
    //     });

    //   // eslint-disable-next-line @typescript-eslint/camelcase
    //   state.data.invite.selectedMembers[
    //     state.data.selectedMemberIndex
    //   ].filteredSlots = updatedTimeSlots;
    // }
    function filterByNew(array: any) {
      return array.filter((item: any) => item.new === true);
    }
    function createdEvent(event: any) {
      console.log("TEsting the SLOTS >>> ", event);
      // event.forEach((item: any)=> {

      // })
      // event.formatedStart = common.formatDateAndTimeSlot(event.start);
      // event.formatedEnd = common.formatDateAndTimeSlot(event.end);
      // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
      // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
      // _eid
      // const array2 =
      //   state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots;

      // // Synchronize array2 with array1
      // event.forEach((obj1: any) => {
      //   const obj2 = array2.find((obj: any) => obj._eid === obj1._eid);
      //   if (obj2) {
      //     // Object with the same id exists in array2, update its name and date
      //     obj2.title = obj1.title;
      //     obj2.startTimeMinutes = obj1.startTimeMinutes;
      //     obj2.start = obj1.start;
      //     obj2.formatedStart = obj1.formatedStart;
      //     obj2.formatedEnd = obj1.formatedEnd;
      //     obj2.endTimeMinutes = obj1.endTimeMinutes;
      //     obj2.end = obj1.end;
      //     obj2.daysCount = obj1.daysCount;
      //     obj2.content = obj1.content;
      //     obj2.class = obj1.class;
      //     obj2.background = obj1.background;
      //     obj2.allDay = obj1.allDay;
      //   } else {
      //     // Object with the same id does not exist in array2, add it
      //     array2.push({
      //       _eid: obj1._eid,
      //       title: obj1.title,
      //       startTimeMinutes: obj1.startTimeMinutes,
      //       start: obj1.start,
      //       formatedStart: obj1.formatedStart,
      //       formatedEnd: obj1.formatedEnd,
      //       endTimeMinutes: obj1.endTimeMinutes,
      //       end: obj1.end,
      //       daysCount: obj1.daysCount,
      //       content: obj1.content,
      //       class: obj1.class,
      //       background: obj1.background,
      //       allDay: obj1.allDay,
      //     });
      //   }
      // });

      // console.log("SYNCKED >>>>>", array2);

      // // Synchronize targetArray with sourceArray
      // state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
      //   array2;
      // // state.data.invite.selectedMembers[
      // //   state.data.selectedMemberIndex
      // // ].slots = event.slice();

      // // latest update code
      // // state.data.invite.selectedMembers[
      // //   state.data.selectedMemberIndex
      // // ].slots = event;

      // // old code
      // // state.data.invite.selectedMembers[
      // //   state.data.selectedMemberIndex
      // // ].slots.push(event);

      // // Create an object to store the latest end_time for each unique date and time
      // const latestEndTimes: any = {};

      // state.data.invite.selectedMembers[
      //   state.data.selectedMemberIndex
      // ].slots.forEach((slot: any) => {
      //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //   const time = slot.formatedStart.split(" ")[1]; // Extract the time part
      //   const formatedEnd = new Date(slot.formatedEnd);

      //   const dateTimeKey = `${date} ${time}`;

      //   if (
      //     !latestEndTimes[dateTimeKey] ||
      //     formatedEnd > latestEndTimes[dateTimeKey]
      //   ) {
      //     latestEndTimes[dateTimeKey] = formatedEnd;
      //   }
      // });

      // // Create a new array with the updated time slots
      // const updatedTimeSlots = Object.values(latestEndTimes).map(
      //   (latestEndTime: any) => {
      //     return state.data.invite.selectedMembers[
      //       state.data.selectedMemberIndex
      //     ].slots.find(
      //       (slot: any) =>
      //         new Date(slot.formatedEnd).getTime() === latestEndTime.getTime()
      //     );
      //   }
      // );

      // // Filter the old slots to keep only the ones that are in updatedTimeSlots
      // state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
      //   state.data.invite.selectedMembers[
      //     state.data.selectedMemberIndex
      //   ].slots.filter((slot: any) => {
      //     const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //     const time = slot.formatedStart.split(" ")[1]; // Extract the time part
      //     const dateTimeKey = `${date} ${time}`;
      //     return (
      //       updatedTimeSlots.includes(slot) ||
      //       latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd)
      //     );
      //   });

      if (event && event.length) {
        event.forEach((item: any) => {
          item.formatedStart = common.formatDateAndTimeSlot(item.start);
          item.formatedEnd = common.formatDateAndTimeSlot(item.end);
        });
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invite.selectedMembers[
        state.data.selectedMemberIndex
      ].filteredSlots = filterByNew(event);
    }
    function deletedEvent(deletedEvent: any) {
      console.log("REceived Deleted Items >>> ", deletedEvent);

      const filteredAnotherEventsArray = state.data.invite.selectedMembers[
        state.data.selectedMemberIndex
      ].slots.filter((anotherEvent: any) => {
        return !deletedEvent.some((event: any) => {
          return (
            event.title === anotherEvent.title &&
            event.formatedStart === anotherEvent.formatedStart &&
            event.formatedEnd === anotherEvent.formatedEnd
          );
        });
      });

      console.log(filteredAnotherEventsArray);
      // setTimeout(function () {
      // Trigger a click event on the button
      state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
        filteredAnotherEventsArray;
      // }, 200);
      // event.formatedStart = common.formatDateAndTimeSlot(event.start);
      // event.formatedEnd = common.formatDateAndTimeSlot(event.end);
      // state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
      //   state.data.invite.selectedMembers[
      //     state.data.selectedMemberIndex
      //   ].slots.filter(
      //     (e: any) =>
      //       e.title !== event.title &&
      //       e.formatedStart !== event.formatedStart &&
      //       e.formatedEnd !== event.formatedEnd
      //   );

      //   console.log("<<< FINAL SLOTS >>> ", state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots)
    }
    function selectedTab(type: string) {
      state.data.activeTab = type;
    }
    // Define a function to find the index of an object by its 'id' property
    function findIndexById(
      array: { id: number; name: string }[],
      targetId: number
    ): number {
      return array.findIndex((obj) => obj.id === targetId);
    }
    function clearTaskId() {
      const hasTaskId = "taskId" in router.query;
      if (!hasTaskId) return false;

      // Remove the taskId query parameter
      const { taskId, ...queryWithoutTaskId } = router.query;

      // Convert query parameters to strings
      const sanitizedQuery = Object.fromEntries(
        Object.entries(queryWithoutTaskId).map(([key, value]) => [
          key,
          String(value),
        ])
      );

      // Construct the new query string without taskId
      const queryString = new URLSearchParams(sanitizedQuery).toString();

      // Construct the new path with the updated query string
      const newPath = `${router.path}${queryString ? `?${queryString}` : ""}`;

      // Replace the current URL with the new URL without taskId
      route.replace(newPath);
    }
    function permissionByUser(data: any) {
      console.log("CHECK THE PERMISSION All Info >>> ", data);
      // console.log("CHECK THE PERMISSION All Info >>> ", data.permission);
      // console.log("Index >> ", data.permissionIndex)
      state.data.invite.selectedMembers = data;
      console.log("ALL MEME >>> ", state.data.invite.selectedMembers);

    }
    function memberWisePermission(data: any) {
      state.data.invite.selectedMembers.forEach((member: any) => {
        if (member.email === data.selectedMember.email) {
          member.role.permissions.forEach((permission: any) => {
            permission = data.permission;
            // if (permission.id === item.check.id) {
            //   console.log(" IN CHECK LIST>>>", permission);
            //   permission.isChecked = true;
            // }
          });
        }
      });
    }
    function checkWithRow(item: any) {
      state.data.invite.selectedMembers.forEach((member: any) => {
        // console.log("Member ITEM >>", member.role.permissions)
        if (member.email === item.row.email) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.id === item.check.id) {
              permission.isChecked = true;
            }
          });
        }
      });
    }

    function addMemberToTaskSuccess() {
      state.data.showSuccesCustomModal = true;
      state.data.successFulMessage = "Members in Task Added Successfully";
      clearTaskId();
    }
    function setUpdatedTask(udpatedTask: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === udpatedTask.uuid) {
          // Replace the object with the updated object
          item.budget = udpatedTask.budget;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_budget = udpatedTask.budget;
          if (typeof udpatedTask.priority_id === "string") {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.priority_id = parseInt(udpatedTask.priority_id);
          } else {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.priority_id = udpatedTask.priority_id;
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.priority_id = udpatedTask.priority_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.end_date = common.localDateOnly(udpatedTask.end_date);
        }
      });
      state.data.showSuccesCustomModal = true;
      state.data.successFulMessage = "Task Updated.";

      // filteredPhases.value.forEach((item: any)=> {
      //   if (item.uuid === udpatedTask.uuid) {
      //       // Replace the object with the updated object
      //       item.budget = udpatedTask.budget;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.priority_id = udpatedTask.priority_id;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_start_date = common.localDateOnly(udpatedTask.start_date );
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
      //     }
      // })
    }
    function setMemberUpdatedTask(udpatedTask: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === udpatedTask.uuid) {
          item.allMembers = udpatedTask.allMembers;
          // Replace the object with the updated object
          item.budget = udpatedTask.budget;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.priority_id = udpatedTask.priority_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
        }
      });

      // filteredPhases.value.forEach((item: any)=> {
      //   if (item.uuid === udpatedTask.uuid) {
      //       // Replace the object with the updated object
      //       item.budget = udpatedTask.budget;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.priority_id = udpatedTask.priority_id;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_start_date = common.localDateOnly(udpatedTask.start_date );
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
      //     }
      // })
    }

    function addMemberToTaskData(tasks: any) {
      tasks.forEach((task: any, index: any) => {
        setMemberUpdatedTask(task);
        if (tasks.length === index + 1) {
          state.data.showSuccesCustomModal = true;
          state.data.successFulMessage = "Members Added To Selected Task";
          // clearTaskId();
        }
      });
    }
    function hideUpdateTaskModal() {
      state.data.showTaskUpdateModal = !state.data.showTaskUpdateModal;
    }
    function showTaskUpdateModal() {
      state.data.showTaskUpdateModal = true;
    }
    function hideAddMemberToTaskModal() {
      state.data.showAddMemberModal = !state.data.showAddMemberModal;
    }
    function showAddMemberToTaskModal() {
      state.data.showAddMemberModal = true;
    }

    function selectedTask(selected: any) {
      state.data.selectedTask = selected;
    }
    function selectedChecked(selected: any) {
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === selected.uuid) {
          item.isChecked = true;
          state.data.selectedTask = item;
        } else {
          item.isChecked = false;
        }
      });
    }
    function hideAvaibilityModal() {
      state.data.showAvailibilityModal = false;
    }
    function selectedMemberForCheckAvaibility(member: any) {
      // alert("<<< CHECK >>> ");
      console.log("WOWOW", member);
      state.data.selectedEmail = member.email;
      state.data.selectedUser = member;
      state.data.showAvailibilityModal = true;
    }
    function deleteAmountType(data: any, item: any) {
      // console.log("Triggered Type >> ", item);
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.amount_types = phaseDetails.value.amount_types.filter(
        (e: any) => e != item
      );
    }
    function selectedMemberIndex(memberIndex: number) {
      state.data.selectedMemberIndex = memberIndex;
    }
    function deleteRole(sata: any, item: any) {
      phaseDetails.value.roles = phaseDetails.value.roles.filter(
        (e: any) => e != item
      );
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function selectedAmountTypeForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.amountType);
      selectedMember.member.amountType = selectedMember.amountType;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.invite.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.amountType = selectedMember.amountType;
        }
      });
    }
    function addNewRole(newRole: any) {
      phaseDetails.value.roles = newRole;
    }
    function addNewAmountType(newAmountTypes: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.amount_types = newAmountTypes;
    }
    function selectedRoleForMember(selectedMember: any) {
      if (
        selectedMember.role &&
        selectedMember.role.permissions &&
        selectedMember.role.permissions.length
      ) {
        selectedMember.role.permissions.forEach((permission: any) => {
          permission.isChecked = false;
        });
      }
      selectedMember.member.role = selectedMember.role;
      state.data.selectedMemberForRole = selectedMember;
      state.data.invite.selectedMembers[selectedMember.index].role = selectedMember.role;
      // state.data.invite.selectedMembers.forEach((item: any) => {
      //   if (item.email === selectedMember.member.email) {
      //     item.role = selectedMember.role;
      //   }
      // });
    }
    function setNewSelectedMembers(fullName: string, email: string) {
      
      if (state.data.invite.selectedMembers.length > 0 &&
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].email === "") {
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].name = fullName;
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].amountType = "";
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].isExist = common.findEmailMatch(email, phaseDetails.value.phase_members);
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].amountTypeNumber = "";
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].role = "";
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].email = email;
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].slots = [];
        // eslint-disable-next-line @typescript-eslint/camelcase
        state.data.invite.selectedMembers[state.data.invite.selectedMembers.length - 1].approval_required = false;
        } else {
          state.data.invite.selectedMembers.push({
            name: fullName,
            amountType: "",
            isExist: common.findEmailMatch(email, phaseDetails.value.phase_members),
            amountTypeNumber: "",
            role: "",
            email: email,
            slots: [],
            // eslint-disable-next-line @typescript-eslint/camelcase
            approval_required: false,
          });
      }

      state.data.invite.existingMemberSelected = {};
    }
    function changeDetailsByEmail() {
      console.log("CHECK >>> ", state.data.invite.existingMemberSelected)
      if (
        !validationService.isValidEmail(
          state.data.invite.existingMemberSelected.email
        )
      )
        return false;

      const selectedEmail = state.data.invite.existingMemberSelected.email;
      const oldEmail = state.data.invite.selectedMembers.find((member: any) => {
        return member.email == selectedEmail;
      });
      if (!(oldEmail === undefined)) {
        return false;
      }
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.changeDetailsByEmail) return false;
      state.ui.isLoading.changeDetailsByEmail = true;
      ethitransService
        .detailsByemail(selectedEmail.toString())
        .then((res: any) => {
          if (res.data.data.email && res.data.data.full_name) {
            setNewSelectedMembers(res.data.data.full_name, res.data.data.email);
          } else {
            setNewSelectedMembers("", selectedEmail);
          }

          state.ui.isLoading.changeDetailsByEmail = false;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function checkTaskIdSelected() {
      const filteredArray = phaseDetails.value.phase.child_phases.filter(
        (item: any) => item.uuid === props.parentTaskId
      );
      // const filteredArray = filteredPhases.value.filter((item: any) => item.uuid !== props.parentTaskId);
      const startDate = filteredArray[0].start_date;
      const endDate = filteredArray[0].end_date;
      if (startDate && endDate) {
        showAddMemberToTaskModal();
        selectedChecked(filteredArray[0]);
      } else {
        showTaskUpdateModal();
        selectedTask(filteredArray[0]);
      }
    }
    function getPhaseDetails() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPhaseDetails) return false;
      state.ui.isLoading.getPhaseDetails = true;
      ethitransService
        .phaseDetailsById(props.phaseId.toString())
        .then((res: any) => {
          console.log(
            "getPhaseDetails Response Check:>>> ",
            res.data.data.role
          );
          // console.log("LINK Check:>>> ", config.liveImageBaseUrl);
          state.data.userRole = res.data.data.role;

          state.ui.isLoading.getPhaseDetails = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }

    function getToDoData() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.phaseDetails) return false;
      state.ui.isLoading.phaseDetails = true;
      ethitransService
        .addMemberInPhase(props.phaseId.toString())
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.phase.edit_budget = common.formatCurrencyToAddMember(
            JSON.parse(JSON.stringify(res.data.data.phase.budget))
          );
          if (res.data.data.smart_contract.end_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.smart_contract.end_date = common.localDateOnly(
              res.data.data.smart_contract.end_date
            );
          }
          if (res.data.data.smart_contract.start_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.smart_contract.start_date = common.localDateOnly(
              res.data.data.smart_contract.start_date
            );
          }
          if (res.data.data.phase.end_date) {
            res.data.data.phase.endDateVueCal = res.data.data.phase.end_date;
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.end_date = common.localDateOnly(
              res.data.data.phase.end_date
            );

            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.edit_end_date = JSON.parse(
              JSON.stringify(common.localDateOnly(res.data.data.phase.end_date))
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.phase.start_date) {
            res.data.data.phase.startDateVueCal =
              res.data.data.phase.start_date;
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.start_date = common.localDateOnly(
              res.data.data.phase.start_date
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.edit_start_date = JSON.parse(
              JSON.stringify(
                common.localDateOnly(res.data.data.phase.start_date)
              )
            );
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (
            res.data.data.phase.child_phases &&
            res.data.data.phase.child_phases.length
          ) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.child_phases.forEach((item: any) => {
              item.isChecked = false;
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.edit_budget = common.formatCurrencyToAddMember(
                JSON.parse(JSON.stringify(item.budget))
              );
              // eslint-disable-next-line @typescript-eslint/camelcase
              if (item.start_date) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.edit_start_date = JSON.parse(
                  JSON.stringify(common.localDateOnly(item.start_date))
                );
              }
              // eslint-disable-next-line @typescript-eslint/camelcase
              if (item.end_date) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.edit_end_date = JSON.parse(
                  JSON.stringify(common.localDateOnly(item.end_date))
                );
              }
            });
          }
          // if(res.data.data.members && res.data.data.members.length){

          // }
          if (res.data.data.permissions && res.data.data.permissions.length) {
            res.data.data.permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          if (
            res.data.data.phase_permissions &&
            res.data.data.phase_permissions.length
          ) {
            res.data.data.phase_permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          if (
            res.data.data.task_permissions &&
            res.data.data.task_permissions.length
          ) {
            res.data.data.task_permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          state.data.minDate = res.data.data.phase
            ? moment(res.data.data.phase.edit_start_date).format("Y-MM-DD")
            : "";
          state.data.maxDate = res.data.data.phase
            ? moment(res.data.data.phase.edit_end_date).format("Y-MM-DD")
            : "";
          phaseDetails.value = res.data.data;
          state.ui.isLoading.phaseDetails = false;
          // Get a reference to the button element by its ID
          const button = document.getElementById("member-task-tab");

          // check for the taskID
          if (props.parentTaskId) {
            // selectedTab('task')
            // Check if the button element exists
            if (button) {
              // Trigger a click event on the button
              setTimeout(function () {
                // Trigger a click event on the button
                button.click();
                checkTaskIdSelected();
              }, 500);
              // console.log("CLICKED>>")
            } else {
              console.error("Button not found!");
            }
          }

          if (
            phaseDetails.value.phase.permissions.some(
              (obj: any) => obj.code === "add_member_to_phase"
            )
          )
            return false;
          if (
            phaseDetails.value.phase.permissions.some(
              (obj: any) => obj.code === "add_member_to_task"
            )
          ) {
            console.log("GOOD");
            // selectedTab('task')
            // Check if the button element exists
            if (button) {
              // Trigger a click event on the button
              setTimeout(function () {
                // Trigger a click event on the button
                button.click();
                checkTaskIdSelected();
              }, 500);
              // console.log("CLICKED>>")
            } else {
              console.error("Button not found!");
            }
          }
          if (state.data.activeTab === "phase") {
            if (state.data.invite.selectedMembers && state.data.invite.selectedMembers.length != 0) return false;
            state.data.invite.selectedMembers.push({
              name: "",
              amountType: "",
              isExist: false,
              amountTypeNumber: "",
              role: "",
              email: "",
              slots: [],
              // eslint-disable-next-line @typescript-eslint/camelcase
              approval_required: false,
            });
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }

    const userAllInfo: any = localStorage.getItem("current_user_info");
    const userInfo: any = JSON.parse(userAllInfo);
    state.data.loggedInUserUUID = userInfo.uuid;
    // console.log("<<< CHECK UUID:>>>>", state.data.loggedInUserUUID);
    // Pusher.logToConsole = true;s
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });

    const todoChannel = pusher.subscribe(
      `private-phaseAddMember.${props.phaseId.toString()}`
    );
    todoChannel.bind("phaseAddMember.updated", function (data: any) {
      getToDoData();

      // if (abc) {
      //   let url = new URL(
      //     "https://localhost:8080/#/todo/add-member/e4401720-e02a-4dee-962b-b67a842b9545?taskId=76d61910-1860-4d4f-af9a-ea174158e74c"
      //   );

      //   url.searchParams.delete("taskId");
      //   let updatedUrl = url.toString();
      // }

      // if (JSON.stringify(data)) {
      //   console.log("DELETE NOTIFICATION CHECK >>>> ", data);
      // }
    });
    function changeInviteMember() {
      // console.log("CHECK MSG", state.inviteMember);

      if (state.data.invite.newUsers) {
        // if(state.data.invite.existingMembers){
        if (validationService.isValidEmail(state.data.invite.newUsers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.selectedMembers.length === 0) {
            state.data.invite.selectedMembers.push({
              name: "",
              hourly: "",
              email: state.data.invite.newUsers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              checkAvaibility: true,
              error: false,
            });
          } else {
            state.data.invite.selectedMembers.forEach((member: any) => {
              // console.log("member", member);
              // console.log("member", state.data.invite.newUsers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.newUsers.toLowerCase()
              ) {
                state.data.invite.selectedMembers.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.newUsers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  checkAvaibility: true,
                  error: false,
                });
              }
            });
          }
          state.data.invite.newUsers = "";
          state.data.invite.existingMembers = "";
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.selectedMembers = Array.from(
            new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).error,
            };
          });
          // state.data.invite.selectedMembers = Array.from(
          //   new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          // ).map((email) => {
          //   return {
          //     email: email,
          //     // eslint-disable-next-line @typescript-eslint/camelcase
          //     sub_contractor: false,
          //     member: false,
          //   };
          // });
        }
      } else {
        if (validationService.isValidEmail(state.data.invite.existingMembers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.selectedMembers.length === 0) {
            state.data.invite.selectedMembers.push({
              name: "",
              hourly: "",
              email: state.data.invite.existingMembers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              checkAvaibility: true,
              error: false,
            });
          } else {
            state.data.invite.selectedMembers.forEach((member: any) => {
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.existingMembers.toLowerCase()
              ) {
                state.data.invite.selectedMembers.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.existingMembers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  checkAvaibility: true,
                  error: false,
                });
              }
            });
          }
          state.data.invite.existingMembers = [];

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.selectedMembers = Array.from(
            new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).error,
            };
          });
        }
      }
      // state.data.invite.existedMembers = [];
    }
    function selectedMember(selectedMember: any) {
      state.data.selectedToRemove = selectedMember;
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
      // props.members
      // state.data.members = state.data.members.filter((e: any) => e != selectedMember);
    }
    function removeSelectedMember() {
      // props.members
      state.data.invite.selectedMembers =
        state.data.invite.selectedMembers.filter(
          (e: any) => e != state.data.selectedToRemove
        );
    }
    function hideConfirmation() {
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
    }
    function containsNumberThree(arr: any) {
      return arr.includes(3);
    }
    function insertEmptyMember() {
      if (state.data.invite.selectedMembers && state.data.invite.selectedMembers.length === 0) {

        state.data.invite.selectedMembers.push({
          name: '',
          amountType: "",
          isExist: false,
          amountTypeNumber: "",
          role: "",
          email: "",
          slots: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: false,
        });
      } else {
        state.data.invite.selectedMembers.push({
          name: '',
          amountType: "",
          isExist: false,
          amountTypeNumber: "",
          role: "",
          email: "",
          slots: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: false,
        });
      }
    }
    function addMembersInPhases() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      const filterMembers = [] as any;
      state.data.invite.selectedMembers.forEach((member: any) => {
        const permissionList = [] as any;
        const timeSlots = [] as any;
        if (
          member.role &&
          member.role.permissions &&
          member.role.permissions.length
        ) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.isChecked) {
              permissionList.push(permission.id);
            }
          });
        }
        if (!containsNumberThree(permissionList)) {
          permissionList.push(3);
        }
        member.filteredSlots.forEach((slot: any) => {
          timeSlots.push({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_time: slot.formatedStart,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_time: slot.formatedEnd,
            title: slot.title,
            isAllday: slot.isAllday,
            category: slot.category,
            notifications: slot.notifications,
            bgColor: slot.bgColor,
          });
        });
        filterMembers.push({
          email: member.email,
          name: member.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          role_id: member.role.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          amount_type_id: member.amountType.id,
          amount: member.amountTypeNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: member.approval_required,
          permissions: permissionList,
          // eslint-disable-next-line @typescript-eslint/camelcase
          time_slots: timeSlots,

        });
      });

      // add validation here

      if (state.ui.isPostLoading.addMembersInPhases) return false;
      state.ui.isPostLoading.addMembersInPhases = true;

      const payLoad = {
        phases: [
          {
            uuid: phaseDetails.value.phase.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            priority_id: phaseDetails.value.phase.priority_id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: phaseDetails.value.phase.edit_start_date,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: phaseDetails.value.phase.edit_end_date,
            budget: phaseDetails.value.phase.budget,
          },
        ],
        members: filterMembers,
      };

      console.log("CHECK THE PAYLOAD >>>", payLoad);
      ethitransService
        .addMembersInPhases(payLoad)
        .then((res: any) => {
          state.data.invite.selectedMembers = [];
          state.data.showSuccesCustomModal = true;
          // state.data.unitLists = res.data.data;
          state.ui.isPostLoading.addMembersInPhases = false;
          insertEmptyMember();
        })
        .catch((error: any) => {
          state.ui.isPostLoading.addMembersInPhases = false;
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    
    watch(
      () => state.data.activeTab,
      (newVal: string) => {
        //  if(newVal === 'phase'){

        //  }
        if (newVal === 'phase') {
          insertEmptyMember();
        } else {
          state.data.invite.selectedMembers = [];
          state.data.showAddMemberModal = false;
          phaseDetails.value.phase.child_phases.forEach((item: any) => {
          item.isChecked = false;
      });
        }
      }
    );
    

    const hasValidLastEmail = computed(() => {
      const members = state.data.invite.selectedMembers;
      if (members.length === 0) {
        return false;
      }
      const lastMember = members[members.length - 1];
      return validationService.isValidEmail(lastMember.email);
    });
    // Handle the update event from the child component
    const updateMemberPermission = (updatedMembers: any) => {
      state.data.invite.selectedMembers.value = updatedMembers;
    };

    onMounted(() => {
      getToDoData();
      getPhaseDetails();
      // clearTaskId();
    });

    return {
      state,
      nameWithEmail,
      emailMonitor,
      updateMemberPermission,
      permissionByUser,
      hasValidLastEmail,
      insertEmptyMember,
      filterByNew,
      isActive,
      getPhaseDetails,
      tabPermissionStatus,
      memberWisePermission,
      containsNumberThree,
      clearTaskId,
      checkTaskIdSelected,
      taskId,
      memberFilteredSlotsValid,
      hasValidFilteredSlots,
      // filterSlots,
      clickEvent,
      selectedMemberIndex,
      createdEvent,
      deletedEvent,
      selectedTab,
      filteredEvents,
      findIndexById,
      checkWithRow,
      setMemberUpdatedTask,
      addMemberToTaskData,
      addMemberToTaskSuccess,
      selectedChecked,
      hideAddMemberToTaskModal,
      showAddMemberToTaskModal,
      setUpdatedTask,
      hideUpdateTaskModal,
      selectedTask,
      showTaskUpdateModal,
      filteredPhases,
      filteredChildPhases,
      hideAvaibilityModal,
      deleteRole,
      selectedMemberForCheckAvaibility,
      deleteAmountType,
      addNewAmountType,
      addNewRole,
      closeSuccessCustomModal,
      addMembersInPhases,
      selectedAmountTypeForMember,
      selectedRoleForMember,
      selectedMember,
      getToDoData,
      phaseDetails,
      hideConfirmation,
      changeInviteMember,
      changeDetailsByEmail,
      setNewSelectedMembers,
      removeSelectedMember,
    };
  },
});
