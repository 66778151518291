
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import eventBus from "@/eventBus";
export default defineComponent({
  name: "GoBack",
  props: {
    errors: Array,
    uuid: String,
    pathName: String,
    urlId: String,
  },
  setup(props) {
    const router = useRouter();
    function redirectBackTo() {
      eventBus.emit("go-back", { pathName: props.pathName, uuid: props.uuid });
    }

    // console.log("CHECK ChannelCard props", props.errors);
    // console.log("CHECK props", props.hasError);
    return { redirectBackTo };
  },
});
