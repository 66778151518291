
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter } from "vue-router";
import Pusher from "pusher-js";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { config } from "@/Config";

export default defineComponent({
    name: "ProjectWorkflowList",
    components: {
        EmptyData,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const {
            params: { projectId },
        } = useRoute();
        const isInvalid = ref(false);
        const state = reactive({
            projectId: projectId,
            selectUnitId: '' as any,
            selectJobEstimationItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectJobEstimationItem: '' as any,
            data: {
                subscriptionDetails: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    appointment_numbers: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    archive_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_file_size: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_message_history: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    estimation_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    export_channel_pdf: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    files_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    folder_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    guests_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    import_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    invoice_payment: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_in_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_per_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    members_per_appointment: 0,
                    name: "Silver",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    pdf_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phase_per_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    private_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    project_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    sync_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    task_per_phase: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    template_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_channel_member: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_estimation: 0,
                } as any,
                role: {} as any,
                note: '' as any,
                title: "data found",
                headings: "Workflow",
                workflowLists: [] as any,
                unitLists: [] as any,
                details: {} as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
            },
            ui: {
                isActive: {
                    upgradeSubscription: false,
                },
                isLoading: {
                    packageDetailsByUser: false,
                    isEdit: false,
                    getWorkflowList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    pusherUpdate: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                },
                errors: {
                    errorList: {
                        createUnit: [] as any,
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    hasError: false,
                },
            },
        });
        function redirectToPhasesDashboard(uuid: string) {
            console.log('The uuid is>>>', uuid);
            emit('user-to-do-list', uuid, 'flowlist');
        }

        function gotoSmartContractPreview(uuid: any) {
            // router.push({
            //     name: "smart-contract-preview",
            //     params: { contractId: uuid }
            // });
            emit('smart-contract-preview', uuid);
        }

        function getWorkflowList() {
            state.ui.isLoading.getWorkflowList = true
            ethitransService
                .getWorkflowList(state.projectId)
                .then((res: any) => {
                    console.log('The res is:>>>>>>>>>>',res.data.data.workflows);
                    state.data.workflowLists = res.data.data.workflows;
                    state.data.role = res.data.data.project_role;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getWorkflowList = false;
                });
        }


        function redirectToProject() {
            router.push({
                name: "admin-project-details",
                params: { projectId: projectId },
            });
        }

        const token = localStorage.getItem("current_user_token");
        let newToken = "";
        if (token) {
            newToken = token.replace(/"/gi, "");
        }
        const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
            cluster: "ap2",
            authEndpoint: `${config.privateChannel}broadcasting/auth`,
            auth: {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + newToken,
                },
            },
        });

        const todoChannel = pusher.subscribe(
            `private-smartContractList.${projectId.toString()}`
        );
        todoChannel.bind("smartContractList.updated", function (data: any) {
            state.ui.isLoading.pusherUpdate = false;
            if (JSON.stringify(data)) {
                state.ui.isLoading.pusherUpdate = true;
                getWorkflowList();
            }
        });

        function packageDetailsByUser() {
            // state.ui.errors.errorList = [];
            // state.ui.errors.hasError = false;
            state.ui.isLoading.packageDetailsByUser = true;
            ethitransService
                .packageDetailsByUser()
                .then((res: any) => {
                    console.log("Check response >>> ", res.data.data.package_features)
                    state.data.subscriptionDetails = res.data.data.package_features;
                    state.ui.isLoading.packageDetailsByUser = false;

                })
                .catch((error: any) => {
                    // common
                    //     .sdCatchErr(error, state.ui.errors.errorList)
                    //     .then((res: any) => {
                    //         state.ui.errors.hasError = true;
                    //         // console.log("CHECK for Error >>>", res)
                    //         for (const [key, value] of Object.entries(res)) {
                    //             if (Array.isArray(value)) {
                    //                 state.ui.errors.errorList.push(value[0]);
                    //             }
                    //         }

                    //     });
                })
        }
        onMounted(() => {
            getWorkflowList();
            packageDetailsByUser()
        });
        return {
            state,
            packageDetailsByUser,
            redirectToPhasesDashboard,
            getWorkflowList,
            redirectToProject,
        };
    },
});
