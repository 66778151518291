import { io, Socket } from 'socket.io-client';
import { useStore } from "vuex";

// Define the shape of events
interface ServerToClientEvents {
  message: (data: string) => void;
  room_message: (data: any) => void;
}

interface ClientToServerEvents {
  join_room: (room: string) => void;
  room_message: (data: { room: string; data: any }) => void;
}

// Socket instance (uninitialized initially)
let socket: Socket<ServerToClientEvents, ClientToServerEvents> | null = null;


// Function to initialize the socket connection with a token
export const initializeSocket = (token: string): Socket<ServerToClientEvents, ClientToServerEvents> => {
  const store = useStore();
  if (!socket) {
    const url = `https://app.convorally.com:3000?token=${token}`;
    socket = io(url, {
      reconnection: true,
      reconnectionAttempts: 5,
      timeout: 5000,
      transports: ['websocket'], // Ensure WebSocket transport is used
    });
    socket.on('connect_error',(data: any) => {
      console.log('Socket disconnected',data);
      store.dispatch("saveSocketStatus", 2);
    });
    
    // Optional: Handle connection events
    socket.on('connect', () => {
      console.log('Socket connected:', socket?.id);
      if(store != undefined){
        store.dispatch("saveSocketStatus", 1);
      }
      
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
      store.dispatch("saveSocketStatus", 0);
    });

    
    
  }
  return socket;
};

// Function to get the existing socket instance
export const getSocket = (): Socket<ServerToClientEvents, ClientToServerEvents> | null => {
  return socket;
};
