// eventBus.ts
import { reactive } from "vue";

type EventCallback = (...args: any[]) => void;

const eventBus = reactive(new Map<string, EventCallback[]>());

export default {
  on(event: string, callback: EventCallback) {
    if (!eventBus.has(event)) {
      eventBus.set(event, []);
    }
    eventBus.get(event)?.push(callback);
  },
  emit(event: string, ...args: any[]) {
    eventBus.get(event)?.forEach(callback => callback(...args));
  },
  off(event: string, callback: EventCallback) {
    const listeners = eventBus.get(event) || [];
    const updatedListeners = listeners.filter(fn => fn !== callback);
    if (updatedListeners.length > 0) {
      eventBus.set(event, updatedListeners);
    } else {
      eventBus.delete(event);
    }
  },
};
