
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter } from "vue-router";
import Pusher from "pusher-js";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { config } from "@/Config";

export default defineComponent({
    name: "ProjectWorkflowInvoiceList",
    components: {
        EmptyData,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const {
            params: { projectId },
        } = useRoute();
        const isInvalid = ref(false);
        const state = reactive({
            projectId: projectId,
            selectUnitId: '' as any,
            activeUserDetails: '' as any,
            selectJobEstimationItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectJobEstimationItem: '' as any,
            data: {
                subscriptionDetails: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    appointment_numbers: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    archive_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_file_size: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_message_history: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    estimation_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    export_channel_pdf: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    files_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    folder_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    guests_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    import_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    invoice_payment: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_in_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_per_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    members_per_appointment: 0,
                    name: "Silver",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    pdf_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phase_per_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    private_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    project_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    sync_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    task_per_phase: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    template_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_channel_member: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_estimation: 0,
                } as any,
                role: {} as any,
                note: '' as any,
                title: "data found",
                headings: "Job Estimation",
                smartContractLists: [] as any,
                unitLists: [] as any,
                details: {} as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
                filterReceivedInvoiceList: {
                    list: [] as any,
                    id: "",
                    listByStatus: 1,
                    listByTitle: "",
                },
                filterSentInvoiceList: {
                    list: [] as any,
                    id: "",
                    listByStatus: 1,
                    listByTitle: "",
                },
                isPayActive: false,
                selectedInvoice: {} as any,
            },
            ui: {
                isActive: {
                    upgradeSubscription: false,
                },
                isLoading: {
                    packageDetailsByUser: false,
                    isEdit: false,
                    getSmartContractList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    pusherUpdate: false,
                    getInvoiceByPhaseOrTask: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                    confirmPayment: false
                },
                error: {
                    errorList: {
                        createUnit: [] as any,
                        getInvoiceByPhaseOrTask: [] as any
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    hasError: false,
                },
            },
        });
        function redirectToPhasesDashboard(uuid: string) {
            // console.log("CHECK UUID >>> ", uuid);
            router.push({
                name: "user-todo-list",
                params: { projectId: uuid }
            });
        }

        function gotoCreateJobEstimation() {
            router.push({
                name: "create-job-estimation",
                params: { projectId: state.projectId }
            });
        }

        function gotoSmartContractPreview(uuid: any) {
            // router.push({
            //     name: "smart-contract-preview",
            //     params: { contractId: uuid }
            // });
            emit('smart-contract-preview', uuid);
        }
        function editJobEstimation(itemId: any) {
            router.push({
                name: "edit-job-estimation",
                params: { projectId: state.projectId },
                query: { id: itemId }
            });
        }

        function getSmartContractList() {
            state.ui.isLoading.getSmartContractList = true
            ethitransService
                .getSmartContractList(state.projectId)
                .then((res: any) => {
                    state.data.smartContractLists = res.data.data.smartcontracts;
                    state.data.role = res.data.data.project_role;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getSmartContractList = false;
                });
        }
        function getProjectInvoiceList(payload: any) {
            state.ui.isLoading.getSmartContractList = true
            ethitransService
                .projectInvoiceList(payload)
                .then((res: any) => {
                    // state.data.smartContractLists = res.data.data.smartcontracts;
                    // state.data.role = res.data.data.project_role;
                    state.data.filterReceivedInvoiceList.list = res.data.data.received_invoice
                        ? res.data.data.received_invoice
                        : [];
                    state.data.filterSentInvoiceList.list = res.data.data.sent_invoices;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getSmartContractList = false;
                });
        }

        function geJobEstimationDetails(uuid: any) {
            state.data.details = {};
            state.ui.isLoading.getSmartContractList = true
            ethitransService
                .geJobEstimationDetailsPreview(uuid)
                .then((res: any) => {
                    res.data.data.total = 0;
                    state.data.note = res.data.data.note;
                    res.data.data.singed_customers.forEach((customer: any) => {
                        // eslint-disable-next-line @typescript-eslint/camelcase  
                        customer.signaute_date = common.localDateOnly(customer.signaute_date);
                    });
                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    res.data.data.signature_date = common.localDateOnly(res.data.data.signature_date ? res.data.data.signature_date : '');
                    res.data.data.phases.forEach((item: any) => {
                        item.sub_items.forEach((subItem: any) => {
                            subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                            if (subItem.markup_type == 1) {
                                const parCal = (parseFloat(subItem.cost) * parseFloat(subItem.markup)) / 100;
                                subItem.total = parCal + parseFloat(subItem.cost);
                                res.data.data.total += subItem.total;
                            } else {
                                subItem.total = parseFloat(subItem.cost) + parseFloat(subItem.markup);
                                res.data.data.total += subItem.total;
                            }
                        });
                    });
                    state.data.details = res.data.data;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getSmartContractList = false;
                });
        }

        function deleteJobEstimation() {

            ethitransService
                .deleteJobEstimation(state.selectJobEstimationItem.uuid)
                .then((res: any) => {

                    state.data.smartContractLists.splice(state.selectItemIndex, 1)

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.getUnit = false;
                });

        }

        function detailsShow(uuid: any) {
            geJobEstimationDetails(uuid);
            $("#showDetails").modal("show");
        }
        function detailsClose() {
            $("#showDetails").modal("hide");
        }

        function deleteJobEstimationShow(itemIndex: any, item: any) {
            state.selectItemIndex = itemIndex;
            state.selectJobEstimationItem = item;
            $("#deleteJobEstimation").modal("show");
        }
        function deleteJobEstimationClose() {
            $("#deleteJobEstimation").modal("hide");
        }

        function gotoJobEstimationSend(uuid: any) {
            router.push({
                name: "send-job-estimation",
                params: { estimationId: uuid },
            });
        }

        function downoadPdf(url: any) {

            window.open(url, '_blank');
        }

        function sendSmartContract(uuid: any, estimationId: any) {
            // router.push({
            //     name: "send-smart-contract",
            //     query: { contractId: uuid, estimationId: estimationId },
            // });
            emit('send-smart-contract', uuid, estimationId);
        }

        function redirectToProject() {
            router.push({
                name: "admin-project-details",
                params: { projectId: projectId },
            });
        }

        const token = localStorage.getItem("current_user_token");
        let newToken = "";
        if (token) {
            newToken = token.replace(/"/gi, "");
        }
        const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
            cluster: "ap2",
            authEndpoint: `${config.privateChannel}broadcasting/auth`,
            auth: {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + newToken,
                },
            },
        });

        const todoChannel = pusher.subscribe(
            `private-smartContractList.${projectId.toString()}`
        );
        todoChannel.bind("smartContractList.updated", function (data: any) {
            state.ui.isLoading.pusherUpdate = false;
            if (JSON.stringify(data)) {
                state.ui.isLoading.pusherUpdate = true;
                getSmartContractList();
            }
        });

        function packageDetailsByUser() {
            // state.ui.errors.errorList = [];
            // state.ui.errors.hasError = false;
            state.ui.isLoading.packageDetailsByUser = true;
            ethitransService
                .packageDetailsByUser()
                .then((res: any) => {
                    console.log("Check response >>> ", res.data.data.package_features)
                    state.data.subscriptionDetails = res.data.data.package_features;
                    state.ui.isLoading.packageDetailsByUser = false;

                })
                .catch((error: any) => {
                    // common
                    //     .sdCatchErr(error, state.ui.errors.errorList)
                    //     .then((res: any) => {
                    //         state.ui.errors.hasError = true;
                    //         // console.log("CHECK for Error >>>", res)
                    //         for (const [key, value] of Object.entries(res)) {
                    //             if (Array.isArray(value)) {
                    //                 state.ui.errors.errorList.push(value[0]);
                    //             }
                    //         }

                    //     });
                })
        }
        function selectedInvoice(data: any) {
            state.data.selectedInvoice = {};
            state.data.selectedInvoice = data;
            let invoiceId = "";
            invoiceId = data.uuid;
            // console.log("Choose Payment Method", invoiceId);
            state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
            state.ui.error.hasError = false;

            if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;

            state.ui.isLoading.getInvoiceByPhaseOrTask = true;
            ethitransService
                // .invoiceByPhaseOrTask(phaseId.toString())
                .invoiceByPhaseOrTask(invoiceId.toString(), 1)
                .then((res: any) => {
                    console.log(
                        "getInvoiceByPhaseOrTask Response Check:>>> ",
                        res.data.data
                    );
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    if (res.data.data.invoice && res.data.data.invoice.invoice_date) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        res.data.data.invoice.invoice_date = common.localDateOnly(
                            res.data.data.invoice.invoice_date
                        );
                    }
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    if (res.data.data.new_items && res.data.data.new_items.length) {
                        console.log("New Items");
                        if (
                            res.data.data.invoice &&
                            res.data.data.invoice.invoice_items &&
                            res.data.data.invoice.invoice_items.length
                        ) {
                            console.log("invoice items");
                            res.data.data.new_items.forEach((item: any) => {
                                item.newAdded = true;
                                res.data.data.invoice.invoice_items.push(item);
                            });
                        }
                    }
                    // res.data.data.breadcrumb.reverse();
                    state.data.selectedInvoice = res.data.data.invoice
                        ? res.data.data.invoice
                        : {};
                    // state.data.breadcrumb = res.data.data.breadcrumb
                    //   ? res.data.data.breadcrumb
                    //   : [];

                    if (
                        localStorage.getItem("current_user_id") &&
                        state.data.selectedInvoice.billed_from
                    ) {
                        const userId: any = localStorage.getItem("current_user_id");
                        console.log("USER ID >>> ", userId);
                        if (
                            state.data.selectedInvoice.status === 1 ||
                            state.data.selectedInvoice.status === 4
                        ) {
                            // if (
                            //   parseInt(userId) !=
                            //   parseInt(state.data.invoiceDetails.billed_from)
                            // ) {
                            state.data.isPayActive = true;
                        } else {
                            state.data.isPayActive = false;
                        }
                    }
                    // if (routerQuery.query.account === "success") {
                    //   state.data.stripeSuccess = true;

                    //   state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    //   state.data.successFulMessage = "Stripe Account Setup Completed.";
                    //   // generateInvoice();
                    // }
                    $("#selectedReceivedInvoice").modal("show");
                })
                .catch((error: any) => {
                    // console.log(error);
                    common
                        .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                                        value[0]
                                    );
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isLoading.getInvoiceByPhaseOrTask = false;
                });
        }

        function selectedPdf(link: any) {
            window.open(link, "_blank");
        }

        function choosePaymentMode() {
            state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
            state.ui.error.hasError = false;
            if (state.ui.isPostLoading.confirmPayment) return false;

            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                invoice_id: state.data.selectedInvoice.uuid,
                amount: state.data.selectedInvoice.total,
            };
            console.log("Paylaod Check >>> ", payLoad);
            state.ui.isPostLoading.confirmPayment = true;
            ethitransService
                .payInvoiceByStripe(payLoad)
                .then((res: any) => {
                    console.log("PAYMENT DONE >>> ", res.data.data);
                    // alert("THEN");
                    // emit("logData", res.data.data);
                    if (res.data.data.status === 1) {
                        console.log("Project Todo Response Check:>>> ", res.data.data.link);
                        window.location.replace(`${res.data.data.link}`);
                    }
                    // logData(res.data.data);
                    // hideTaskLogModal();
                })
                .catch((error: any) => {
                    // alert("CATCH WHY");
                    console.log(error);
                    common
                        .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                                        value[0]
                                    );
                                }
                            }
                        });
                })
        }
        onMounted(() => {
            getSmartContractList();
            packageDetailsByUser();
            getProjectInvoiceList({ id: state.projectId });
            const userData: any = localStorage.getItem(
                "current_user_info"
            );
            state.activeUserDetails = JSON.parse(userData);
        });
        return {
            state,
            packageDetailsByUser,
            redirectToPhasesDashboard,
            gotoCreateJobEstimation,
            getSmartContractList,
            deleteJobEstimationClose,
            deleteJobEstimationShow,
            deleteJobEstimation,
            editJobEstimation,
            gotoJobEstimationSend,
            detailsShow,
            detailsClose,
            geJobEstimationDetails,
            downoadPdf,
            sendSmartContract,
            redirectToProject,
            gotoSmartContractPreview,
            getProjectInvoiceList,
            selectedInvoice,
            selectedPdf,
            choosePaymentMode
        };
    },
});
