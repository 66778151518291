
import {
  defineComponent,
  reactive,
  onMounted,
  computed,
  watch,
  ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { common } from "@/common/services/Common.ts";
import { ethitransService } from "@/services/EthitransService.ts";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import SignaturePad from "signature_pad";
import Multiselect from "@vueform/multiselect";

import { useStore } from "vuex";
import introJs from "intro.js";

export default defineComponent({
  name: "CreateNewFrorm",
  components: {
    ErrorList,
    TitleComponent,
    TextBoxComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
    EmptyData,
    Multiselect,
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    // const picked = ref(new Date())
    const router = useRoute();
    const store = useStore();
    const routerUse = useRouter();
    const state = reactive({
      canvas: {} as any,
      canvasId: 0,
      signaturePad: {} as any,
      selectedDate: null,
      title: "edocs",
      heading: "Please create some edocs.",
      picked: new Date(),
      existingMember: {} as any,
      invite: {
        users: [] as any,
        invites: [] as any[],
      },
      data: {
        createNewFormLoad: false,
        formMode: "" as any,
        formHeader: {
          heading: "",
          subHeading: "",
        },
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        selectedChannelByProject: {
          project: {} as any,
          channel: {} as any,
          hasUUID: false,
        } as any,
        index: 0,
        isCalenderHide: false,
        isValidation: false,
        deleteStatus: false,
        activeOption: "",
        formResponse: {} as any,
        selectedFormForEdit: {} as any,
        additionalMessage: "",
        projectUUID: "",
        userEmail: "",
        activeElementIndex: 0,
        userList: [] as any,
        edocsList: {} as any,
        selectedEdoc: {
          metadata: {} as any,
          data: {} as any,
          index: 0,
        } as any,
        elementList: [] as any,
        projectList: [] as any,
        channelList: [] as any,
        sendToUserStatus: false,
        showSelectProject: false,
        formType: "" as any,
        saveShaer: false,
        isSaveShareButton: true,
        project: {} as any,
        channel: {} as any,
        formTypeList: [
          {
            name: "Make Form Public",
            value: "public",
          },
          {
            name: "Make Form Private",
            value: "private",
          },
          {
            name: "Make Form Shareable",
            value: "share",
          },
        ],
        privacyList: [
          {
            name: "Private to this channel",
            code: "channel",
            value: "private to this channel",
          },
          {
            name: "Share within this project",
            code: "project",
            value: "share within this project",
          },
          { name: "Share to Public", code: "public", value: "share to public" },
        ],
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
        shareForm: false,
        showSaveMsg: false,
      },
      newFormData: {
        titleError: false,
        title: "",
        description: "",
        elements: [] as any,
        privacy: "",
      },
      ui: {
        template: {
          msg: "",
          response: {} as any,
        },
        hasErrorStatus: false,
        errorList: [] as any,
        validationList: [] as any,
        isLoading: {
          getprojectList: false,
          getAccountFormList: false,
          accountFormResponseByEmailAndId: false,
          getChannelFormList: false,
        },
        isPostLoading: {
          edocDataMapping: false,
          edocMapped: false,
          getProjectChannelDetails: false,
          createFormTemplate: false,
          getAccountFormRetrieveById: false,
          createNewForm: false,
          selectedUserAccountFormResponse: false,
          getChannelByProject: false,
          getUserList: false,
          getEdocsList: false,
          formMessage: false,
          selectEdoc: false,
          generateEdocStatement: false,
        },
      },
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome ",
                intro:
                  "Welcome to Create New Form! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Choose Edocs",
                element: document.getElementById(
                  "chooseEdocs"
                ) as HTMLInputElement,
                intro: "Create New Form with Edocs Template.",
              },
              {
                title: "Preview",
                element: document.getElementById(
                  "previewNewForm"
                ) as HTMLInputElement,
                intro: "Preview the Creating Form",
              },
              {
                title: "Title of Form",
                element: document.getElementById(
                  "titleOfForm"
                ) as HTMLInputElement,
                intro: "Type the Form Title",
              },
              {
                title: "Description",
                element: document.getElementById(
                  "descriptionOfForm"
                ) as HTMLInputElement,
                intro: "Description of the form.",
              },
              {
                title: "Elements",
                element: document.getElementById(
                  "customizeFormElements"
                ) as HTMLInputElement,
                intro: "Add Custom Elements in Form.",
              },
              {
                title: "Title for TextBox",
                element: document.getElementById(
                  "inputTextBoxTitle"
                ) as HTMLInputElement,
                intro: "Provide the TextBox Title/Question.",
              },
              {
                title: "Select Element Type",
                element: document.getElementById(
                  "selectTextBox"
                ) as HTMLInputElement,
                intro:
                  "Please select the Element ie. TextBox, TextArea, Signature etc....",
              },
              {
                title: "Answer",
                element: document.getElementById(
                  "answerTextBox"
                ) as HTMLInputElement,
                intro: "Answer for User.",
              },
              {
                title: "Required",
                element: document.getElementById(
                  "formElementRequired"
                ) as HTMLInputElement,
                intro: "Element Required config.",
              },
              {
                title: "Duplicate",
                element: document.getElementById(
                  "duplicateSelectedElementRow"
                ) as HTMLInputElement,
                intro: "Create Duplicate Selected Element.",
              },
              {
                title: "Delete",
                element: document.getElementById(
                  "deleteSelectedElementRow"
                ) as HTMLInputElement,
                intro: "Delete Selected Element.",
              },
              {
                title: "Add New Element",
                element: document.getElementById(
                  "addNewElementRow"
                ) as HTMLInputElement,
                intro: "Add New Element after the selected Element.",
              },
              {
                title: "Continue",
                element: document.getElementById(
                  "checkValiditionOfForm"
                ) as HTMLInputElement,
                intro: "Validate and Continue the Form Create Process.",
              },
            ],
          })
          .start();
      }
    }
    // for Digital Signature
    function clearSignature() {
      // Clears the canvas
      state.signaturePad.clear();
    }
    // for Selected Digital Signature
    function clearSelectedSignature(form: any) {
      // Clears the canvas
      form.signaturePad.clear();
    }
    function saveSignature() {
      // Clears the canvas
      console.log("SHOW SIGNATURE DATA >>>", state.signaturePad.toDataURL());
    }
    function hideTemplateModal() {
      $("#templateModal").modal("hide");
    }

    function addTag(tag: any) {
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .formCategorySave(payLoad)
        .then((res: any) => {
          // console.log("createMasterTag:>>> ", res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function tagList() {
      state.ui.errorList = [];
      state.ui.hasErrorStatus = false;
      ethitransService
        .formCategoryList()
        .then((res: any) => {
          console.log("hello from in form", res.data);
          res.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.name;
          });
          state.data.tags.options = res.data ? res.data : [];
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.ui.errorList).then((res: any) => {
            state.ui.hasErrorStatus = true;
            // console.log("CHECK for Error >>>", res)
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.ui.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function setSelectedEdocDetailsInFormData() {
      if (state.data.selectedEdoc.data && !state.data.selectedEdoc.data._id)
        return;
      // state.data.selectedEdoc.data
      // state.data.selectedEdoc.index
      state.newFormData.title = state.data.selectedEdoc.data.title;
      state.newFormData.description = state.data.selectedEdoc.data.description;
      // if (
      //   state.data.elementList[state.data.activeElementIndex].type === "checkBox" ||
      //   state.data.elementList[state.data.activeElementIndex].type === "radioButton" ||
      //   state.data.elementList[state.data.activeElementIndex].type === "single_select"
      // ) {
      //   console.log("OTHERS ELEMENTS")
      // } else {
      state.data.elementList.forEach((item: any) => {
        item.metaDataList = state.data.selectedEdoc.metadata;
        if (item.type === "textbox" || item.type === "textArea") {
          item.metaData = state.data.selectedEdoc.metadata[0];
        }
      });
      state.ui.isPostLoading.edocMapped = false;

      hideTemplateModal();
    }
    function getEdocsList() {
      state.data.edocsList = {};
      const payLoad = {
        items: 10,
        page: 1,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.getEdocsList = true;
      // state.ui.isPostLoading.edocMapped = true;
      ethitransService
        .edocsList(payLoad)
        .then((res: any) => {
          // console.log("getEdocsList Response Check:>>> ", res.data.data);
          state.data.edocsList = res.data.data ? res.data.data : {};
          if (router.query.edocId) {
            if (state.data.edocsList.docs && state.data.edocsList.docs.length) {
              state.data.edocsList.docs.forEach((item: any) => {
                if (router.query.edocId === item._id) {
                  state.data.selectedEdoc.data = item;
                  state.data.selectedEdoc.index = common.findIndex(
                    state.data.edocsList.docs,
                    item
                  );
                  state.ui.isPostLoading.edocMapped = false;
                }
              });
            }
          }
          $("#templateModal").modal("show");
        })
        .catch((error: any) => {
          console.log(error);
          common.sdCatchErr(error, state.ui.errorList).then((res: any) => {
            console.log("CHECK for Error >>>", res.items);
            // state.ui.errorList = res.items;
            state.ui.hasErrorStatus = true;
            if (res.items) {
              state.ui.errorList = res.items;
            }
            if (res.page) {
              // state.ui.hasErrorStatus = true;

              state.ui.errorList = res.page;
            }
            // state.ui.errorList.push(res.uuid[0]);
          });
        })
        .finally(() => {
          state.ui.isPostLoading.getEdocsList = false;
        });
    }
    function addElement() {
      // console.log("Add new element", selectedIndex);
      if (state.data.elementList && state.data.elementList.length) {
        // state.data.elementList.splice(selectedIndex, 0, {
        //   type: 'textbox',
        //   title: "",
        //   required: false,
        //   isActive: false
        // })
        // state.data.elementList.join()
        state.data.elementList[state.data.elementList.length - 1].titleError =
          true;
        if (!state.data.elementList[state.data.elementList.length - 1].title)
          return false;
        state.data.elementList[state.data.elementList.length - 1].titleError =
          false;
        state.data.elementList.push({
          type: "textbox",
          title: "",
          answer: "",
          required: false,
          isActive: true,
          titleError: false,
        });

        // state.data.elementList = state.data.elementList.concat({
        //   type: 'textbox',
        //   title: "",
        //   required: false,
        //   isActive: true
        // });
        // state.data.elementList.forEach((item: any, index: number) => {
        //   if (state.data.elementList.length - 1 === index) {
        //     item.isActive = true;
        //   } else {
        //     item.isActive = false;
        //   }
        // });

        // changeActiveStatus({
        //   type: 'textbox',
        //   title: "",
        //   required: false,
        //   isActive: false
        // })
        // console.log("Index check", state.data.elementList.length -1)
        // console.log("Index", state.data.elementList.length -1)
        // state.data.elementList[state.data.elementList.length -1].isActive = true

        // console.log("LAST ARRAY", state.data.elementList[state.data.elementList.length -1 ])
      } else {
        state.data.elementList.push({
          type: "textbox",
          title: "",
          answer: "",
          required: false,
          isActive: true,
          titleError: false,
        });
      }
      // state.data.elementList[state.data.elementList.length -1 ].isActive = true
    }
    function clearNewFormDatas() {
      state.newFormData.title = "";
      state.newFormData.description = "";
      state.data.elementList = [];

      addElement();
      state.data.userEmail = String(localStorage.getItem("current_user_email"));
    }
    function edocApiCall() {
      if (router.query.edocId) {
        state.ui.isPostLoading.edocMapped = true;
        state.ui.isPostLoading.edocDataMapping = true;
        getEdocsList();
      } else {
        console.log("NO Edoc Id");
        clearNewFormDatas();
      }
      if (!router.query.edocId) return false;
      // state.data.selectedEdoc.data = doc
      // state.data.selectedEdoc.index = common.findIndex(state.data.edocsList.docs,doc)
      // console.log("Edoc Index CHECK ", state.data.selectedEdoc.index);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        document_id: router.query.edocId,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.selectEdoc = true;
      ethitransService
        .getEdocsMetadata(payLoad)
        .then((res: any) => {
          console.log("EDOC API CALL");
          // console.log("getEdocsMetadata Response Check:>>> ", res.data.data);
          state.data.selectedEdoc.metadata = res.data.data.metadata
            ? Object.keys(res.data.data.metadata)
            : {};
          setSelectedEdocDetailsInFormData();
          // $("#templateModal").modal("hide");
          $("#templateModal").modal("hide");
          state.ui.isPostLoading.edocDataMapping = false;
          // toggleTourGuide();
          state.data.createNewFormLoad = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectEdoc = false;
        });
    }
    // watch(()=>state.data.elementList.length (length, oldlength)=> {
    //   elementLength = length
    // }, {
    //   lazy: true
    // }
    // )

    watch(() => router.query.edocId, edocApiCall);
    // eslint-disable-next-line vue/return-in-computed-property
    const formStatus = computed(() => {
      if (router.query.mode === "edit") {
        // console.log("EDIT MODE");
        // state.data.formHeader.heading = ' edit form',
        // state.data.formHeader.subHeading = 'edit form using form elements.'
        return "Edit Mode";
      } else if (router.query.mode === "send") {
        // console.log("SEND MODE");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.formMode = "send";
        return "Send Mode";
      } else {
        // console.log("CREATE MODE");
        return "Create Mode";
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.data.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (state.data.privacy.value === "share within this project") {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
    function addSelectOptions(form: any) {
      // alert("ADD")
      // if(form.options){
      //   console.log("CHECK THE ADD >>", form)
      //   form.options = form.options.concat({ selected: "", option: "" })
      // }
      if (form === state.data.elementList[state.data.activeElementIndex]) {
        if (state.data.elementList[state.data.activeElementIndex].options) {
          state.data.elementList[state.data.activeElementIndex].options =
            state.data.elementList[
              state.data.activeElementIndex
            ].options.concat({
              selected: "",
              option: "",
            });
        }
        // state.data.activeOption = "";
      }
      // state.data.elementList[
      //   common.findIndex(state.data.elementList, form)
      // ].options =
      // state.data.elementList[
      //   common.findIndex(state.data.elementList, form)
      // ].options.push({ selected: "", option: "" });
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const channelStatus = computed(() => {
      if (
        router.query.channelId ||
        router.query.mode === "send" ||
        router.query.mode === "edit"
      ) {
        return true;
      } else {
        return false;
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const dateFormat = computed(() => {
      const day = state.picked;
      const dayWrapper = moment(day);
      const dayString = dayWrapper.format("L");
      return dayString;
    });
    function showHideCalender() {
      state.data.isCalenderHide = !state.data.isCalenderHide;
    }
    function selectMetaData() {
      state.ui.validationList = [];
      state.ui.hasErrorStatus = false;
      state.data.elementList.forEach((element: any, elementIndex: number) => {
        if (state.data.activeElementIndex != elementIndex) {
          // metadata Validation
          if (element.metaDataList) {
            // console.log("MetaData Check", element.metaDataList)
            // // if(state.data.activeElementIndex !=)
            // if(state.data.elementList[state.data.activeElementIndex].metaData === element.metaData){
            //   state.ui.validationList.push(`${state.data.elementList[state.data.activeElementIndex].title} duplicate metadata.`);
            //   state.ui.hsErrorStatus = true;
            // }
            // else {
            //   state.ui.validationList = [];
            //   state.ui.hsErrorStatus = false;
            // }
          }
        }
      });
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const dynamicForm = computed(() => {
      if (state.data.elementList && state.data.elementList.length) {
        state.data.elementList.forEach((element: any, elementIndex: number) => {
          if (element.type === "digitalSignature") {
            // console.log("SIGNATURE", element.signaturePad.isEmpty())
          }
          // metadata Validation
          // if(element.metaDataList){
          //   // console.log("MetaData Check", element.metaDataList)
          //   // if(state.data.activeElementIndex !=)
          //   if(state.data.elementList[state.data.activeElementIndex].metaData === element.metaData){
          //     state.ui.validationList.push("Form Title is required.");
          //     state.ui.hsErrorStatus = true;
          //   }
          //   // else {
          //   //   state.ui.validationList = [];
          //   //   state.ui.hsErrorStatus = false;
          //   // }
          // }
          if (element.type === "datePicker") {
            // const day = state.picked
            // const dayWrapper = moment(day);
            // const dayString = dayWrapper.format('L');
          } else if (element.type != "textbox" || element.type != "textArea") {
            if (element.options && element.options.length) {
              // element.options.forEach((item: any, index: number) => {
              if (
                state.data.elementList[state.data.activeElementIndex] ===
                element
              ) {
                if (
                  state.data.elementList[state.data.activeElementIndex]
                    .options &&
                  state.data.elementList[state.data.activeElementIndex].options
                    .length
                ) {
                  // console.log("DATA CHECK HERE: >>>", state.data.elementList[state.data.activeElementIndex].options)
                  if (state.data.index === state.data.activeElementIndex) {
                    console.log("NO ADDD");
                  }
                  const length =
                    state.data.elementList[state.data.activeElementIndex]
                      .options.length;
                  // console.log("CHECK LENGTH >>>", length)
                  state.data.activeOption =
                    state.data.elementList[
                      state.data.activeElementIndex
                    ].options[length - 1].option;
                  // console.log("OPTION CHECK: >>>", state.data.activeOption)
                  //   state.data.activeOption = item
                  //   // if (item.option) {
                  //   //   element.options = element.options.concat({
                  //   //     selected: "",
                  //   //     option: "",
                  //   //   });
                  //   // }
                }
              }
              // });
              //   if (
              //     element.options[element.options.length - 1].option &&
              //     element.options[element.options.length - 1].option.length
              //   ) {
              //     console.log("Element", element);
              //     addSelectOptions(element);
              //   }
            }
          }
        });
        // validation in isValidation only
        if (state.data.isValidation) {
          state.data.elementList.forEach(
            (element: any, elementIndex: number) => {
              // if(element.type) {
              if (element.title) {
                element.titleError = false;
                return false;
              }
              element.titleError = true;
              if (element.type != "textbox" || element.type != "textArea") {
                console.log("DATA CHECK >>", element);
              }
            }
          );
        }
        return state.data.elementList;
      }
    });
    function hidecalender() {
      state.data.isCalenderHide = false;
    }

    watch(
      () => state.selectedDate,
      () => {
        setTimeout(hidecalender, 1000);
      }
    );
    watch(
      () => state.data.activeElementIndex,
      (newvalue, oldvalue) => {
        // console.log("Data Check new >>>", newvalue);
        // console.log("Data Check Old >>>", oldvalue);
        state.data.index = newvalue;
      }
    );
    // watch(()=>state.picked, (newvalue, oldvalue)=> {
    //   console.log("Date Check new >>>", newvalue)
    //   console.log("Date Check Old >>>", oldvalue)
    //   showHideCalender()
    // });

    watch(
      () => state.data.activeOption,
      (newValue, oldValue) => {
        if (oldValue && newValue) {
          console.log("Title Old: ", oldValue);
          console.log("Title New: ", newValue);
          // if(state.data.index === state.data.activeElementIndex){

          if (state.data.elementList[state.data.activeElementIndex].options) {
            state.data.elementList[state.data.activeElementIndex].options =
              state.data.elementList[
                state.data.activeElementIndex
              ].options.concat({
                selected: "",
                option: "",
              });
          }
          state.data.activeOption = "";
          // }
        }
      }
    );
    function proceedToSaveSelection() {
      // if(state.data.selectedEdoc.metadata && state.data.selectedEdoc.metadata.length){
      //   console.log("HELLO CHECK FOR SELECTIOn")
      //   state.data.showSelectProject = false;
      // } else {

      if (
        state.data.selectedChannelByProject.project &&
        state.data.selectedChannelByProject.project.uuid
      ) {
        state.data.showSelectProject = false;
      }
      if (!router.query.projectId && !router.query.channelId) {
        state.data.selectedChannelByProject.channel = {};
      }
      // }
    }
    function hideProjectSelectModal() {
      // @click="getChannelByProject"
      $("#selectproject").modal("hide");
    }
    function changeExistingMemberInForm() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.data.userList = state.data.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.data.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }

    function previewEdoc(doc: any) {
      // console.log("Selected EDoc", doc)
      window.open(doc.galleryThumbUrl);
    }
    function selectEdoc(doc: any) {
      state.data.selectedEdoc.data = doc;
      state.data.selectedEdoc.index = common.findIndex(
        state.data.edocsList.docs,
        doc
      );
      if (
        router.query.channelId &&
        router.query.projectId &&
        !router.query.edocId
      ) {
        // alert('ALL')
        routerUse.replace({
          name: "admin-channel-create-form",
          query: {
            projectId: router.query.projectId,
            channelId: router.query.channelId,
            edocId: doc._id,
            mode: "create",
          },
        });
      } else {
        // alert('ELSE')
        routerUse.push({
          name: "admin-channel-create-form",
          query: { edocId: doc._id, mode: "create" },
        });
      }
      // $("#templateModal").modal("hide");
      // $(".modal-backdrop").remove();
    }
    // function selectEdoc(doc: any) {
    //   console.log("Selected EDoc", doc)
    //   if(!doc._id) return false
    //   state.data.selectedEdoc.data = doc
    //   state.data.selectedEdoc.index = common.findIndex(state.data.edocsList.docs,doc)
    //     // console.log("Edoc Index CHECK ", state.data.selectedEdoc.index);
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     document_id : doc._id,
    //   };
    //   // console.log("PAYLOAD CHECK ", payLoad);
    //   state.ui.isPostLoading.selectEdoc = true;
    //   ethitransService
    //     .getEdocsMetadata(payLoad)
    //     .then((res: any) => {
    //       console.log("getEdocsMetadata Response Check:>>> ", res.data.data);
    //       state.data.selectedEdoc.metadata = res.data.data.metadata ? Object.keys(res.data.data.metadata) : {};
    //       setSelectedEdocDetailsInFormData()
    //       $("#templateModal").modal("hide");
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.selectEdoc = false;
    //     });
    // }
    // function getUserList() {
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     project_id: state.data.selectedChannelByProject.project.uuid,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     channel_id: state.data.selectedChannelByProject.channel.uuid,
    //     // channel_id: state.data.channel.uuid,
    //   };
    //   // console.log("PAYLOAD CHECK ", payLoad);
    //   state.ui.isPostLoading.getUserList = true;
    //   ethitransService
    //     .userList(payLoad)
    //     .then((res: any) => {
    //       // console.log("UserList Response Check:>>> ", res.data.data);
    //       state.data.userList = res.data.data ? res.data.data : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.getUserList = false;
    //     });
    // }
    function getUserList() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // project_id: state.data.selectedChannelByProject.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.selectedChannelByProject.channel.uuid,
        // channel_id: state.data.channel.uuid,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.getUserList = true;
      ethitransService
        .channelMemberList(payLoad)
        .then((res: any) => {
          // console.log("UserList Response Check:>>> ", res.data.data);
          state.data.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserList = false;
        });
    }
    function getChannelByProject(projectUUID: string) {
      // function getChannelByProject() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectUUID,
      };
      state.ui.isPostLoading.getChannelByProject = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          // console.log("getChannelByProject Response Check:>>> ", res.data.data);

          state.data.channelList = res.data.data ? res.data.data : [];
          state.data.selectedChannelByProject.channel =
            state.data.channelList.filter(
              (item: any) => item.uuid === router.query.channelId
            )[0];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getChannelByProject = false;
        });
    }
    // function getChannelByProject() {
    function getChannelByProjectChange() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.selectedChannelByProject.project.uuid,
      };
      state.ui.isPostLoading.getChannelByProject = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          // console.log("getChannelByProject Response Check:>>> ", res.data.data);

          state.data.channelList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getChannelByProject = false;
        });
    }
    function validateCreateNewForm() {
      state.ui.validationList = [];
      state.ui.hasErrorStatus = false;
      state.newFormData.titleError = false;

      // if (state.data.selectedChannelByProject.project && !state.data.selectedChannelByProject.project.uuid) {
      // // if (state.data.project && !state.data.project.uuid) {
      //   state.ui.validationList.push("Please select the project.");
      // }

      if (!state.data.elementList && !state.data.elementList.length) {
        state.ui.validationList.push("At least 1 element is required.");
      } else {
        state.data.elementList.forEach((item: any) => {
          // console.log("Element >>> ", item);
          if (!item.title) {
            state.ui.validationList.push("Please enter the Element's title.");
          }
        });
      }
      if (!state.newFormData.title) {
        state.ui.validationList.push("Form Title is required.");
        state.newFormData.titleError = true;
      }

      if (!state.ui.validationList && !state.ui.validationList.length) {
        state.ui.hasErrorStatus = false;
      } else if (state.ui.validationList.length != 0) {
        state.ui.hasErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.ui.hsErrorStatus);
    }
    function successfulCreateNewForm() {
      // alert('Hello')
      $("#successfulModal").modal("show");
    }
    function channelCommunicationForm(id: any) {
      const data = state.data.formResponse;
      // state.owner
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // form_data: {
        type: "form",
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_id: data._id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_name: data.title,
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_desc: data.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sent_to_users: data.sentToUsers,
        // },
      };
      // console.log("<< ChannelCommunicationForm CHECK >>", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .channelCommunicationFormData(payLoad)
        .then((res: any) => {
          // console.log("ChannelCommunicationForm Response Check:>>> ", res.data);
          // state.communicationList = res.data ? res.data : [];
          // state.message = "";
          // // console.log("CommunicationList Response Check:>>> ", res.data.data);
          // state.channel = res.data.data.channel ? res.data.data.channel : {};
          // res.data.data.messages.forEach((item: any) => {
          //   if (item.form_data != null) {
          //     console.log("check msg :>>", item.form_data);
          //     if (item.form_data && item.form_data.sent_to_users) {
          //       console.log(
          //         "Final :>>",
          //         JSON.parse(item.form_data.sent_to_users)
          //       );
          //       // eslint-disable-next-line @typescript-eslint/camelcase
          //       item.form_data.sent_to_users = JSON.parse(
          //         item.form_data.sent_to_users
          //       );
          //     }
          //   }
          // });
          // state.communicationList = res.data.data.messages
          //   ? res.data.data.messages
          //   : [];
          // state.memberList = res.data.data.channel.members
          //   ? res.data.data.channel.members
          //   : [];
          // scrollAtButton();
          // scrollToLast();
          // $("#templateupload").modal("hide");
          successfulCreateNewForm();
          
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function formMessage(msg: string, userList: any) {
      const form = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        type: "form",
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_id: state.data.formResponse._id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_name: state.data.formResponse.title,
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_desc: state.data.formResponse.description
      };
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.selectedChannelByProject.channel.uuid,
        // channel_id: state.data.channel.uuid,
        message: state.data.additionalMessage,
        usersList: userList,
        type: "form",
        form:form
      };
      state.ui.isPostLoading.formMessage = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          // console.log(
          //   "<<<Create Communication Response Check:>>> ",
          //   res.data.data
          // );
          // state.communicationList = res.data ? res.data : [];
          // state.message = "";
          // // console.log("CommunicationList Response Check:>>> ", res.data.data);
          // state.channel = res.data.data.channel ? res.data.data.channel : {};
          // res.data.data.messages.forEach((item: any) => {
          //   if (item.form_data != null) {
          //     console.log("check msg :>>", item.form_data);
          //     if (item.form_data && item.form_data.sent_to_users) {
          //       console.log(
          //         "Final :>>",
          //         JSON.parse(item.form_data.sent_to_users)
          //       );
          //       // eslint-disable-next-line @typescript-eslint/camelcase
          //       item.form_data.sent_to_users = JSON.parse(
          //         item.form_data.sent_to_users
          //       );
          //     }
          //   }
          // });
          // state.communicationList = res.data.data.messages
          //   ? res.data.data.messages
          //   : [];
          // state.memberList = res.data.data.channel.members
          //   ? res.data.data.channel.members
          //   : [];
          // scrollAtButton();
          successfulCreateNewForm();
          // channelCommunicationForm(res.data.data.communication.id);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.formMessage = false;
        });
    }
    function shareAndSave() {
      state.data.privacy.code = "project";
      state.data.privacy.value = "share within this project";
      state.data.privacy.name = "Share within this project";
      state.data.showSaveMsg = true;
      state.data.shareForm = true;
    }
    function hideSendOptionModal() {
      state.data.shareForm = false;
      state.data.showSelectProject = false;
      if (
        (router.query.channelId && router.query.projectId) ||
        router.query.mode
      ) {
        state.data.showSelectProject = false;
      } else {
        state.data.selectedChannelByProject.project = {};
      }
      $("#sendoption").modal("hide");
    }
    function sendBack() {
      routerUse.back();
    }
    function closeSuccessfulModal() {
      $(".modal-backdrop").remove();
      if (router.query.channelId && router.query.projectId) {
        routerUse.push({
          name: "admin-channel",
          params: {
            channelId: state.data.selectedChannelByProject.channel.uuid,
          },
        });
        console.log("admin-channel");
      } else {
        routerUse.back();
      }
    }
 
    function createNewForm() {
      if (state.ui.isPostLoading.createNewForm) return false;
      // console.log("After");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      let payLoad = {};
      if (state.data.formType == "public") {
        payLoad = {
          title: state.newFormData.title,
          formType: state.data.formType,
          description: state.newFormData.description,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_id: state.data.selectedEdoc.data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_metadata: state.data.selectedEdoc.data.metadata,
          elements: state.data.elementList,
          sentToUsers: usersArray ? usersArray : [],
          categories: state.data.tags.value,
          privacy: {
            code: "public",
            name: "share to public",
          },
          owner: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_by: {
              name: localStorage.getItem("current_user_details"),
              email: localStorage.getItem("current_user_email"),
            },
            //   // project_id: state.data.project.uuid,
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   project_id: state.data.selectedChannelByProject.project.uuid,
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   channel_id: state.data.selectedChannelByProject.channel.uuid,
            //   // channel_id: state.data.channel.uuid,
            //   project: {
            //   //added
            //   id: state.data.selectedChannelByProject.project.id,
            //   uuid: state.data.selectedChannelByProject.project.uuid,
            //   name: state.data.selectedChannelByProject.project.name,
            //   description: state.data.selectedChannelByProject.project.description,
            // },
            // channel: {
            //   //added
            //   id: state.data.selectedChannelByProject.channel.id,
            //   uuid: state.data.selectedChannelByProject.channel.uuid,
            //   name: state.data.selectedChannelByProject.channel.name,
            //   description: state.data.selectedChannelByProject.channel.description,
            // },
          },
        };
      } else if (
        state.data.formType == "private" &&
        !state.data.selectedChannelByProject.project
      ) {
        payLoad = {
          title: state.newFormData.title,
          formType: state.data.formType,
          description: state.newFormData.description,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_id: state.data.selectedEdoc.data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_metadata: state.data.selectedEdoc.data.metadata,
          elements: state.data.elementList,
          sentToUsers: usersArray ? usersArray : [],
          categories: state.data.tags.value,
          privacy: {
            code: "private",
            name: "share to private",
          },
          owner: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_by: {
              name: localStorage.getItem("current_user_details"),
              email: localStorage.getItem("current_user_email"),
            },
            //   // project_id: state.data.project.uuid,
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   project_id: state.data.selectedChannelByProject.project.uuid,
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   channel_id: state.data.selectedChannelByProject.channel.uuid,
            //   // channel_id: state.data.channel.uuid,
            //   project: {
            //   //added
            //   id: state.data.selectedChannelByProject.project.id,
            //   uuid: state.data.selectedChannelByProject.project.uuid,
            //   name: state.data.selectedChannelByProject.project.name,
            //   description: state.data.selectedChannelByProject.project.description,
            // },
            // channel: {
            //   //added
            //   id: state.data.selectedChannelByProject.channel.id,
            //   uuid: state.data.selectedChannelByProject.channel.uuid,
            //   name: state.data.selectedChannelByProject.channel.name,
            //   description: state.data.selectedChannelByProject.channel.description,
            // },
          },
        };
      } else if (
        state.data.selectedFormForEdit &&
        state.data.selectedFormForEdit.edoc_id
      ) {
        payLoad = {
          title: state.newFormData.title,
          description: state.newFormData.description,
          formType: state.data.formType,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_id: state.data.selectedFormForEdit.edoc_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_metadata: state.data.selectedFormForEdit.edoc_metadata,
          categories: state.data.tags.value,
          elements: state.data.elementList,
          sentToUsers: usersArray ? usersArray : [],
          privacy: {
            code: state.data.privacy.code,
            name: state.data.privacy.value,
          },
          owner: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_by: {
              name: localStorage.getItem("current_user_details"),
              email: localStorage.getItem("current_user_email"),
            },
            // project_id: state.data.project.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_id: state.data.selectedChannelByProject.project.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_id: state.data.selectedChannelByProject.channel.uuid,
            // channel_id: state.data.channel.uuid,
            project: {
              //added
              id: state.data.selectedChannelByProject.project.id,
              uuid: state.data.selectedChannelByProject.project.uuid,
              name: state.data.selectedChannelByProject.project.name,
              description:
                state.data.selectedChannelByProject.project.description,
            },
            channel: {
              //added
              id: state.data.selectedChannelByProject.channel.id,
              uuid: state.data.selectedChannelByProject.channel.uuid,
              name: state.data.selectedChannelByProject.channel.name,
              description:
                state.data.selectedChannelByProject.channel.description,
            },
          },
        };
      } else {
        payLoad = {
          title: state.newFormData.title,
          formType: state.data.formType,
          description: state.newFormData.description,
          elements: state.data.elementList,
          sentToUsers: usersArray ? usersArray : [],
          categories: state.data.tags.value,
          privacy: {
            code: state.data.privacy.code,
            name: state.data.privacy.value,
          },
          owner: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_by: {
              name: localStorage.getItem("current_user_details"),
              email: localStorage.getItem("current_user_email"),
            },
            // project_id: state.data.project.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_id: state.data.selectedChannelByProject.project.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_id: state.data.selectedChannelByProject.channel.uuid,
            // channel_id: state.data.channel.uuid,
            project: {
              //added
              id: state.data.selectedChannelByProject.project.id,
              uuid: state.data.selectedChannelByProject.project.uuid,
              name: state.data.selectedChannelByProject.project.name,
              description:
                state.data.selectedChannelByProject.project.description,
            },
            channel: {
              //added
              id: state.data.selectedChannelByProject.channel.id,
              uuid: state.data.selectedChannelByProject.channel.uuid,
              name: state.data.selectedChannelByProject.channel.name,
              description:
                state.data.selectedChannelByProject.channel.description,
            },
          },
        };
      }
      console.log("New Form PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createNewForm = true;
      // state.ui.isPostLoading.edocDataMapping = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          // console.log("createNewForm Response Check:>>> ", res.data);
          state.data.formResponse = res.data;

          if (
            state.data.additionalMessage ||
            (usersArray && usersArray.length)
          ) {
            formMessage(state.data.additionalMessage, usersArray);
          }
          // routerUse.go(-1);

          $(".modal-backdrop").remove();
          hideSendOptionModal();
          // successfulCreateNewForm();
          // if (
          //   state.data.privacy.code === "channel" &&
          //   state.data.privacy.value === "private to this channel"
          // ) {
          //   console.log("CHannel");
          // } else if (
          //   state.data.privacy.code === "project" &&
          //   state.data.privacy.value === "share within this project"
          // ) {
          //   console.log("Project");
          // } else {
          //   console.log("Public");
          // }
          // if(state.data.selectedEdoc.metadata && state.data.selectedEdoc.metadata.length){
          //   // generateEdocStatement()
          // }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createNewForm = false;
        });
    }

    function validateChannelAndUser() {
      // console.log("Validate Users");
      createNewForm();
    }
    function sendOptionBack() {
      if (
        (router.query.channelId && router.query.projectId) ||
        router.query.formId
      ) {
        // state.data.showSelectProject = false;
      } else {
        state.data.showSelectProject = true;
      }
      state.data.sendToUserStatus = false;
      state.data.channel = {};
      state.invite.users = [];
      state.data.additionalMessage = "";
      state.data.privacy.code = "channel";
      state.data.privacy.value = "private to this channel";
      state.data.shareForm = false;
      state.data.showSaveMsg = false;
    }
    function showSendToUsers() {
      state.data.sendToUserStatus = true;
      state.data.shareForm = true;
      state.data.privacy.code = "channel";
      state.data.privacy.value = "private to this channel";
      state.data.privacy.name = "Private to this channel";
      if (
        state.data.selectedChannelByProject.channel &&
        state.data.selectedChannelByProject.channel.uuid
      ) {
        getUserList();
      }
      // if(router.query.formId && (router.query.mode === 'edit' || router.query.mode === 'send')){
      // if (
      //   (state.data.selectedChannelByProject.project &&
      //     !state.data.selectedChannelByProject.project.uuid) ||
      //   (state.data.selectedChannelByProject.channel &&
      //     !state.data.selectedChannelByProject.channel.uuid)
      // ) {
      //   getChannelByProjectChange();
      // }
    }

    function saveDataAndShare() {
      state.data.saveShaer = true;
      state.data.isSaveShareButton = false;
    }

    function deleteSelecedOption(form: any, options: any) {
      // console.log("Check Delete", form);
      // console.log("Check Delete", options);
      const selectedIndex = common.findIndex(form.options, options);
      // console.log("Index", selectedIndex);
      // alert()
      if (
        form.type === "checkBox" ||
        form.type === "radioButton" ||
        form.type === "single_select"
      ) {
        if (form.options && form.options.length > 1) {
          form.options = form.options.filter(
            (item: any, index: any) => index != selectedIndex
          );
        }
      }
    }
    function checkValidation() {
      state.data.isValidation = true;

      state.data.elementList.forEach((item: any) => {
        if (
          item.type != "textbox" ||
          item.type != "textArea" ||
          item.type != "digitalSignature" ||
          item.type != "datePicker"
        ) {
          if (item.options && item.options.length) {
            item.options.forEach((data: any) => {
              if (!data.option) {
                deleteSelecedOption(item, data);
              }
              data.metaData = item.metaData;
            });
          }
        }
      });
      validateCreateNewForm();
      // console.log("CHECK", state.ui.validationList);
      if (
        state.ui.hasErrorStatus ||
        (state.ui.validationList && state.ui.validationList.length)
      ) {
        // this changes the scrolling behavior to "smooth"
        window.scrollTo({ top: 0, behavior: "smooth" });
        return false;
      }
      // createNewForm()
      state.data.sendToUserStatus = false;
      if (
        (router.query.channelId && router.query.projectId) ||
        router.query.formId
      ) {
        state.data.showSelectProject = false;
      } else {
        state.data.showSelectProject = true;
      }
      if (router.query.mode === "send") {
        // alert()
        // state.data.sendToUserStatus = true;
        showSendToUsers();
      }
      state.invite.users = [];
      $("#sendoption").modal("show");
      // $("#sendform").modal("show");
    }
    function deleteEmpty(selectedElement: any) {
      const selectedIndex = common.findIndex(
        state.data.elementList,
        selectedElement
      );
      if (
        selectedElement.type != "textbox" ||
        selectedElement.type != "textArea" ||
        selectedElement.type != "datePicker" ||
        selectedElement.type != "digitalSignature"
      ) {
        if (
          state.data.elementList[selectedIndex].options &&
          state.data.elementList[selectedIndex].options.length
        ) {
          state.data.elementList[selectedIndex].options.forEach((item: any) => {
            if (!item.option) {
              deleteSelecedOption(selectedElement, item);
            }
          });
        }
      }
      state.data.deleteStatus = false;
    }
    function changeActiveStatus(selectedElement: any) {
      // state.data.deleteStatus = true
      // deleteEmpty (selectedElement)

      // alert("Status");
      state.data.activeElementIndex = common.findIndex(
        state.data.elementList,
        selectedElement
      );
      state.data.elementList.forEach((item: any, index: number) => {
        if (item === selectedElement) {
          item.isActive = true;
          // console.log("SELECTED CHECK", item);
        } else {
          item.isActive = false;
        }
      });
      // state.data.deleteStatus = false
      // console.log("CHECK FINAL LIST", state.data.elementList);
    }
    // function checkDataEmpty() {
    //   signaturePad.isEmpty();
    // }
    function selectedFormElement(form: any) {
      console.log("Selected Option", form);
      if (form.type === "textbox" || form.type === "textArea") {
        // console.log("Selected Option", form);
        form.answer = "";
      } else if (form.type === "datePicker") {
        form.date = "";
        // form.date = new Date();
        form.show = false;
        form.convertedDate = "";
      } else if (form.type === "digitalSignature") {
        // state.canvasId
        const formIndex = common.findIndex(state.data.elementList, form);
        // console.log("INDEX", formIndex);
        form.canvasName = `myCanvas${state.canvasId}`;
        //  console.log("SHOW SIGNATURE DATA >>>", form.canvas.toDataURL());
        form.canvas = document.getElementById(form.canvasName);
        // // // form.canvas = document.querySelector("canvas");
        if (form.canvas) {
          form.signaturePad = new SignaturePad(form.canvas, {
            backgroundColor: "rgb(255, 255, 255)",
          });
        }
        if (
          state.data.selectedEdoc.metadata &&
          state.data.selectedEdoc.metadata.length
        ) {
          state.data.selectedEdoc.metadata =
            state.data.selectedEdoc.metadata.concat(`Sig-${state.canvasId}`);
          form.metaDataList = form.metaDataList.concat(`Sig-${state.canvasId}`);
        }
        // if(form.signaturePad.isEmpty()){
        //   // form.signaturePad
        //   console.log("EMPTY")
        // } else {
        //   console.log("SIGNATURE")

        // }
        form.signature = "";
        state.canvasId = state.canvasId + 1;
      } else {
        if (form.options && form.options.length) {
          // console.log("NO OPTIOns");
        } else {
          form.options = [];
          if (
            state.data.elementList[state.data.activeElementIndex].type ===
              "checkBox" ||
            state.data.elementList[state.data.activeElementIndex].type ===
              "radioButton" ||
            state.data.elementList[state.data.activeElementIndex].type ===
              "single_select"
          ) {
            form.options.push({ selected: "", option: "", metaData: "" });
            // delete form.metaData;
            delete form.answer;
          } else {
            form.options.push({ selected: "", option: "" });
            delete form.answer;
          }
        }
      }
    }
    function setActive(selectedIndex: number) {
      state.data.elementList.forEach((item: any, index: number) => {
        if (state.data.elementList[selectedIndex + 1] === item) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
    }

    function validateAddNewElement(selectedElement: any) {
      const selectedIndex = common.findIndex(
        state.data.elementList,
        selectedElement
      );
      if (!state.data.elementList[selectedIndex].title) {
        // console.log(
        //   "Active Element for Add Request Before >>>",
        //   state.data.elementList[selectedIndex]
        // );
        state.data.elementList[selectedIndex].titleError = true;
        // console.log(
        //   "Active Element for Add Request After >>>",
        //   state.data.elementList[selectedIndex]
        // );
      }
      if (
        state.data.elementList[selectedIndex].type != "textbox" ||
        state.data.elementList[selectedIndex].type != "textArea"
      ) {
        if (state.data.elementList[selectedIndex].options) {
          state.data.elementList[selectedIndex].options.forEach(
            (selectedOption: any) => {
              // console.log("Validation selectedOption >>> ", selectedOption);
              if (!selectedOption.option) {
                selectedOption.optionError = true;
              }
            }
          );
        }
      }
      //  state.data.elementList[state.data.elementList.length-1].titleError =  true
      //   if(!state.data.elementList[state.data.elementList.length-1].title) return false
      //   state.data.elementList[state.data.elementList.length-1].titleError =  false
    }
    function addElementNew(selectedElement: any) {
      // selectedElement.isActive = false
      // console.log("Add new element", selectedElement);
      const selectedIndex = common.findIndex(
        state.data.elementList,
        selectedElement
      );
      // state.data.elementList[selectedIndex].titleError = false;
      // state.data.elementList[selectedIndex].options.forEach(
      //     (selectedOption: any) => {
      //       console.log("Validation selectedOption >>> ", selectedOption);
      //       if (!selectedOption.option) {
      //         selectedOption.optionError = false;
      //       }
      //     }
      //   );
      state.data.elementList[selectedIndex].titleError = false;
      if (
        selectedElement.type != "textbox" ||
        selectedElement.type != "textArea" ||
        selectedElement.type != "datePicker" ||
        selectedElement.type != "digitalSignature"
      ) {
        if (
          state.data.elementList[selectedIndex].options &&
          state.data.elementList[selectedIndex].options.length
        ) {
          state.data.elementList[selectedIndex].options.forEach((item: any) => {
            if (!item.option) {
              deleteSelecedOption(selectedElement, item);
            }
            item.metaData = selectedElement.metaData;
          });
        }
      }

      validateAddNewElement(selectedElement);

      if (state.data.elementList[selectedIndex].titleError) {
        return false;
      }
      // if (
      //   state.data.elementList[selectedIndex].titleError ||
      //   (!state.data.elementList[selectedIndex].options &&
      //     !state.data.elementList[selectedIndex].options.length)
      // ) {
      //   return false;
      // }

      if (state.data.elementList && state.data.elementList.length) {
        if (
          state.data.selectedEdoc.metadata &&
          state.data.selectedEdoc.metadata.length
        ) {
          if (
            state.data.elementList.length !=
            state.data.elementList[selectedIndex].metaDataList.length
          ) {
            state.data.elementList.forEach((item: any) => {
              // console.log("ELEMENT CHECK ", item)
              if (item.metaDataList && item.metaDataList.length) {
                // item.metaData &&
                item.metaDataList.forEach((meta: any, index: number) => {
                  // console.log("META CHECK ", meta, index)
                  if (item.metaData === meta) {
                    // console.log("META CHECK CHECK MATCH", meta, index)
                    if (index < item.metaDataList.length - 1) {
                      console.log("CHECK THE INDEX", index);
                      state.data.activeElementIndex = selectedIndex + 1;
                      state.data.elementList.splice(selectedIndex + 1, 0, {
                        type: "textbox",
                        title: "",
                        metaData: state.data.selectedEdoc.metadata[index + 1],
                        metaDataList: state.data.selectedEdoc.metadata,
                        required: false,
                        isActive: true,
                      });
                      state.data.elementList.join();
                      setActive(selectedIndex);
                    }
                  }
                });
              }
            });
          }
        } else {
          state.data.activeElementIndex = selectedIndex + 1;
          state.data.elementList.splice(selectedIndex + 1, 0, {
            type: "textbox",
            title: "",
            required: false,
            isActive: true,
          });
          state.data.elementList.join();
          setActive(selectedIndex);
        }
      }
      state.data.isValidation = true;
      // else {
      //   state.data.elementList.push({
      //     type: 'textbox',
      //     title: "",
      //     required: false,
      //     isActive: true,
      //   });
      // }
      // state.data.elementList[state.data.elementList.length -1 ].isActive = true
    }
    function deleteSelectedElement(form: any) {
      if (form.type === "digitalSignature") {
        state.canvasId = state.canvasId - 1;
        state.data.selectedEdoc.metadata =
          state.data.selectedEdoc.metadata.filter(
            (item: any) => item != form.canvasName
          );
      }
      state.data.elementList.forEach((item: any, index: number) => {
        if (item.type === "digitalSignature") {
          item.metaDataList = item.metaDataList.filter(
            (item: any) => item != form.metaData
          );
        }
      });
      // alert("Hele");
      // console.log("Add new element", form);
      const selectedIndex = common.findIndex(state.data.elementList, form);
      if (state.data.elementList && state.data.elementList.length <= 1)
        return false;
      // console.log("Delete Selected Element", form);
      setActive(selectedIndex);
      state.data.elementList = state.data.elementList.filter(
        (item: any) => item != form
      );
      // state.data.activeElementIndex = state.data.activeElementIndex-1
    }
    function duplicateSelectedElement(form: any) {
      const index = common.findIndex(state.data.elementList, form);
      if (state.data.elementList && state.data.elementList.length) {
        // state.data.elementList = state.data.elementList.concat(
        //   JSON.parse(JSON.stringify(form))
        // );
        // state.data.activeElementIndex = index +1

        state.data.elementList.splice(
          index + 1,
          0,
          JSON.parse(JSON.stringify(form))
        );
        state.data.activeElementIndex = index + 1;
        form.isActive = false;
        state.data.elementList.join();
        // const elementIndex = common.findIndex(state.data.elementList, form);
        // console.log("For Duplicate Selected ID", elementIndex);
        // console.log("Duplicate Selected", form);
        // obj =
        // state.data.elementList.forEach((item: any, index: number) => {
        //   if (item != elementIndex) {
        //     item.isActive = false;
        //   }
        //   // else {
        //   //   item.isActive = true;
        //   // }
        // });
      }
    }
    function hideCreateForm() {
      $("#showCreatedForm").modal("hide");
    }
    function checkFormData() {
      // console.log("<<< Form List check >>> ", state.data.elementList);
      if (!state.newFormData.title || !state.newFormData.description)
        return false;
      state.data.elementList.forEach((item: any) => {
        if (item.type != "textbox" || item.type != "textArea") {
          if (item.options && item.options.length) {
            item.options.forEach((data: any) => {
              if (!data.option) {
                deleteSelecedOption(item, data);
              }
            });
          }
        }
      });
      $("#showCreatedForm").modal("show");
    }
    function showProjectSelectModal() {
      state.data.project = {};
      $("#selectproject").modal("show");
    }
    function getprojectList() {
      // console.log("getprojectList WOWO:>>>");
      const payLoad = {
        status: "active",
        sort: "asc",
      };
      state.ui.isLoading.getprojectList = true;
      ethitransService
        .getprojectList(payLoad)
        .then((res: any) => {
          // console.log("getprojectList Check:>>> ", res.data.data.projects);
          state.data.projectList = res.data.data.projects
            ? res.data.data.projects
            : [];
          state.data.selectedChannelByProject.project =
            state.data.projectList.filter(
              (item: any) => item.uuid === state.data.projectUUID
            )[0];
          // state.data.selectedChannelByProject.project = state.data.projectList.filter(
          //   (item: any) => item.uuid === router.query.projectId)[0];
          if (
            state.data.selectedChannelByProject.project &&
            state.data.selectedChannelByProject.project.uuid
          ) {
            getChannelByProject(
              state.data.selectedChannelByProject.project.uuid
            );
          }
          // toggleTourGuide();
          state.data.createNewFormLoad = true;
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getprojectList = false;
        });
    }

    function getProjectChannelDetails(projectId: any, channelId: any) {
      debugger;
      state.data.selectedChannelByProject.project.uuid = router.query.projectId;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.selectedChannelByProject.channel;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isPostLoading.getProjectChannelDetails = true;
      ethitransService
        .projectChannelDetails(payLoad)
        .then((res: any) => {
          debugger;
          // console.log("projectChannelDetails Check:>>> ", res.data.data);
          if (res.data.data.project && res.data.data.project.uuid) {
            state.data.selectedChannelByProject.project = res.data.data.project;
          }
          if (res.data.data.channel && res.data.data.channel.uuid) {
            state.data.selectedChannelByProject.channel = res.data.data.channel;
          }
          if (router.query.mode === "send") {
            checkValidation();
          }
          // state.data.projectList = res.data.data.projects
          //   ? res.data.data.projects
          //   : [];
          // state.data.selectedChannelByProject.project = state.data.projectList.filter(
          //   (item: any) => item.uuid === router.query.projectId
          // )[0];
          // if (
          //   state.data.selectedChannelByProject.project &&
          //   state.data.selectedChannelByProject.project.uuid
          // ) {
          //   getChannelByProject(
          //     state.data.selectedChannelByProject.project.uuid
          //   );
          // }
          // state.selectedFormForEdit = res.data ? res.data : {};
          // toggleTourGuide();
          state.data.createNewFormLoad = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getProjectChannelDetails = false;
        });
    }
    function getAccountFormRetrieveById(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "<<<AccountFormRetrieveById Check:>>> ",
            res.data.categories
          );
          if (res.data.elements && res.data.elements.length) {
            res.data.elements.forEach((item: any) => {
              item.isActive = false;
            });
            res.data.elements[res.data.elements.length - 1].isActive = true;
            state.data.activeElementIndex = res.data.elements.length - 1;
            state.data.elementList = res.data.elements;
          }
          if (res.data.title) {
            state.newFormData.title = res.data.title;
          }
          if (res.data.formType) {
            state.data.formType = res.data.formType;
          }
          if (res.data.description) {
            state.newFormData.description = res.data.description;
          }
          // state.formData = res.data;
          state.data.selectedFormForEdit = res.data ? res.data : {};
          if (
            res.data.owner.project_id ||
            (res.data.owner.project_id && res.data.owner.channel_id)
          ) {
            getProjectChannelDetails(
              res.data.owner.project_id,
              res.data.owner.channel_id
            );
          }
          // else if(res.data.owner.project_id && !res.data.owner.channel_id) {
          //   state.data.projectUUID = res.data.owner.project_id
          //   getprojectList();
          // }
          // toggleTourGuide();
          state.data.tags.value = res.data.categories;

          getprojectList();
          state.data.createNewFormLoad = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function redirectToTemplateSearch() {
      // admin-template-search
      routerUse.push({
        name: "admin-template-search",
      });
    }
    function resizeCanvas() {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      state.canvas.width = state.canvas.offsetWidth * ratio;
      state.canvas.height = state.canvas.offsetHeight * ratio;
      state.canvas.getContext("2d").scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      state.signaturePad.clear();
    }
    onMounted(() => {
      tagList();
      getprojectList();
      state.canvas = document.getElementById("myCanvas");
      if (router.query.edocId && router.query.mode === "create") {
        state.ui.isPostLoading.edocDataMapping = true;
        edocApiCall();
      }
      if (
        router.query.formId &&
        (router.query.mode === "edit" || router.query.mode === "send")
      ) {
        // const status = Boolean(router.query.edit)
        // console.log("check Before", typeof router.query.edit)
        // console.log("check", typeof status)
        if (
          (!router.query.formId && router.query.mode === "edit") ||
          (!router.query.formId && router.query.mode === "send")
        )
          return false;
        // alert("EDIT MODE")
        getAccountFormRetrieveById(router.query.formId);
        // }
      } else if (router.query.channelId && router.query.projectId) {
        console.log("CHECK THE Project ID >>> ", router.query.projectId);
        console.log("CHECK THE Channel ID >>> ", router.query.channelId);
        state.data.selectedChannelByProject.hasUUID = false;
        getProjectChannelDetails(
          router.query.projectId,
          router.query.channelId
        );
      } else {
        state.data.selectedChannelByProject.hasUUID = true;
        getprojectList();
      }
      addElement();
      state.data.userEmail = String(localStorage.getItem("current_user_email"));
      // setInterval(
      //   toggleTourGuide
      // , 1000);
    });
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue);
          toggleTourGuide();
        }
      }
    );
    // created(()=> {
    //

    // })
    return {
      state,
      hideCreateForm,
      toggleTourGuide,
      hideTemplateModal,
      clearNewFormDatas,
      // picked,
      deleteEmpty,
      dateFormat,
      // activeElement,
      formStatus,
      channelStatus,
      redirectToTemplateSearch,
      getChannelByProjectChange,
      proceedToSaveSelection,
      channelCommunicationForm,
      shareAndSave,
      formPrivacyMessage,
      validateChannelAndUser,
      setActive,
      getUserList,
      formMessage,
      removeSelectedMember,
      changeExistingMemberInForm,
      // validateElement,
      validateAddNewElement,
      hideProjectSelectModal,
      addElementNew,
      getChannelByProject,
      hideSendOptionModal,
      sendOptionBack,
      showSendToUsers,
      showProjectSelectModal,
      checkValidation,
      validateCreateNewForm,
      deleteSelecedOption,
      createNewForm,
      changeActiveStatus,
      addSelectOptions,
      selectedFormElement,
      addElement,
      deleteSelectedElement,
      checkFormData,
      duplicateSelectedElement,
      dynamicForm,
      sendBack,
      showHideCalender,
      getEdocsList,
      selectEdoc,
      setSelectedEdocDetailsInFormData,
      selectMetaData,
      previewEdoc,
      edocApiCall,
      hidecalender,
      clearSignature,
      resizeCanvas,
      saveSignature,
      clearSelectedSignature,
      closeSuccessfulModal,
      successfulCreateNewForm,
      saveDataAndShare,
      tagList,
      addTag,
    };
  },
});
