
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter } from "vue-router";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import Multiselect from 'vue-multiselect';
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import Editor from "@/components/mention/Editor.vue";
import SignaturePadModal from "@/components/modal/SignaturePadModal.vue";
import SignatureUploadModal from "@/components/modal/SignatureUploadModal.vue";
import VoiceToText from "@/components/VoiceToText.vue";
import GoogleAddressCustomer from "../commonDesign/GoogleAddressCustomer.vue";
import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue";
import { useI18n } from 'vue-i18n';


export default defineComponent({
    name: "SendJobEstimation",
    components: {
        Multiselect,
        SuccessfulModalCustom,
        ErrorList,
        Editor,
        SignaturePadModal,
        SignatureUploadModal,
        VoiceToText,
        GoogleAddressCustomer,
        AppPhoneNumberInput

    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const {
            params: { estimationId },
        } = useRoute();
        const isInvalid = ref(false);
        const isPhoneInvalid = ref(false);
        const canvas: any = ref(null);
        const signaturePad: any = null;
        const latitude = ref<number>(36.0755227);  // Default latitude
        const longitude = ref<number>(-79.8203066);  // Default longitude
        const address = ref<string>('');
        const showMap = ref<boolean>(false);

        const updateLatitude = (newLatitude: number) => {
            latitude.value = newLatitude;
        };

        const updateLongitude = (newLongitude: number) => {
            longitude.value = newLongitude;
        };
        const customer = reactive({
            // eslint-disable-next-line @typescript-eslint/camelcase
            address_line1: ''

        });

        const updateAddress = (newAddress: string) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            customer.address_line1 = newAddress;
        };


        const state = reactive({
            estimationPreview: false,
            selectItems: [] as any,
            estimationId: estimationId,
            selectUnitId: '' as any,
            selectSubItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectSubItem: '' as any,
            approveByAll: false,
            sendData: {} as any,
            mainItem: '' as any,
            projectId: '' as any,
            selectedDate: '' as any,
            fields: {
                description: true,
                markup: true,
                quantity: true,
                unit: true,
                cost: true,
                allowance: true,
                total: true,
                subTotal: true,

            } as any,
            ui: {
                isLoading: {
                    isEdit: false,
                    getItemList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    signaturePadLoad: false,
                    isCompanyEdit: false,
                    isRemoveCustomer: false,
                    checkSignature: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                    checkSignature: false,
                    sendJobEstimation: false,
                    createCompanyIfno: false,
                },
                errors: {
                    errorList: {
                        createUnit: [] as any,
                        sendJobEstimation: [] as any,
                        createCompanyIfno: [] as any,
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    companyEroor: "",
                    hasError: false,
                },
            },
            data: {
                removeUser: {} as any,
                successFulMessage: "",
                showSuccesCustomModal: false,
                selectedCustomers: [] as any,
                allCustomer: [] as any,
                allPhones: [] as any,
                uploadSignature: {} as any,
                haveProfileSign: false,
                useProfileSign: false,
                showSignaturePad: false,
                showSignatureUpload: false,
                uploadSignatureFile: "" as any,
                signatureLink: "" as any,
                modifiedCreatedDate: "" as any,
                cropImage: "",
                note: "",
                newUploadImge: "" as any,
                cropSignature: "" as any,
                lineItems: [] as any,
                unitLists: [] as any,
                company: "" as any,
                signatureFileName: "" as any,
                companyInfo: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_name: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_email: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_phone_number: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_address: "",
                    acknowledgement: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    legal_statement: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    logo: "",
                },
                unit: {
                    name: '',
                    abbreviation: '',
                },
                sendarSignature: "" as any,
                // eslint-disable-next-line @typescript-eslint/camelcase
                signature_link: '' as any,

            },
            mailNotification: true, // Default to true
            smsNotification: false, // Default to false
            mailNotificationError: '',
        });

        function validateEmail() {
         // Regular expression for basic email validation
         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         state.ui.errors.hasError = false;

         if (!state.data.companyInfo.company_email.match(emailPattern)) {
         state.ui.errors.companyEroor = 'Please enter a valid email address';
         state.ui.errors.hasError = true;

         } else {
         state.ui.errors.companyEroor = '';
         }
       }
        function closePreviewModal() {
            state.estimationPreview = false;
            $("#previewModal").modal("hide");
        }
        function previewModal() {
            $("#previewModal").modal("show");

        }
        function estimationPreview() {
            state.estimationPreview = true;
            previewModal()
        }
        function gotoListJobEstimation() {
            router.push({
                name: "list-job-estimation",
                params: { projectId: state.projectId },
            });
        }

        function showSignaturePad() {
            state.data.showSignaturePad = !state.data.showSignaturePad;
        }

        function hideSignaturePad() {
            state.data.showSignaturePad = !state.data.showSignaturePad;
            $("#signaturePadModal").modal("hide");
        }

        function hideSignatureUpload() {
            state.data.showSignatureUpload = false;
            $("#signatureUploadModal").modal("hide");
        }
        function uploadSignature(data: any) {
            state.data.uploadSignature = data;
            state.data.signatureLink = "";
            state.data.cropSignature = data;
            state.data.useProfileSign = false;
        }

        function uploadSignatureFile(event: any) {
            state.data.showSignatureUpload = true;
            state.data.uploadSignatureFile = event.target.files || event.dataTransfer.files;
        }

        function readLogo(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.file = e.target.result;
                data.filename = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                data.isuploded = false;
                data.size = common.formatBytes(parseInt(file.size));
                state.data.companyInfo.logo = data;
            };
            reader.readAsDataURL(file);
        }


        function uploadLogo(event: any) {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // console.log("FILE CHECK >>>", file);
                // if(file.type)
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                readLogo(file);
            }
        }

        function readImage(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.file = e.target.result;
                data.name = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                console.log("CHECK DATA FOR UPLOAD>>", data);
                // state.imageList.push(data);
            };
            reader.readAsDataURL(file);
        }
        const dropFiles = (event: any) => {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                readImage(file);
            }
        };

        function readSignature(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.base64 = e.target.result;
                data.duplicateFile = false; // data.fileName = file.name;
                data.fileName = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                data.isuploded = false;
                data.tag = [] as any;
                data.size = common.formatBytes(parseInt(file.size));
                // state.data.uploadImageData = data;
                state.data.newUploadImge = data.base64;
                state.data.cropImage = data.base64;
                state.data.signatureFileName = data.fileName;
            };
            reader.readAsDataURL(file);
        }

        function onDocumentChange(event: any) {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // console.log("FILE CHECK >>>", file);
                // if(file.type)
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                readSignature(file);
                $("#updateUpload").modal("show");
            }
        }

        function showUploadImageModel() {
            $("#updateUpload").modal("show");
        }

        function hideShowUploadImageModel() {
            $("#profileUpdate").val("");
            $("#updateUpload").modal("hide");
        }


        function saveSignature() {
            hideShowUploadImageModel();
        }

        function clearSignature() {
            state.data.uploadSignature = "";
            state.data.cropSignature = "";

        }

        // function clearCrop() {
        //     if (!cropper) return;
        //     cropper.clear();
        // }
        // /**
        //  * Reset the default cropping area
        //  */
        // function resetCrop() {
        //     if (!cropper) return;
        //     cropper.reset();
        // }

        // function enableSignaturPad() {

        //     state.ui.isLoading.signaturePadLoad = !state.ui.isLoading.signaturePadLoad;
        //     signaturePad = new SignaturePad(canvas.value);
        // }


        function getProjectDetails() {
            ethitransService
                .getEstimationCreateDetails(state.projectId)
                .then((res: any) => {

                    res.data.data.payers.forEach((user: any) => {
                        const data = {
                            email: user.email,
                            code: user.email,
                            mailNotification: true,
                        };
                        state.data.allCustomer.push(data);
                    });
                    // toggleTourGuide();
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.postLoading = false;
                });
        }

        function getJobestimationDetail() {
            state.ui.isLoading.getItemList = true
            ethitransService
                .getJobestimationDetail(state.estimationId)
                .then((res: any) => {
                    state.selectItems = [];
                    state.sendData = res.data.data;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.projectId = res.data.data.project_id;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.selectedDate = common.formatDateAndTime(res.data.data.created_date);
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.modifiedCreatedDate = common.formatDateAndTime(res.data.data.created_date);
                    state.data.note = res.data.data.note;
                    // state.data.createDate = moment(res.data.data.created_date).format("Y-MM-DD");
                    res.data.data.items.forEach((subItem: any) => {
                        subItem.subTotal = parseFloat(subItem.cost) * parseFloat(subItem.quantity);
                        subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                        if (subItem.markup_type == 1) {
                            const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
                            subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                            subItem.markup = common.takeLimitDigits(subItem.markup);
                        } else {
                            subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
                            subItem.markup = common.usdPriceFormate(subItem.markup);
                        }
                        subItem.total = common.usdPriceFormate(subItem.total);
                        subItem.cost = common.usdPriceFormate(subItem.cost);
                        subItem.subTotal = common.usdPriceFormate(subItem.subTotal);
                        subItem.allowance = subItem.allowance ? common.usdPriceFormate(subItem.allowance) : '-';
                    });

                    state.selectItems = res.data.data.items;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    getProjectDetails();
                    state.ui.isLoading.getItemList = false;
                });
        }

        function fetchCustomerDetails(email: string) {
            ethitransService.detailsByemail(email)
                .then(response => {
                    const selectedCustomers: any = response.data;
                    if (selectedCustomers && selectedCustomers) {
                        const data = selectedCustomers.data;
                        if (data.email) {
                            const customerData = {
                                email: data.email,
                                code: data.email,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                first_name: data.first_name || '',
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                last_name: data.last_name || '',
                                phone: data.mobile_number || '',
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                address_line1: data.address_line1 || '',
                                mailNotification: true,
                                smsNotification: false,
                            };

                            const existingCustomerIndex = state.data.selectedCustomers.findIndex((customer: any) => ((customer.email === data.email) || (customer.phone === data.mobile_number)));
                            if (existingCustomerIndex !== -1) {
                                // Replace the entire object
                                state.data.selectedCustomers[existingCustomerIndex] = { ...customerData };
                            } else {
                                state.data.selectedCustomers.push(customerData);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching customer details:', error);
                });
        }



        function addCustomerEmail(data: any) {
            isInvalid.value = false;
            const email = data;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email == localStorage.getItem("current_user_email")) {
                isInvalid.value = true;
                return false;
            }
            if (!emailRegex.test(email)) {
                isInvalid.value = true;
                return false;
            }
            const existingCustomerIndex = state.data.selectedCustomers.findIndex((customer: any) => customer.email === email);
            if (existingCustomerIndex === -1) {
                // Add new customer to both allCustomer and selectedCustomers
                const addData = {
                    email: email,
                    code: email,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    first_name: '',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    last_name: '',
                    phone: '',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    address_line1: '',
                    mailNotification: true,
                    smsNotification: false,
                };

                state.data.allCustomer.push(addData);
                state.data.selectedCustomers.push(addData);

                // Fetch additional details for the new customer
                fetchCustomerDetails(email);
            } else {
                // If email already exists, update the existing customer details
                fetchCustomerDetails(email);
            }

        }

        // function changeValue() {
        //     isInvalid.value = false;
        // }
        function changeValue(selectedCustomer: any) {
            isInvalid.value = false;
            if (selectedCustomer && selectedCustomer.email) {
                fetchCustomerDetails(selectedCustomer.email);
            }
        }

        function addCustomerPhone(data: any) {
            isPhoneInvalid.value = false;
            const phone = data;
            if (phone.length != 10) {
                isPhoneInvalid.value = true;
                return false;
            }
            let userPhone = "";
            const currentUser = localStorage.getItem('current_user_info');
            userPhone = (currentUser != undefined) ? JSON.parse(currentUser).mobile_number : '';
            if (phone == userPhone) {
                isPhoneInvalid.value = true;
                return false;
            }
            const existingCustomerIndex = state.data.selectedCustomers.findIndex((customer: any) => customer.phone === phone);
            if (existingCustomerIndex === -1) {
                // Add new customer to both allCustomer and selectedCustomers
                const addData = {
                    email: "",
                    code: phone,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    first_name: '',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    last_name: '',
                    phone: phone,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    address_line1: '',
                    mailNotification: true,
                    smsNotification: false,
                };

                state.data.allPhones.push(addData);
                state.data.selectedCustomers.push(addData);

                // Fetch additional details for the new customer
                fetchCustomerDetails(phone);
            } else {
                // If email already exists, update the existing customer details
                fetchCustomerDetails(phone);
            }

        }

        function changePhoneValue(selectedCustomer: any) {
            isInvalid.value = false;
            if (selectedCustomer && selectedCustomer.email) {
                fetchCustomerDetails(selectedCustomer.email);
            }
        }


        function closeSuccessCustomModal() {
            $("#SuccessfulModalCustom").modal("hide");
            gotoListJobEstimation();
        }


        function validateSendInvitation() {
            state.ui.errors.errorList.sendJobEstimation = [];
            state.ui.errors.hasError = false;

            if (state.data.selectedCustomers.length == 0) {
                state.ui.errors.errorList.sendJobEstimation.push("Enter one custumer");
            }
            if (!state.data.uploadSignature && !state.data.signatureLink) {
                state.ui.errors.errorList.sendJobEstimation.push("Draw/Upload/ Use your profile Signature");
                state.ui.isPostLoading.checkSignature = true;
            }

            if (
                !state.ui.errors.errorList.sendJobEstimation &&
                !state.ui.errors.errorList.sendJobEstimation.length
            ) {
                state.ui.errors.hasError = false;
            } else if (state.ui.errors.errorList.sendJobEstimation.length != 0) {
                state.ui.errors.hasError = true;
            }
        }

        function sendJobEstimation() {
            state.ui.isPostLoading.sendJobEstimation = false;
            state.ui.errors.hasError = false;
            state.ui.isPostLoading.checkSignature = false;
            validateSendInvitation();
            if (state.ui.isPostLoading.sendJobEstimation || state.ui.errors.hasError) return false;
            const selectedCustomer = state.data.selectedCustomers.map((customer: any) => ({
                ...customer,
                mailNotification: customer.mailNotification ?? true,  // Default to true if undefined
                smsNotification: customer.smsNotification ?? false,   // Default to false if undefined
            }));
            // const selectedCustomer = [] as any;
            // state.data.selectedCustomers.forEach((customer: any) => {
            //     selectedCustomer.push(customer);
            // });
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                estimation_id: estimationId,
                fields: state.fields,
                // eslint-disable-next-line @typescript-eslint/camelcase
                approve_by_all: state.approveByAll,
                customers: selectedCustomer,
                signature: state.data.uploadSignature,
                // eslint-disable-next-line @typescript-eslint/camelcase
                signature_link: state.data.signatureLink,
                note: state.data.note,
                acknowledgement: state.data.companyInfo.acknowledgement,
                // eslint-disable-next-line @typescript-eslint/camelcase
                legal_statement: state.data.companyInfo.legal_statement,
                logo: state.data.companyInfo.logo
            };
            state.ui.isPostLoading.sendJobEstimation = true;
            ethitransService
                .sendJobEstimation(payLoad)
                .then((res: any) => {
                    // console.log(res);
                    state.data.successFulMessage = t('send_job_estimation_successfully');
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.sendJobEstimation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.sendJobEstimation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.sendJobEstimation = false;

                });
        }

        const totalCost = computed(() => {
            const total = state.selectItems.reduce((total: any, item: any) => total + parseFloat(item.total.replace(/[$,]/g, '').trim()), 0);
            return common.usdPriceFormate(total);
        });

        function showCreateCompanyModel() {
            $("#addCompanyInfo").modal("show");
        }

        function hideCreateCompanyModel() {
            state.ui.errors.errorList.createCompanyIfno = [];
            $("#addCompanyInfo").modal("hide");
            state.ui.isLoading.isCompanyEdit = false;
        }
        function editCompany() {
            state.ui.isLoading.isCompanyEdit = !state.ui.isLoading.isCompanyEdit;
            $("#addCompanyInfo").modal("show");
        }


        function showDelete(customer: any) {
            // Find index of the customer in both arrays
            const indexInAllCustomer = state.data.allCustomer.findIndex((item: any) => item.email === customer.email);
            const indexInSelectedCustomers = state.data.selectedCustomers.findIndex((item: any) => item.email === customer.email);

            // Remove from allCustomer array if exists
            if (indexInAllCustomer !== -1) {
                state.data.allCustomer.splice(indexInAllCustomer, 1);
            }

            // Remove from selectedCustomers array if exists
            if (indexInSelectedCustomers !== -1) {
                state.data.selectedCustomers.splice(indexInSelectedCustomers, 1);
            }
            const indexInPhoneCustomer = state.data.allPhones.findIndex((item: any) => item.phone === customer.phone);
            // remove from allphone array if exists
            if (indexInPhoneCustomer !== -1) {
                state.data.allPhones.splice(indexInPhoneCustomer, 1);
            }
        }


        function hideDelete() {
            $("#deleteModal").modal("hide");
        }
        function removeCustomer() {
            state.ui.isLoading.isRemoveCustomer = true;
            if (state.data.removeUser != null) {
                const customers = state.data.allCustomer.filter(function (user: any) {
                    return user.email == state.data.removeUser.email;
                })
            }
            state.data.allCustomer.length = 0;
            state.ui.isLoading.isRemoveCustomer = false;
            hideDelete();

        }
        function isEmailValid(item: any) {
            const email = item.email;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email == localStorage.getItem("current_user_email")) {
                item.emailError = "Owner email  not valid here.";
                return false;
            }
            if (!emailRegex.test(email)) {
                item.emailError = "Please enter valid email.";
                return false;
            }
            item.emailError = "";
            return true;
        }

        function isPhoneValid(item: any) {
            const phone = item.phone;
            const phoneRegex = /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
            if (!phoneRegex.test(phone)) {
                item.phoneError = "Please enter valid phone.";
                return false;
            }
            item.phoneError = "";
            return true;
        }
        function editSelectCustomer(index: number) {
            state.data.selectedCustomers.forEach((customer: any) => {
                customer.isEdit = false;
            });
            state.data.selectedCustomers[index].isEdit = true;
        }
        function updateSelectCustomer(data: any) {

            const item = state.data.selectedCustomers[data];
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.address_line1 = customer.address_line1 ? customer.address_line1 : item.address_line1;
            item.emailError = "";
            item.phoneError = "";
            if (item.phone) {
                if (isEmailValid(item)) {
                    item.emailError = "";
                    item.phoneError = "";
                }
                if (isPhoneValid(item)) {
                    item.emailError = "";
                    item.phoneError = "";
                    item.isEdit = false;
                }
            } else {
                if (isEmailValid(item)) {
                    item.emailError = "";
                    item.phoneError = "";
                    item.isEdit = false;
                }
            }
            if (item.smsNotification) {
                if (item.phone == undefined) {
                    item.phoneError = "Enter phone number.";
                    item.isEdit = true;
                } else if (item.phone.length != 10) {
                    item.phoneError = "Phone number must be of 10 digit.";
                    item.isEdit = true;
                }

            }

        }
        function removeSelectCustomer(data: any) {
            state.data.selectedCustomers = state.data.selectedCustomers.filter(
                (e: any) => e != data
            );
            hideDelete();
        }

        function validateCreateCompany() {
            // console.log("*** TODO VALIDATION  ***");
            state.ui.errors.hasError = false;
            state.ui.errors.errorList.createCompanyIfno = [];
            if (
                !state.data.companyInfo.company_name &&
                !state.ui.isLoading.isCompanyEdit
            ) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company name is required."
                );
            }
            if (
                !state.data.companyInfo.company_email &&
                !state.ui.isLoading.isCompanyEdit
            ) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company Email is required."
                );
            } else if (
                state.data.companyInfo.company_email &&
                !state.data.companyInfo.company_email.includes("@")
            ) {
                state.ui.errors.errorList.createCompanyIfno.push(
                "Company Email must be a valid email address."
                );
            }
            
            if (!state.data.companyInfo.logo) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company logo is required."
                );
            }
            if (!state.data.companyInfo.acknowledgement) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company acknowledgement is required."
                );
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (!state.data.companyInfo.legal_statement) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company legal statement is required."
                );
            }
            if (
                !state.ui.errors.errorList.createCompanyIfno &&
                !state.ui.errors.errorList.createCompanyIfno.length
            ) {
                state.ui.errors.hasError = false;
            } else if (state.ui.errors.errorList.createCompanyIfno.length != 0) {
                state.ui.errors.hasError = true;
            }
        }
        //no profile set
        function createCompanyIfno() {
            state.ui.errors.errorList.createCompanyIfno = [];
            state.ui.errors.hasError = false;

            validateCreateCompany();

            if (state.ui.isPostLoading.createCompanyIfno) return false;
            if (
                state.ui.errors.hasError ||
                state.ui.errors.errorList.createCompanyIfno.length != 0
            )
                return false;

            if (state.ui.isLoading.isCompanyEdit) {
                hideCreateCompanyModel();
            }
            const payLoad: any = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                company_name: state.data.companyInfo.company_name,
                // eslint-disable-next-line @typescript-eslint/camelcase
                logo: state.data.companyInfo.logo,
                // eslint-disable-next-line @typescript-eslint/camelcase
                company_phone_number: state.data.companyInfo.company_phone_number,
                // eslint-disable-next-line @typescript-eslint/camelcase
                company_email: state.data.companyInfo.company_email,
                // eslint-disable-next-line @typescript-eslint/camelcase
                acknowledgement: state.data.companyInfo.acknowledgement,
                // eslint-disable-next-line @typescript-eslint/camelcase
                legal_statement: state.data.companyInfo.legal_statement,
            };
            state.ui.isPostLoading.createCompanyIfno = true;
            ethitransService
                .updateCompanyInfo(payLoad)
                .then((res: any) => {
                    hideCreateCompanyModel();
                })
                .catch((error: any) => {
                    console.log(error);
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createCompanyIfno)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createCompanyIfno.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.createCompanyIfno = false;
                });
        }

        function userStatus() {

            const payLoad = {
                email: localStorage.getItem("current_user_email"),
            };
            ethitransService
                .userStatus(payLoad)
                .then((res: any) => {
                    state.data.companyInfo.logo = res.data.data.company.logo;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_name = res.data.data.company.company_name;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_phone_number = res.data.data.company.company_phone_number;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_email = res.data.data.company.company_email;
                    state.data.companyInfo.acknowledgement = res.data.data.company.acknowledgement;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.legal_statement = res.data.data.company.legal_statement;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_address = res.data.data.company.company_address;
                    // console.log("User Detail Check:>>> ", res.data.data);
                })
                .catch((error: any) => {
                    //   common
                    //     .sdCatchErr(error, state.ui.error.errorsList)
                    //     .then((res: any) => {
                    //       state.ui.error.hasError = true;
                    //       // console.log("CHECK for Error >>>", res)
                    //       for (const [key, value] of Object.entries(res)) {
                    //         if (Array.isArray(value)) {
                    //           state.ui.error.errorsList.push(value[0]);
                    //         }
                    //       }
                    //       // if (res) {
                    //       //   if (res.error) {
                    //       //     console.log("CHECK for Error >>>", res.error[0])
                    //       //     // state.errorList.push(res.email[0]);
                    //       //     // state.errorList = [...state.errorList]
                    //       //   }
                    //       // }
                    //     });
                })
                .finally(() => {
                    //   state.ui.isLoading.userStatus = true;
                });
        }

        function userSignature(from: any) {
            ethitransService
                .userSignature()
                .then((res: any) => {
                    if (from == 'onMount' && res.data.data.signature) {
                        state.data.haveProfileSign = true;
                    }
                    if (from == 'use' && res.data.data.signature) {
                        state.data.useProfileSign = true;
                        state.data.uploadSignature = "";
                        state.data.cropSignature = res.data.data.signature;
                        state.data.signatureLink = res.data.data.signature;
                    }
                })
                .catch((error: any) => {
                    //   common
                })
                .finally(() => {
                    //   state.ui.isLoading.userStatus = true;
                });
        }

        function startRecording(newValue: any) {
            state.data.note = state.data.note ? state.data.note : '' + ' ' + newValue;
        }

        onMounted(() => {
            userStatus();
            getJobestimationDetail();
            userSignature('onMount');
            clearSignature();
            // signaturePad = new SignaturePad(canvas.value);

        });
        return {
            state,
            closePreviewModal,
            previewModal,
            estimationPreview,
            getJobestimationDetail,
            changeValue,
            isInvalid,
            addCustomerEmail,
            getProjectDetails,
            canvas,
            clearSignature,
            readImage,
            readSignature,
            onDocumentChange,
            showUploadImageModel,
            hideShowUploadImageModel,
            saveSignature,
            // enableSignaturPad,
            closeSuccessCustomModal,
            sendJobEstimation,
            validateSendInvitation,
            gotoListJobEstimation,
            totalCost,
            hideCreateCompanyModel,
            showCreateCompanyModel,
            createCompanyIfno,
            validateCreateCompany,
            editCompany,
            uploadLogo,
            readLogo,
            userStatus,
            showDelete,
            hideDelete,
            isEmailValid,
            isPhoneValid,
            removeSelectCustomer,
            editSelectCustomer,
            updateSelectCustomer,
            showSignaturePad,
            hideSignaturePad,
            uploadSignature,
            uploadSignatureFile,
            hideSignatureUpload,
            userSignature,
            startRecording,
            removeCustomer,
            fetchCustomerDetails,
            address,
            latitude,
            longitude,
            showMap,
            updateLatitude,
            updateLongitude,
            updateAddress,
            customer,
            changePhoneValue,
            addCustomerPhone,
            validateEmail

        };
    },
});
