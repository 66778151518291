
import {
  defineComponent,
  reactive,
  onMounted,
  computed,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";
import ToDoPhaseAddMemberTask from "@/components/todo/ToDoPhaseAddMemberTask.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import CheckAvaibilityByUser from "@/components/todo/CheckAvaibilityByUser.vue";
import AddMemberToTaskEditPhase from "@/components/todo/AddMemberToTaskEditPhase.vue";
import AddMembeToTask from "@/components/todo/AddMembeToTask.vue";
import eventBus from "@/eventBus";
// import QCalender from "@/components/projectDetail/QCalender.vue";
// import ProCalender from "@/components/projectDetail/ProCalender.vue";
// import CustomEventCalender from "@/components/projectDetail/CustomEventCalender.vue";
import moment from "moment";

// import { Data } from "@/components/todo/AddMember.ts";

import ToDoPhaseAddMemberIndividual from "@/components/todo/ToDoPhaseAddMemberIndividual.vue";

export default defineComponent({
  name: "ProjectWorkflowEditTask",
  props:{
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    ToDoPhaseAddMemberIndividual,
    ErrorList,
    SuccessfulModalCustom,
    CheckAvaibilityByUser,
    AppDeleteConfirmationModal,
    // EmptyData,
  },
  setup(props, {emit}) {
    const router = useRouter();
    // const {
    //   params: { taskId },
    // } = useRoute();
    const phaseDetails = ref({
      phase: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        child_phases: [] as any,
      } as any,
      roles: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      amount_types: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phase_members: [] as any,
      members: [] as any,
    });
    const state = reactive({
      data: {
        activeUserExistingSlots: [] as any,
        activeTimeSlot: {} as any,
        editTask: {} as any,
        phaseDetail: {} as any,
        initialStartDate: "" as any,
        initialEndDate: "" as any,
        updateStartDate: "" as any,
        updateEndDate: "" as any,
        reason: "" as any,
        selectedMemberIndex: 0,
        activeTab: "edit",
        title: "Members Accepted",
        heading: "Invitation Send and waiting for Acknowledgement",
        minDate: "",
        maxDate: "",
        filterBySearch: "",
        phaseDetailsStatus: true,
        agreeToInvitation: false,
        showAddMemberModal: false,
        showSuccesCustomModal: false,
        showAvailibilityModal: false,
        selectedEmail: "",
        editAllData: {} as any,
        masterList: {
          permissions: [] as any,
          amountType: [] as any,
        } as any,
        selectedUser: {},
        successFulMessage:
          "Update Successfully",
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        deleteTimeSlotModalMessage:
          "Selected Time Slot will be Delete. Please Confirm.",
        selectedMemberForRole: {},
        selectedToRemove: {},
        selectedRole: {},
        selectedTask: {},
        showDeleteConfirmationModal: false,
        showTaskUpdateModal: false,
        invite: {
          newUsers: "",
          notificationUsers: "",
          individualNotificationUsers: [] as any,
          existingMembers: [] as any,
          existingMemberSelected: [] as any,
          invitedMembers: [] as any,
          selectedMembers: [
            {
              role: {},
              id: "",
              name: "",
              amountType: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                created_at: "",
                // eslint-disable-next-line @typescript-eslint/camelcase
                deleted_at: "",
                id: "",
                name: "",
                type: 0,
                // eslint-disable-next-line @typescript-eslint/camelcase
                updated_at: "",
                // eslint-disable-next-line @typescript-eslint/camelcase
                user_id: 0,
              },
            },
          ] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getPhaseDetails: [] as any,
            phaseDetails: [] as any,
            updateTasks: [] as any,
          },
        },
        isLoading: {
          phaseDetails: false,
          changeDetailsByEmail: false,
          getPhaseDetails: false,
        },
        isPostLoading: {
          createPhase: false,
          addMembersInPhases: false,
        },
      },
    });
    const activeUpdate = computed(() => {
      return state.ui.isPostLoading.addMembersInPhases;
    });
    const filteredChildPhases = computed(() => {
      return phaseDetails.value.phase.child_phases.filter(
        (childPhase: any) => childPhase.isChecked
      );
    });

    const changedDetectedInDate = computed(() => {
      return (
        state.data.updateStartDate !== state.data.initialStartDate ||
        state.data.updateEndDate !== state.data.initialEndDate
      );
    });
    function deleteNotificationMember(email: any) {
      state.data.invite.individualNotificationUsers =
        state.data.invite.individualNotificationUsers.filter(
          (e: any) => e != email
        );
    }
    function changeNotificationMember() {
      // Check if the selected notificationUsers is a valid email
      const user = validationService.isValidEmail(
        state.data.invite.notificationUsers
      )
        ? state.data.invite.notificationUsers
        : null;

      if (!user) {
        // Handle the case where the email is not valid or not provided
        console.error("Invalid or missing email");
        return;
      }

      if (!state.data.invite.individualNotificationUsers) {
        // If individualNotificationUsers doesn't exist, create it as an array
        state.data.invite.individualNotificationUsers = [];
      }

      // Check if the email already exists in individualNotificationUsers
      if (!state.data.invite.individualNotificationUsers.includes(user)) {
        // Add the user to individualNotificationUsers only if it's not already present
        state.data.invite.individualNotificationUsers.unshift(user);

        console.log(
          "CHECK THE LIST >>> ",
          state.data.invite.individualNotificationUsers
        );
      } else {
        console.log("Email already exists in the list");
      }
      state.data.invite.notificationUsers = "";
    }

    function filteredTimeSlotRefToStartAndEndDate() {
      const startDateTime = new Date(state.data.updateStartDate).getTime();
      const endDateTime = new Date(state.data.updateEndDate).getTime();

      return state.data.invite.selectedMembers.map((member: any) => {
        member.filteredSlots = member.filteredSlots.filter((event: any) => {
          const eventStartTime = new Date(event.start_time).getTime();
          const eventEndTime = new Date(event.end_time).getTime();

          // Check if the event falls within the specified date range
          return eventStartTime >= startDateTime && eventEndTime <= endDateTime;
        });

        return member;
      });
    }
    watch(
      () => changedDetectedInDate.value,
      (newvalue: any, oldvalue: any) => {
        if (newvalue === true) {
          filteredTimeSlotRefToStartAndEndDate();
        }
      }
    );
    function activeTimeSlotForDelete(activeTimeSlot: any) {
      state.data.activeTimeSlot = activeTimeSlot;
      state.data.showDeleteConfirmationModal = true;
    }
    function removeSelectedTimeSlot() {
      console.log("CHeck the active Slot >>> ", state.data.activeTimeSlot);
      console.log(
        "CHeck Active Member >>> ",
        state.data.invite.selectedMembers[state.data.selectedMemberIndex]
      );
      // console.log("CHeck Selected Member >>> ", state.data.selectedToRemove);

      if (
        Array.isArray(
          state.data.invite.selectedMembers[state.data.selectedMemberIndex]
            .filteredSlots
        )
      ) {
        // Find the index of selectedSlot within selectedObject's filteredSlots array
        const indexToRemove = state.data.invite.selectedMembers[
          state.data.selectedMemberIndex
        ].filteredSlots.findIndex(
          (slot: any) => slot.id === state.data.activeTimeSlot.id
        );

        // Remove selectedSlot from selectedObject's filteredSlots array
        if (indexToRemove !== -1) {
          state.data.invite.selectedMembers[
            state.data.selectedMemberIndex
          ].filteredSlots.splice(indexToRemove, 1);
        }
      }
    }

    const filteredPhases = computed(() => {
      const searchTerm = state.data.filterBySearch.toLowerCase();
      phaseDetails.value.phase.child_phases.forEach((child: any) => {
        child.isCheckedDisabled = false;
        if (child.start_date === null || child.end_date === null) {
          child.isCheckedDisabled = true;
        }
      });

      return phaseDetails.value.phase.child_phases.filter((item: any) =>
        item.name.toLowerCase().startsWith(searchTerm)
      );

      // return phaseDetails.value.phase.child_phases.filter((item: any) =>
      //           item.name.toLowerCase().includes(searchTerm)
      // );
    });
    const filteredEvents = computed(() => {
      const dates: any = [
        {
          start: "2018-11-21 09:00",
          end: "2018-11-21 17:00",
          title: `ABC`,
          // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          // class: 'health'
        },
        {
          start: "2018-11-22 09:00",
          end: "2018-11-21 17:00",
          title: `DEF`,
          // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          // class: 'health'
        },
      ];
      // phaseDetails.value.phase.child_phases.forEach((child: any)=> {
      //   if(child.edit_end_date === null || child.edit_start_date === null){
      //     dates.push({
      //     start: '2018-11-21 09:00',
      //     end: '2018-11-21 17:00',
      //     title: `${child.name}`,
      //     // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
      //     // class: 'health'
      //   })

      //   }
      // })
      return dates;

      // return phaseDetails.value.phase.child_phases.filter((item: any) =>
      //           item.name.toLowerCase().includes(searchTerm)
      // );
    });
    function clickEvent(data: any) {
      console.log("PRABHU >>> ", data);
    }
    // function createdEvent(event: any) {
    //   console.log("CHECK Phase CREATED >>> ", event);
    //   event.formatedStart = common.formatDateAndTime(event.start);
    //   event.formatedEnd = common.formatDateAndTime(event.end);
    //   // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
    //   // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
    //   if (
    //     state.data.invite.selectedMembers[state.data.selectedMemberIndex]
    //       .filteredSlots
    //   ) {
    //     state.data.invite.selectedMembers[
    //       state.data.selectedMemberIndex
    //     ].filteredSlots.forEach((slot: any) => {
    //       state.data.invite.selectedMembers[
    //         state.data.selectedMemberIndex
    //       ].slots.push(slot);
    //     });
    //   }

    //   // latest update code
    //   state.data.invite.selectedMembers[
    //     state.data.selectedMemberIndex
    //   ].slots = event;

    //   // old update code
    //   // state.data.invite.selectedMembers[
    //   //   state.data.selectedMemberIndex
    //   // ].slots.push(event);

    //   // Create an object to store the latest end_time for each unique date and time
    //   const latestEndTimes: any = {};

    //   state.data.invite.selectedMembers[
    //     state.data.selectedMemberIndex
    //   ].slots.forEach((slot: any) => {
    //     const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    //     const time = slot.formatedStart.split(" ")[1]; // Extract the time part
    //     const formatedEnd = new Date(slot.formatedEnd);

    //     const dateTimeKey = `${date} ${time}`;

    //     if (
    //       !latestEndTimes[dateTimeKey] ||
    //       formatedEnd > latestEndTimes[dateTimeKey]
    //     ) {
    //       latestEndTimes[dateTimeKey] = formatedEnd;
    //     }
    //   });

    //   // Create a new array with the updated time slots
    //   const updatedTimeSlots = Object.values(latestEndTimes).map(
    //     (latestEndTime: any) => {
    //       return state.data.invite.selectedMembers[
    //         state.data.selectedMemberIndex
    //       ].slots.find(
    //         (slot: any) =>
    //           new Date(slot.formatedEnd).getTime() === latestEndTime.getTime()
    //       );
    //     }
    //   );

    //   // Filter the old slots to keep only the ones that are in updatedTimeSlots
    //   state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
    //     state.data.invite.selectedMembers[
    //       state.data.selectedMemberIndex
    //     ].slots.filter((slot: any) => {
    //       const date = slot.formatedStart.split(" ")[0]; // Extract the date part
    //       const time = slot.formatedStart.split(" ")[1]; // Extract the time part
    //       const dateTimeKey = `${date} ${time}`;
    //       return (
    //         updatedTimeSlots.includes(slot) ||
    //         latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd)
    //       );
    //     });

    //   // eslint-disable-next-line @typescript-eslint/camelcase
    //   state.data.invite.selectedMembers[
    //     state.data.selectedMemberIndex
    //   ].filteredSlots = updatedTimeSlots;
    // }
    function filterByNew(array: any) {
      return array.filter((item: any) => item.new === true || item.new === false);
    }
    function createdEvent(event: any) {
      console.log("TEsting the SLOTS >>> ", event);
      // event.forEach((item: any)=> {

      // })
      // event.formatedStart = common.formatDateAndTimeSlot(event.start);
      // event.formatedEnd = common.formatDateAndTimeSlot(event.end);
      // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
      // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
      // _eid
      // const array2 =
      //   state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots;

      // // Synchronize array2 with array1
      // event.forEach((obj1: any) => {
      //   const obj2 = array2.find((obj: any) => obj._eid === obj1._eid);
      //   if (obj2) {
      //     // Object with the same id exists in array2, update its name and date
      //     obj2.title = obj1.title;
      //     obj2.startTimeMinutes = obj1.startTimeMinutes;
      //     obj2.start = obj1.start;
      //     obj2.formatedStart = obj1.formatedStart;
      //     obj2.formatedEnd = obj1.formatedEnd;
      //     obj2.endTimeMinutes = obj1.endTimeMinutes;
      //     obj2.end = obj1.end;
      //     obj2.daysCount = obj1.daysCount;
      //     obj2.content = obj1.content;
      //     obj2.class = obj1.class;
      //     obj2.background = obj1.background;
      //     obj2.allDay = obj1.allDay;
      //   } else {
      //     // Object with the same id does not exist in array2, add it
      //     array2.push({
      //       _eid: obj1._eid,
      //       title: obj1.title,
      //       startTimeMinutes: obj1.startTimeMinutes,
      //       start: obj1.start,
      //       formatedStart: obj1.formatedStart,
      //       formatedEnd: obj1.formatedEnd,
      //       endTimeMinutes: obj1.endTimeMinutes,
      //       end: obj1.end,
      //       daysCount: obj1.daysCount,
      //       content: obj1.content,
      //       class: obj1.class,
      //       background: obj1.background,
      //       allDay: obj1.allDay,
      //     });
      //   }
      // });

      // console.log("SYNCKED >>>>>", array2);

      // // Synchronize targetArray with sourceArray
      // state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
      //   array2;
      // // state.data.invite.selectedMembers[
      // //   state.data.selectedMemberIndex
      // // ].slots = event.slice();

      // // latest update code
      // // state.data.invite.selectedMembers[
      // //   state.data.selectedMemberIndex
      // // ].slots = event;

      // // old code
      // // state.data.invite.selectedMembers[
      // //   state.data.selectedMemberIndex
      // // ].slots.push(event);

      // // Create an object to store the latest end_time for each unique date and time
      // const latestEndTimes: any = {};

      // state.data.invite.selectedMembers[
      //   state.data.selectedMemberIndex
      // ].slots.forEach((slot: any) => {
      //   const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //   const time = slot.formatedStart.split(" ")[1]; // Extract the time part
      //   const formatedEnd = new Date(slot.formatedEnd);

      //   const dateTimeKey = `${date} ${time}`;

      //   if (
      //     !latestEndTimes[dateTimeKey] ||
      //     formatedEnd > latestEndTimes[dateTimeKey]
      //   ) {
      //     latestEndTimes[dateTimeKey] = formatedEnd;
      //   }
      // });

      // // Create a new array with the updated time slots
      // const updatedTimeSlots = Object.values(latestEndTimes).map(
      //   (latestEndTime: any) => {
      //     return state.data.invite.selectedMembers[
      //       state.data.selectedMemberIndex
      //     ].slots.find(
      //       (slot: any) =>
      //         new Date(slot.formatedEnd).getTime() === latestEndTime.getTime()
      //     );
      //   }
      // );

      // // Filter the old slots to keep only the ones that are in updatedTimeSlots
      // state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
      //   state.data.invite.selectedMembers[
      //     state.data.selectedMemberIndex
      //   ].slots.filter((slot: any) => {
      //     const date = slot.formatedStart.split(" ")[0]; // Extract the date part
      //     const time = slot.formatedStart.split(" ")[1]; // Extract the time part
      //     const dateTimeKey = `${date} ${time}`;
      //     return (
      //       updatedTimeSlots.includes(slot) ||
      //       latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd)
      //     );
      //   });

      if (event && event.length) {
        event.forEach((item: any) => {
          if (item.start && item.end) {

            item.formatedStart = common.formatDateAndTimeSlot(item.start);
            item.formatedEnd = common.formatDateAndTimeSlot(item.end);
          }

          if (item.start_time && item.end_time) {

            item.formatedStart = common.formatDateAndTimeSlot(item.start_time);
            item.formatedEnd = common.formatDateAndTimeSlot(item.end_time);
          }
        });
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invite.selectedMembers[
        state.data.selectedMemberIndex
      ].filteredSlots = filterByNew(event);
    }
    function deletedEvent(event: any) {
      console.log("CHECK Phase DELETED >>> ", event);
      console.log(
        "DATE TIME FORMAT START >> ",
        common.formatDateAndTime(event.start)
      );
      console.log(
        "DATE TIME FORMAT END >> ",
        common.formatDateAndTime(event.end)
      );
      event.formatedStart = common.formatDateAndTime(event.start);
      event.formatedEnd = common.formatDateAndTime(event.end);
      state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
        state.data.invite.selectedMembers[
          state.data.selectedMemberIndex
        ].slots.filter(
          (e: any) =>
            e.title !== event.title &&
            e.start !== event.start &&
            e.end !== event.end
        );
    }
    function selectedTab(type: string) {
      state.data.activeTab = type;
    }
    // Define a function to find the index of an object by its 'id' property
    function findIndexById(
      array: { id: number; name: string }[],
      targetId: number
    ): number {
      return array.findIndex((obj) => obj.id === targetId);
    }
    function checkWithRow(item: any) {
      console.log(
        "ITEM INDEX >>",
        state.data.invite.selectedMembers.findIndex(
          (member: any) => member === item
        )
      );
      state.data.invite.selectedMembers.forEach((member: any) => {
        // console.log("Member ITEM >>", member.role.permissions)
        if (member.email === item.row.email) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.id === item.check.id) {
              console.log(" IN CHECK LIST>>>", permission);
              permission.isChecked = true;
            }
          });
        }
      });
    }

    function addMemberToTaskSuccess() {
      state.data.showSuccesCustomModal = true;
      state.data.successFulMessage = "Members in Task Added Successfully.";
    }
    function setUpdatedTask(udpatedTask: any) {
      console.log("UPDATE Check >>> ", udpatedTask);
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === udpatedTask.uuid) {
          // Replace the object with the updated object
          item.budget = udpatedTask.budget;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_budget = udpatedTask.budget;
          if (typeof udpatedTask.priority_id === "string") {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.priority_id = parseInt(udpatedTask.priority_id);
            console.log("<< TYPE CHECK >>", item.priority_id);
          } else {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.priority_id = udpatedTask.priority_id;
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.priority_id = udpatedTask.priority_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.end_date = common.localDateOnly(udpatedTask.end_date);
        }
      });
      state.data.showSuccesCustomModal = true;
      state.data.successFulMessage = "Task Updated";

      // filteredPhases.value.forEach((item: any)=> {
      //   if (item.uuid === udpatedTask.uuid) {
      //       // Replace the object with the updated object
      //       item.budget = udpatedTask.budget;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.priority_id = udpatedTask.priority_id;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_start_date = common.localDateOnly(udpatedTask.start_date );
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
      //     }
      // })
    }
    function setMemberUpdatedTask(udpatedTask: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === udpatedTask.uuid) {
          item.allMembers = udpatedTask.allMembers;
          // Replace the object with the updated object
          item.budget = udpatedTask.budget;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.priority_id = udpatedTask.priority_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
        }
      });

      // filteredPhases.value.forEach((item: any)=> {
      //   if (item.uuid === udpatedTask.uuid) {
      //       // Replace the object with the updated object
      //       item.budget = udpatedTask.budget;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.priority_id = udpatedTask.priority_id;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_start_date = common.localDateOnly(udpatedTask.start_date );
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
      //     }
      // })
    }
    function addMemberToTaskData(tasks: any) {
      console.log("Check Tasks>>> ", tasks);
      tasks.forEach((task: any, index: any) => {
        setMemberUpdatedTask(task);
        if (tasks.length === index + 1) {
          state.data.showSuccesCustomModal = true;
          state.data.successFulMessage = "Members Added To Selected Task";
        }
      });
    }
    function hideUpdateTaskModal() {
      state.data.showTaskUpdateModal = !state.data.showTaskUpdateModal;
    }
    function showTaskUpdateModal() {
      console.log("IN PARENT >> ");
      state.data.showTaskUpdateModal = true;
    }
    function hideAddMemberToTaskModal() {
      state.data.showAddMemberModal = !state.data.showAddMemberModal;
    }
    function showAddMemberToTaskModal() {
      state.data.showAddMemberModal = true;
    }

    function selectedTask(selected: any) {
      console.log("IN PARENT >> ");
      state.data.selectedTask = selected;
    }
    function selectedChecked(selected: any) {
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === selected.uuid) {
          item.isChecked = true;
          state.data.selectedTask = item;
        } else {
          item.isChecked = false;
        }
      });
    }
    function hideAvaibilityModal() {
      state.data.showAvailibilityModal = false;
    }
    function selectedMemberForCheckAvaibility(member: any) {
      state.data.activeUserExistingSlots = member;
      console.log("CHECK The Active Slots >>> ", state.data.activeUserExistingSlots)
      // alert("CHECK THE ALERT")
      state.data.selectedEmail = member.email;
      state.data.selectedUser = member;
      state.data.showAvailibilityModal = true;
    }
    function deleteAmountType(data: any, item: any) {
      console.log("Triggered Type >> ", item);
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.amount_types = phaseDetails.value.amount_types.filter(
        (e: any) => e != item
      );
    }
    function selectedMemberIndex(memberIndex: number) {
      state.data.selectedMemberIndex = memberIndex;
    }
    function deleteRole(sata: any, item: any) {
      console.log("Triggered Delete Role >> ", item);

      phaseDetails.value.roles = phaseDetails.value.roles.filter(
        (e: any) => e != item
      );
    }
    function viewSelectedPhaseDetails() {
      eventBus.emit("user-todo-details",state.data.editTask.parent_phase.uuid)
      // router.push({
      //   name: "user-todo-details",
      //   params: { phaseId: state.data.editTask.parent_phase.uuid },
      // });
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
      viewSelectedPhaseDetails();
    }
    function selectedAmountTypeForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.amountType);
      selectedMember.member.amountType = selectedMember.amountType;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.invite.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.amountType = selectedMember.amountType;
        }
      });
    }
    function addNewRole(newRole: any) {
      phaseDetails.value.roles = newRole;
    }
    function addNewAmountType(newAmountTypes: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.amount_types = newAmountTypes;
    }
    function selectedRoleForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.role);
      if (
        selectedMember.role &&
        selectedMember.role.permissions &&
        selectedMember.role.permissions.length
      ) {
        selectedMember.role.permissions.forEach((permission: any) => {
          permission.isChecked = false;
        });
      }
      selectedMember.member.role = selectedMember.role;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.invite.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.role = selectedMember.role;
        }
      });
    }
    function setNewSelectedMembers(fullName: string, email: string) {
      // if (state.data.invite.selectedMembers.length === 0) {
      // res.data.data.phase_members.forEach()
      // common.findEmailMatch
      state.data.invite.selectedMembers.push({
        name: fullName,
        amountType: "",
        isExist: common.findEmailMatch(email, phaseDetails.value.phase_members),
        amountTypeNumber: "",
        role: "",
        email: email,
        slots: [],
        // eslint-disable-next-line @typescript-eslint/camelcase
        approval_required: false,
      });
      // } else {
      //   state.data.invite.selectedMembers.forEach((member: any) => {
      //     if (
      //       member.email.toLowerCase() !=
      //       state.data.invite.existingMemberSelected.toLowerCase()
      //     ) {
      //       state.data.invite.selectedMembers.push({
      //     name: fullName,
      //     amountTypeNumber: "",
      //     role: "",
      //     email: email,
      //   });
      //     }
      //   });
      //   state.data.invite.selectedMembers =
      //             state.data.invite.selectedMembers.filter(
      //               (item: any, index: any) =>
      //                 state.data.invite.selectedMembers.indexOf(item) === index
      //             );
      // }
      state.data.invite.existingMemberSelected = "";
    }
    function changeDetailsByEmail() {
      if (
        !validationService.isValidEmail(
          state.data.invite.existingMemberSelected
        )
      )
        return false;
      const selectedEmail = state.data.invite.existingMemberSelected;
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.changeDetailsByEmail) return false;
      state.ui.isLoading.changeDetailsByEmail = true;
      ethitransService
        .detailsByemail(selectedEmail.toString())
        .then((res: any) => {
          console.log("Details by Email Check:>>> ", res.data.data);
          if (res.data.data.email && res.data.data.full_name) {
            setNewSelectedMembers(res.data.data.full_name, res.data.data.email);
          } else {
            setNewSelectedMembers("", selectedEmail);
          }

          state.ui.isLoading.changeDetailsByEmail = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function getToDoData() {
      const id: any = 0;
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.phaseDetails) return false;
      state.ui.isLoading.phaseDetails = true;
      ethitransService
        .addMemberInPhase(id.toString())
        .then((res: any) => {
          console.log("getToDoData Check:>>> ", res.data.data);
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.phase.edit_budget = JSON.parse(
            JSON.stringify(res.data.data.phase.budget)
          );
          if (res.data.data.smart_contract.end_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.smart_contract.end_date = common.localDateOnly(
              res.data.data.smart_contract.end_date
            );
          }
          if (res.data.data.smart_contract.start_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.smart_contract.start_date = common.localDateOnly(
              res.data.data.smart_contract.start_date
            );
          }
          if (res.data.data.phase.end_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.end_date = common.localDateOnly(
              res.data.data.phase.end_date
            );

            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.edit_end_date = JSON.parse(
              JSON.stringify(common.localDateOnly(res.data.data.phase.end_date))
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.phase.start_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.start_date = common.localDateOnly(
              res.data.data.phase.start_date
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.edit_start_date = JSON.parse(
              JSON.stringify(
                common.localDateOnly(res.data.data.phase.start_date)
              )
            );
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (
            res.data.data.phase.child_phases &&
            res.data.data.phase.child_phases.length
          ) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.child_phases.forEach((item: any) => {
              item.isChecked = false;
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.edit_budget = JSON.parse(JSON.stringify(item.budget));
              // eslint-disable-next-line @typescript-eslint/camelcase
              if (item.start_date) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.edit_start_date = JSON.parse(
                  JSON.stringify(common.localDateOnly(item.start_date))
                );
              }
              // eslint-disable-next-line @typescript-eslint/camelcase
              if (item.end_date) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.edit_end_date = JSON.parse(
                  JSON.stringify(common.localDateOnly(item.end_date))
                );
              }
            });
          }
          // if(res.data.data.members && res.data.data.members.length){

          // }
          if (res.data.data.permissions && res.data.data.permissions.length) {
            res.data.data.permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          // console.log("CHECK PC >>>", moment(res.data.data.smart_contract.start_date).format("Y-MM-DD"))
          // console.log("CHECK PC >>>", moment(res.data.data.smart_contract.end_date).format("Y-MM-DD"))
          state.data.minDate = res.data.data.smart_contract
            ? moment(res.data.data.smart_contract.start_date).format("Y-MM-DD")
            : "";
          state.data.maxDate = res.data.data.smart_contract
            ? moment(res.data.data.smart_contract.end_date).format("Y-MM-DD")
            : "";
          phaseDetails.value = res.data.data;
          state.ui.isLoading.phaseDetails = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function changeInviteMember() {
      // console.log("CHECK MSG", state.inviteMember);

      if (state.data.invite.newUsers) {
        // if(state.data.invite.existingMembers){
        if (validationService.isValidEmail(state.data.invite.newUsers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.selectedMembers.length === 0) {
            state.data.invite.selectedMembers.push({
              name: "",
              hourly: "",
              email: state.data.invite.newUsers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              error: false,
            });
          } else {
            state.data.invite.selectedMembers.forEach((member: any) => {
              // console.log("member", member);
              // console.log("member", state.data.invite.newUsers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.newUsers.toLowerCase()
              ) {
                state.data.invite.selectedMembers.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.newUsers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  error: false,
                });
              }
            });
          }
          state.data.invite.newUsers = "";
          state.data.invite.existingMembers = "";
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.selectedMembers = Array.from(
            new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).error,
            };
          });
          // state.data.invite.selectedMembers = Array.from(
          //   new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          // ).map((email) => {
          //   return {
          //     email: email,
          //     // eslint-disable-next-line @typescript-eslint/camelcase
          //     sub_contractor: false,
          //     member: false,
          //   };
          // });
        }
      } else {
        if (validationService.isValidEmail(state.data.invite.existingMembers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.selectedMembers.length === 0) {
            state.data.invite.selectedMembers.push({
              name: "",
              hourly: "",
              email: state.data.invite.existingMembers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              error: false,
            });
          } else {
            state.data.invite.selectedMembers.forEach((member: any) => {
              console.log("member", member);
              console.log("member", state.data.invite.existingMembers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.existingMembers.toLowerCase()
              ) {
                state.data.invite.selectedMembers.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.existingMembers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  error: false,
                });
              }
            });
          }
          state.data.invite.existingMembers = [];

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.selectedMembers = Array.from(
            new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).error,
            };
          });
        }
      }
      // state.data.invite.existedMembers = [];
    }
    function selectedMember(selectedMember: any) {
      state.data.selectedToRemove = selectedMember;
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
      // props.members
      // state.data.members = state.data.members.filter((e: any) => e != selectedMember);
    }
    function removeSelectedMember() {
      // props.members
      state.data.invite.selectedMembers =
        state.data.invite.selectedMembers.filter(
          (e: any) => e != state.data.selectedToRemove
        );
    }
    function hideConfirmation() {
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
    }
    function addMembersInPhases() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      const filterMembers = [] as any;
      state.data.invite.selectedMembers.forEach((member: any) => {
        const permissionList = [] as any;
        const timeSlots = [] as any;
        if (
          member.role &&
          member.role.permissions &&
          member.role.permissions.length
        ) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.isChecked) {
              permissionList.push(permission.id);
            }
          });
        }
        member.filteredSlots.forEach((slot: any) => {
          timeSlots.push({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_time: slot.formatedStart,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_time: slot.formatedEnd,
            title: slot.title,
          });
        });
        filterMembers.push({
          email: member.email,
          name: member.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          role_id: member.role.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          amount_type_id: member.amountType.id,
          amount: member.amountTypeNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: member.approval_required,
          permissions: permissionList,
          // eslint-disable-next-line @typescript-eslint/camelcase
          time_slots: timeSlots,
        });
      });

      // add validation here

      if (state.ui.isPostLoading.addMembersInPhases) return false;
      state.ui.isPostLoading.addMembersInPhases = true;

      const payLoad = {
        phases: [
          {
            uuid: phaseDetails.value.phase.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            priority_id: phaseDetails.value.phase.priority_id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: phaseDetails.value.phase.edit_start_date,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: phaseDetails.value.phase.edit_end_date,
            budget: phaseDetails.value.phase.edit_budget,
          },
        ],
        members: filterMembers,
      };
      ethitransService
        .addMembersInPhases(payLoad)
        .then((res: any) => {
          state.data.showSuccesCustomModal = true;
          // state.data.unitLists = res.data.data;
          state.ui.isPostLoading.addMembersInPhases = false;
        })
        .catch((error: any) => {
          state.ui.isPostLoading.addMembersInPhases = false;
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }

    function getPhaseDetails(id: any) {
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPhaseDetails) return false;
      state.ui.isLoading.getPhaseDetails = true;
      ethitransService
        .taskDetails(id.toString())
        .then((res: any) => {
          state.data.invite.selectedMembers =
            res.data.data.phase.allMembers.filter(
              (member: any) => member.invitation
            );
          res.data.data.phase.allMembers.forEach((member: any) => {
            if (member.invitation === false) {
              // If invitation is false, add to membersWithoutInvitation array
              state.data.invite.invitedMembers.push(member);
            }
          });
          state.data.minDate = moment(
            res.data.data.smart_contract.start_date
          ).format("Y-MM-DD");
          state.data.maxDate = moment(
            res.data.data.smart_contract.end_date
          ).format("Y-MM-DD");
          state.data.updateStartDate = res.data.data.phase.start_date;
          state.data.updateEndDate = res.data.data.phase.end_date;

          state.data.initialStartDate = JSON.parse(
            JSON.stringify(res.data.data.phase.start_date)
          );
          state.data.initialEndDate = JSON.parse(
            JSON.stringify(res.data.data.phase.end_date)
          );
          res.data.data.phase.localStartedDate = common.localDateOnly(
            res.data.data.phase.start_date
          );
          res.data.data.phase.localEndDate = common.localDateOnly(
            res.data.data.phase.end_date
          );
          if (res.data.data.attachments) {
            res.data.data.attachments.forEach((attachment: any) => {
              // attachment.file_size
              attachment.activeLink =
                //   config.liveImageBaseUrl + attachment.file_link;
                attachment.convertedFileSize = common.formatBytes(
                  parseInt(attachment.file_size)
                );
              // console.log("HELLO ATTACHMENT >>> ", attachment.activeLink)
            });
          }

          res.data.data.phase.child_phases.forEach((child: any) => {
            child.isChecked = false;
            if (child.start_date) {
              child.localStartedDate = common.localDateOnly(child.start_date);
            } else {
              child.localStartedDate = "";
            }
            if (child.end_date) {
              child.localEndDate = common.localDateOnly(child.end_date);
            } else {
              child.localEndDate = "";
            }
          });
          res.data.data.phase.budget = common.formatCurrency(
            res.data.data.phase.budget
          );
          res.data.data.invoices = res.data.data.invoices
            ? res.data.data.invoices
            : {};
          state.data.editTask = res.data.data.phase;
          state.data.phaseDetail = res.data.data.phase.parent_phase;
          state.data.editAllData = res.data.data;
          state.data.editAllData.permissions.forEach((item: any) => {
            item.isChecked = false;
          });
          res.data.data.phase.parent_phase.startDate = common.localDateOnly(
            res.data.data.phase.parent_phase.start_date
          );
          res.data.data.phase.parent_phase.endDate = common.localDateOnly(
            res.data.data.phase.parent_phase.end_date
          );
          state.data.invite.selectedMembers.forEach((member: any) => {
            member.amountType = state.data.editAllData.amount_types.find(
              (obj: any) => obj.id === member.invitation.amount_type_id
            );
            member.role = state.data.editAllData.roles.find(
              (obj: any) => obj.id === member.invitation.role_id
            );
            member.role.permissions = JSON.parse(
              JSON.stringify(state.data.editAllData.permissions)
            );
            member.role.permissions.forEach((item: any) => {
              item.isChecked = false;
              member.invitation.phaseUserPermissions.forEach(
                (selected: any) => {
                  if (item.id === selected.id) {
                    item.isChecked = true;
                  }
                }
              );
            });

            member.amountTypeNumber = member.invitation.amount;
            if (
              member.invitation.timeSlots &&
              member.invitation.timeSlots.length
            ) {
              member.invitation.timeSlots.forEach((time: any) => {
                time.formatedStart = common.formatDateAndTimeSlot(time.start_time);
                time.formatedEnd = common.formatDateAndTimeSlot(time.end_time);
              });
            }
            // new
            if (member.invitation.timeSlots && member.invitation.timeSlots.length) {
              member.invitation.timeSlots.forEach((slot: any) => {
                slot.new = false;
              })
            }
            member.filteredSlots = member.invitation.timeSlots;
            member.name = member.full_name;
            member.slots = [];
          });
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getPhaseDetails = false;
        });
    }

    function updateTask() {
      console.log("IN")
      state.ui.error.errorList.updateTasks = [];
      state.ui.error.hasError = false;
      // if (!state.data.reason) {
      //   state.ui.error.errorList.updateTasks.push("reason is required");
      //   return;
      // }
      console.log("RESSONS")
      const filterMembers = [] as any;
      state.data.invite.selectedMembers.forEach((member: any) => {
        const permissionList = [] as any;
        const timeSlots = [] as any;
        if (
          member.role &&
          member.role.permissions &&
          member.role.permissions.length
        ) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.isChecked) {
              permissionList.push(permission.id);
            }
          });
        }
        member.filteredSlots.forEach((slot: any) => {
          timeSlots.push({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_time: slot.formatedStart,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_time: slot.formatedEnd,
            title: slot.title,
            isAllday: slot.isAllday,
            category: slot.category,
            notifications: slot.notifications,
            bgColor: slot.bg_color ||slot.bgColor,
          });
        });
        filterMembers.push({
          email: member.email,
          name: member.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          role_id: member.role.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          amount_type_id: member.amountType.id,
          amount: member.amountTypeNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: member.approval_required,
          permissions: permissionList,
          // eslint-disable-next-line @typescript-eslint/camelcase
          time_slots: timeSlots,
        });
      });
      console.log("FIltered MEMBERS")

      // add validation here

      // if (state.ui.isPostLoading.addMembersInPhases) return false;
      // state.ui.isPostLoading.addMembersInPhases = true;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: props.taskId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: common.standardizeDate(state.data.updateStartDate),
        // start_date: state.data.updateStartDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: common.standardizeDate(state.data.updateEndDate),
        // end_date: state.data.updateEndDate,
        reason: state.data.reason,
        members: filterMembers,
        // eslint-disable-next-line @typescript-eslint/camelcase
        notify_users: state.data.invite.individualNotificationUsers,
      };
      console.log("PAYLOAD CHECK >>> ", payLoad);
      // console.log("OLD START CHECK >>> ", state.data.updateStartDate);
      // console.log("OLD END CHECK >>> ", state.data.updateEndDate);
      // console.log("START CHECK >>> ", common.standardizeDate(state.data.updateStartDate));
      // console.log("END CHECK >>> ", common.standardizeDate(state.data.updateEndDate));
      ethitransService
        .taskUpdate(payLoad)
        .then((res: any) => {
          state.data.showSuccesCustomModal = true;
          console.log("Response Check >> ", res.data.data);
          // state.data.unitLists = res.data.data;
          state.ui.isPostLoading.addMembersInPhases = false;
        })
        .catch((error: any) => {
          state.ui.isPostLoading.addMembersInPhases = false;
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }

    onMounted(() => {
      getPhaseDetails(props.taskId);
    });

    return {
      state,
      filterByNew,
      deleteNotificationMember,
      changeNotificationMember,
      removeSelectedTimeSlot,
      activeUpdate,
      filteredTimeSlotRefToStartAndEndDate,
      activeTimeSlotForDelete,
      changedDetectedInDate,
      // filterSlots,
      clickEvent,
      selectedMemberIndex,
      createdEvent,
      deletedEvent,
      selectedTab,
      filteredEvents,
      findIndexById,
      checkWithRow,
      setMemberUpdatedTask,
      addMemberToTaskData,
      addMemberToTaskSuccess,
      selectedChecked,
      hideAddMemberToTaskModal,
      showAddMemberToTaskModal,
      setUpdatedTask,
      hideUpdateTaskModal,
      selectedTask,
      showTaskUpdateModal,
      filteredPhases,
      filteredChildPhases,
      hideAvaibilityModal,
      deleteRole,
      selectedMemberForCheckAvaibility,
      deleteAmountType,
      addNewAmountType,
      addNewRole,
      closeSuccessCustomModal,
      addMembersInPhases,
      selectedAmountTypeForMember,
      selectedRoleForMember,
      selectedMember,
      getToDoData,
      phaseDetails,
      hideConfirmation,
      changeInviteMember,
      changeDetailsByEmail,
      setNewSelectedMembers,
      removeSelectedMember,
      getPhaseDetails,
      updateTask,
      viewSelectedPhaseDetails,
    };
  },
});
