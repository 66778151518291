
import { defineComponent, reactive, onMounted, computed, ref, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import PriceInputField from '@/common/PriceInputField.vue'
import { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import moment from "moment";
import cloneDeep from 'lodash/cloneDeep';
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import CreateLineItemInJobEstimation from "./CreateLineItemInJobEstimation.vue";
import VoiceToText from "@/components/VoiceToText.vue";
import { useI18n } from 'vue-i18n';


import { config } from "@/Config";
export default defineComponent({
    name: "CreateJobEstimation",
    props: {
    editId: String,
  },
    components: {
        SuccessfulModalCustom,
        CreateLineItemInJobEstimation,
        PriceInputField,
        VoiceToText
    },
    setup(props,{emit}) {
        const { t } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const table = ref(); //reference to your table element
        const tabulator = ref(); //variable to hold your table
        const filterValue = ref();
        const defMarkUpType = ref();
        const isEditMode = ref(false);
        const {
            params: { projectId },
        } = useRoute();
        const isInvalid = ref(false);
        const state = reactive({
            tableData: [] as any,
            selectedRows: [] as any,
            projectId: projectId,
            estimationUUId: '' as any,
            selectUnitId: '' as any,
            selectSubItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectSubItem: '' as any,
            selectDeleteItem: '' as any,
            selectIndex: '' as any,
            selectItemId: '' as any,
            dataFor: '' as any,
            cloningSelectedItem: false,
            selectItems: [
                {
                    'id': 0,
                    'markup_type': defMarkUpType,
                    'cost': 0,
                    'markup': 0,
                    'quantity': '',
                    'tableSelected': false,
                    'isAllowance': false,
                }
            ] as any,
            data: {
                inputFrom: 'markUp',
                createDate: moment(new Date()).format("Y-MM-DD"),
                minDate: '',
                searchName: '' as any,
                lineItems: [] as any,
                unitLists: [] as any,
                title: '' as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
                showSuccesCustomModal: false,
                successFulMessage: '',
            },
            ui: {
                isLoading: {
                    isEdit: false,
                    getItemList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    addNewSubItem: false,
                    isSaveSend: false,
                    getData: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                    selectedButton: false,
                    createJobEstimation: false,
                },
                errors: {
                    errorList: {
                        createUnit: [] as any,
                        createJobEstimation: [] as any,
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                        unitAbbreviation: "",
                    },
                    hasError: false,
                    titleError: false
                },
            },
        });
        // const updateAllowance = () => {
        //     state.selectItems.forEach((item: any) => {
        //         if (item.isAllowance) {
        //             item.allowance = item.subTotal;
        //         }
        //     });
        // };

        // // Watch for changes in the isChecked property for all items
        // watch(
        //     state.selectItems,
        //     () => {
        //         updateAllowance();
        //     },
        //     { deep: true } // This ensures the watcher reacts to nested object changes
        // );

        function gotoJobEstimationSend() {
            // router.push({
            //     name: "send-job-estimation",
            //     params: { estimationId: state.estimationUUId },
            // });
            emit('send-job-estimation', state.estimationUUId);
        }

        function gotoJobEstimation() {
            router.push({
                name: "list-job-estimation",
                params: { projectId: projectId },
            });
            // emit('list-job-estimation');
        }

        function deleteItemShow(item: any) {
            state.selectDeleteItem = item;
            $("#deleteItem").modal("show");
        }
        function deleteItemClose() {
            state.selectDeleteItem = '';
            $("#deleteItem").modal("hide");
        }

        function closeSuccessCustomModal() {
            // $("#SuccessfulModalCustom").modal("hide");
            // console.log("CHECK TEH ITEM>>>> ", state.ui.isLoading.isSaveSend)
            if (state.ui.isLoading.isSaveSend) {
                gotoJobEstimationSend()
            } else {
                gotoJobEstimation();
            }
        }

        function validationCreateJobEstimation() {
            state.ui.errors.titleError = false;
            state.ui.errors.hasError = false;
            if (!state.data.title) {
                state.ui.errors.titleError = true;
                state.ui.errors.hasError = true;
            }
            state.selectItems.forEach((item: any) => {
                item.quantityError = false
                if (!item.quantity) {
                    item.quantityError = true
                    state.ui.errors.hasError = true;
                }
            });

            state.selectItems.forEach((item: any) => {
                item.unitError = false
                if (!item.unit) {
                    item.unitError = true
                    state.ui.errors.hasError = true;
                }
            });

            state.selectItems.forEach((item: any) => {
                item.costError = false
                if (!item.cost) {
                    item.costError = true
                    state.ui.errors.hasError = true;
                }
            });

        }

        function usdPriceFormate(data: any) {
            if (data != null) {
                return data.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                });
            }

        }


        function getItemList() {
            state.ui.isLoading.getItemList = true
            state.ui.isLoading.getData = true
            ethitransService
                .getActiveItemList(state.data.searchName)
                .then((res: any) => {
                    state.tableData = [];
                    res.data.data.mainitems.forEach((item: any) => {
                        const childData = [] as any;
                        item.subitems.forEach((subItem: any) => {
                            const subData = {
                                id: subItem.id,
                                name: subItem.name,
                                description: subItem.description,
                                unit: subItem.unit ? subItem.unit.name : '',
                                cost: usdPriceFormate(subItem.cost ? subItem.cost : 0),
                                markup: usdPriceFormate(subItem.markup ? subItem.markup : 0),
                            }
                            childData.push(subData)
                        });
                        const pushData = {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            unit: item.unit ? item.unit.name : '',
                            cost: usdPriceFormate(item.cost ? item.cost : 0),
                            markup: usdPriceFormate(item.markup ? item.markup : 0),
                            _children: childData,
                        };
                        state.tableData.push(pushData);
                    });
                    res.data.data.mainitems.forEach((item: any) => {
                        item.isChecked = false;
                        item.markup = item.markup ? item.markup : 0;
                        item.cost = item.cost ? item.cost : 0;
                        item.subTotal = item.cost ? item.cost : 0;
                        item.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === item.unit_id)[0];
                        if (item.markup_type == 1) {
                            const parCal = (parseFloat(item.cost) * parseFloat(item.markup)) / 100;
                            item.total = parCal + parseFloat(item.cost);
                        } else {
                            item.total = parseFloat(item.cost) + parseFloat(item.markup);
                        }
                        item.subitems.forEach((subItem: any) => {
                            subItem.isChecked = false;
                            subItem.mainName = item.name;
                            subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                            if (subItem.markup_type == 1) {
                                const parCal = (parseFloat(subItem.cost) * parseFloat(subItem.markup)) / 100;
                                subItem.total = parCal + parseFloat(subItem.cost);
                            } else {
                                subItem.total = parseFloat(subItem.cost) + parseFloat(subItem.markup);
                            }
                            subItem.subTotal = subItem.cost ? subItem.cost : 0;
                        });
                    });
                    state.data.lineItems = res.data.data.mainitems;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getItemList = false;
                    state.ui.isLoading.getData = false;
                });
        }

        function checkSubItem(item: any) {
            if (item.subitems) {
                item.subitems.forEach((subItem: any) => {
                    subItem.isChecked = item.isChecked;
                });
            }
        }
        function checkItem(item: any) {
            if (item.subitems) {
                const allChecked = item.subitems.every((subItem: any) => subItem.isChecked);
                item.isChecked = allChecked;
                state.ui.isPostLoading.selectedButton = true;
            }
        }

        function checkInputNumber(item: any) {
            const data = item.toString().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            return data;
        }



        const iconFormatter = (cell: any) => {

            const rows = tabulator.value.getRows();
            for (let i = 0; i < rows.length; i++) {
                const children = rows[i].getTreeChildren();
                for (let j = 0; j < children.length; j++) {
                    const child = children[j].getData();
                    if (state.selectedRows.includes(child['id'])) {
                        children[j].select();
                    }
                }
            }
            tabulator.value.selectRow(state.selectedRows);
            const row = cell.getRow();
            return row.getTreeParent() ? '' : `<i class="fa fa-plus"></i> ${t('add')}`;


        };
        function addSubItem(data: any) {
            state.selectItemId = '';
            state.selectItemId = data;
            state.dataFor = 'subItem';
            state.ui.isLoading.addNewSubItem = !state.ui.isLoading.addNewSubItem;
            $(`.job-item-dropdown${state.selectIndex}`).dropdown('toggle');
            $(`.job-item-dropdown${state.selectIndex}`).dropdown('hide');
            state.selectIndex = '';
        }
        function resizeTable() {
            if (state.selectItems.length > 0) {
                tabulator.value = new Tabulator(table.value, {

                    height: "311px",
                    layout: "fitColumns",
                    dataTree: true,
                    dataTreeStartExpanded: true,
                    dataTreeFilter: true, //disable child row filtering
                    // getData: true,
                    paginationSize: 20,
                    ajaxURL: `${config.apiBaseUrl}all/item`,
                    progressiveLoad: "scroll",
                    placeholder: "No Data Set",
                    ajaxProgressiveLoad: true,
                    ajaxProgressiveLoadScroll: 20,
                    progressiveLoadDelay: 500,
                    progressiveLoadScrollMargin: 500,
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("current_user_token")}`,
                        },
                    },
                    columns: [
                        // titleFormatter: "rowSelection",
                        {
                            formatter: "rowSelection", hozAlign: "center", width: 100, headerSort: false, cellClick: function (e: any, cell: any) {
                                cell.getRow().toggleSelect();
                            }
                        },
                        { title: t('name'), field: "name", sorter: "string", width: 200, headerFilter: "input", headerFilterPlaceholder: t('find_a_item') },
                        { title: t('description'), field: "description", width: 300, sorter: "string" },
                        { title: t('unit'), field: "unit", sorter: "string" },
                        { title: t('cost'), field: "cost", sorter: "string" },
                        { title: t('markup'), field: "markup", sorter: "string" },
                        {
                            title: t('action'), field: "naiconme", hozAlign: "center", formatter: iconFormatter, cellClick: function (e: any, cell: any) {
                                const row = cell.getRow();
                                const parentId = row.getData().uuid;
                                addSubItem(parentId);
                            },
                            tooltip: function (e: any, cell: any, onRendered: any) {
                                //e - mouseover event
                                //cell - cell component
                                //onRendered - onRendered callback registration function

                                const el = document.createElement("div");
                                el.style.backgroundColor = "white";
                                el.innerText = t('add_a_new_sub_item')

                                return el;
                            },
                        }], //define table columns


                });

            }

        }


        function closeSelected(index: any) {
            state.ui.isLoading.getData = true;
            state.data.lineItems.forEach((lineItem: any) => {
                if (tabulator.value.getSelectedData().find((item2: any) => item2.id === lineItem.id)) {
                    if (!state.selectedRows.includes(lineItem.id)) {
                        lineItem.isChecked = true;
                        lineItem.isNew = true;
                        state.selectedRows.push(lineItem.id);
                    }

                }
                lineItem.subitems.forEach((subItem: any) => {
                    if (tabulator.value.getSelectedData().find((item2: any) => item2.id === subItem.id)) {
                        if (!state.selectedRows.includes(subItem.id)) {
                            subItem.isChecked = true;
                            subItem.isNew = true;
                            state.selectedRows.push(subItem.id);
                        }
                    }
                })
            });

            state.data.lineItems.forEach((lineItem: any) => {
                lineItem.mainName = '';
                const found = state.selectItems.some((item: any) => item.id === lineItem.id);
                const emptyIndex = state.selectItems.findIndex((item: any) => Object.keys(item).length === 0 || item.id == 0);
                if (emptyIndex !== -1) {
                    state.selectItems.splice(emptyIndex, 1);
                }
                if (lineItem.isChecked && !found && lineItem.id != 0 && !state.selectItems.some((item: any) => item.sub_item_id === lineItem.id)) {
                    lineItem.quantity = 1;
                    //  // eslint-disable-next-line @typescript-eslint/camelcase
                    // lineItem.markup_type = defMarkUpType.value;
                    state.selectItems.push(lineItem);
                }
                lineItem.subitems.forEach((subItem: any) => {
                    subItem.mainName = lineItem.name;
                    const found = state.selectItems.some((item: any) => item.id === subItem.id);
                    const emptyIndex = state.selectItems.findIndex((item: any) => Object.keys(item).length === 0 || item.id == 0);
                    if (emptyIndex !== -1) {
                        state.selectItems.splice(emptyIndex, 1);
                    }
                    if (subItem.isChecked && !found && subItem.id != 0 && !state.selectItems.some((item: any) => item.sub_item_id === subItem.id)) {
                        subItem.quantity = 1;
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        //   subItem.markup_type = defMarkUpType.value;
                        state.selectItems.push(subItem);
                    }


                });
            });
            state.data.lineItems.forEach((item: any) => {
                item.isAllowance = false
            })
            // state.selectItems.forEach((item: any)=> {
            //     item.isAllowance = false,
            // })
            // state.selectedRows = state.selectItems.map((item: any) => item.id);
            if (state.selectItems.length == 0) {
                state.selectItems = [{
                    'id': 0,
                    'total': 0,
                    'markup_type': defMarkUpType.value,
                }];
            }
            state.ui.isLoading.getData = false
            $(`.job-item-dropdown${index}`).dropdown('toggle');
            $(`.job-item-dropdown${index}`).dropdown('hide')

        }

        function onToggleChooseItem(index: any) {
            state.selectIndex = '';
            state.selectIndex = index;
            state.ui.isLoading.getData = true;
            state.selectItems[index].tableSelected = true;
            setTimeout(() => {
                resizeTable();
                state.ui.isLoading.getData = false;
            }, 2000)
        }

        function cloneItem(index: any, item: any) {

            state.cloningSelectedItem = true;

            const newItem = cloneDeep(state.selectItems);

            const originalItem = cloneDeep(newItem[index]);
            originalItem.isCopy = true;

            const updatedItems = newItem
                .slice(0, index + 1)
                .concat(originalItem)
                .concat(newItem.slice(index + 1));

            // eslint-disable-next-line @typescript-eslint/camelcase
            state.selectItems = [];
            setTimeout(() => {
                state.selectItems = updatedItems;
                state.cloningSelectedItem = false;
            }, 0);

        }

        function getAllUnits() {

            state.ui.isLoading.getUnit = true
            state.ui.isLoading.getData = true;
            ethitransService
                .getUnitList()
                .then((res: any) => {
                    state.data.unitLists = res.data.data;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getUnit = false;
                    state.ui.isLoading.getData = false;
                });

        }

        function removeSelectedSubItem(item: any) {
            if (item.id) {
                ethitransService
                    .deleteJobEstimationItem(item.id)
                    .then((res: any) => {

                        //
                    })
                    .catch((error: any) => {
                        console.log(error);
                    })
                    .finally(() => {
                        // state.selectDeleteItem ='';               
                    });
            }
            item.isChecked = false;
            const selectedIndex = common.findIndex(state.selectItems, item);
            state.selectItems[selectedIndex].isChecked = false;
            state.selectItems = state.selectItems.filter((e: any) => e != item);
            state.selectDeleteItem = '';
            deleteItemClose();
        }

        function addMoreSubItems(item: any) {
            state.ui.isLoading.getData = true;
            if (Object.keys(item).length != 0) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                state.selectItems.push(JSON.parse(JSON.stringify({ id: 0, total: 0, markup: 0, cost: 0, markup_type: defMarkUpType.value, tableSelected: false, isAllowance: false })));
            }
            state.ui.isLoading.getData = false;
        }


        function calculateTotal(item: any) {
            const selectedIndex = common.findIndex(state.selectItems, item);
            state.selectItems[selectedIndex].cost = checkInputNumber(item.cost ? item.cost : 0);
            state.selectItems[selectedIndex].markup = checkInputNumber(item.markup ? item.markup : 0);
            const total = computed(() => {
                if (item.markup_type == 1) {
                    const prCal = ((state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0) * (state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0) * (state.selectItems[selectedIndex].markup ? parseFloat(state.selectItems[selectedIndex].markup) : 0)) / 100;
                    return prCal + ((state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0) * (state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0));
                } else {

                    return ((state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0) * (state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0)) + (state.selectItems[selectedIndex].markup ? parseFloat(state.selectItems[selectedIndex].markup) : 0);
                }

            });
            state.selectItems[selectedIndex].total = total;
            state.selectItems[selectedIndex].subTotal = ((state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0) * (state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0));

        }

        function copyTotalAmount(item: any) {
            const selectedIndex = common.findIndex(state.selectItems, item);
            state.selectItems[selectedIndex].allowance = state.selectItems[selectedIndex].subTotal;
            console.log('abc', state.selectItems[selectedIndex].allowance);
        }

        function addUnitShow() {
            $("#addUnite").modal("show");
        }
        function addUniteClose() {
            state.ui.errors.validationErrorList.unitName = "";
            state.ui.errors.validationErrorList.unitAbbreviation = "";
            state.data.unit.abbreviation = "";
            state.data.unit.name = "";
            $("#addUnite").modal("hide");
            state.ui.isLoading.isUnitEdit = false;
        }

        function changeMarkupType(index: any, value: any) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.selectItems[index].markup_type = value;

            calculateTotal(state.selectItems[index]);
        }

        function changeUnit(index: any, value: any) {
            console.log(index, value);
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.selectItems[index].unit_id = value.id;
            state.selectItems[index].unit = value;
        }

        function editUnit(item: any) {
            state.data.unit.name = item.name;
            state.data.unit.abbreviation = item.abbreviation;
            state.selectUnitId = item.id;
            state.ui.isLoading.isUnitEdit = true;
            addUnitShow();
        }

        function addNewUnit(item: any) {
            state.selectSubItemIndex = '';
            state.selectSubItemIndex = common.findIndex(state.selectItems, item);
            addUnitShow();
        }

        function deleteUnitList(item: any, unitList: any) {
            state.selectSubItemIndex = '';
            state.selectSubItemIndex = common.findIndex(state.selectItems, item);
            ethitransService
                .deleteUnite(unitList.id)
                .then((res: any) => {
                    if (state.selectItems[state.selectSubItemIndex].selectUnit) {
                        state.selectItems[state.selectSubItemIndex].selectUnit = '';
                    }
                    state.data.unitLists = res.data.data;


                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.getUnit = false;
                });

        }

        function updateMarkupType(item: any) {
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                markup_type: item,
            };
            ethitransService
                .updateMarkupType(payLoad)
                .then((res: any) => {
                    defMarkUpType.value = res.data.data.markup_type;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.getUnit = false;
                });

        }

        function deleteUnit(item: any, unitList: any) {
            const result = window.confirm('Do you want to delete?');
            if (result) {
                deleteUnitList(item, unitList);
                console.log('User clicked "Yes"');
            } else {

                console.log('User clicked "No"');
            }
        }


        function createUnitValidation() {
            state.ui.errors.validationErrorList.unitName = "";
            if (!state.data.unit.name) {
                state.ui.errors.validationErrorList.unitName = "Ener valid unit";
                state.ui.errors.hasError = true;
            }
            // if (!state.data.unit.abbreviation) {
            //     state.ui.errors.validationErrorList.unitAbbreviation = "Ener valid abbreviation";
            //     state.ui.errors.hasError = true;
            // }
        }

        function createUnit() {
            state.ui.isPostLoading.addUnite = false;
            state.ui.errors.hasError = false;
            createUnitValidation();
            if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
            const payLoad = {

                name: state.data.unit.name,
                abbreviation: state.data.unit.abbreviation,

            };
            state.ui.isPostLoading.addUnite = true;
            ethitransService
                .createUnit(payLoad)
                .then((res: any) => {
                    state.selectItems[state.selectSubItemIndex].unit = res.data.data[0];
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.selectItems[state.selectSubItemIndex].unit_id = res.data.data[0].id;

                    state.data.unitLists = res.data.data;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createUnit)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createUnit.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.addUnite = false;
                    addUniteClose();

                });
        }

        function updateUnit() {
            state.ui.isPostLoading.addUnite = false;
            state.ui.errors.hasError = false;
            createUnitValidation();
            if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
            const payLoad = {

                name: state.data.unit.name,
                abbreviation: state.data.unit.abbreviation,

            };
            state.ui.isPostLoading.addUnite = true;
            ethitransService
                .updateUnit(state.selectUnitId, payLoad)
                .then((res: any) => {
                    state.data.unitLists = res.data.data;

                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createUnit)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createUnit.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.addUnite = false;
                    state.selectUnitId = '';
                    addUniteClose();

                });
        }

        const total = computed(() => {

            const total = state.selectItems.reduce((total: any, item: any) => total + item.total, 0);
            return total ? total : 0;

        })


        const totalMarkUP = computed(() => {
            return state.selectItems.reduce((total: any, item: any) => {
                if (item.markup_type == 1) {
                    const tot = total + (parseFloat(item.cost) * parseFloat(item.markup) * parseFloat(item.quantity)) / 100;
                    // return tot ? common.usdPriceFormate(tot) : common.usdPriceFormate(0);
                    return tot ? tot : 0;

                } else {
                    const tot = (total + parseFloat(item.markup));
                    return tot ? tot : 0;
                    // return tot ? common.usdPriceFormate(tot) : common.usdPriceFormate(0);
                }
            }, 0);
        })

        function clearAllJobItem() {
            state.data.lineItems.forEach((item: any) => {
                item.isChecked = false;
                item.subitems.forEach((subItem: any) => {
                    subItem.isChecked = false;
                });
            });
            state.selectItems = [{}];
        }


        function creatJobEstimation() {
            state.ui.isPostLoading.createJobEstimation = false;
            state.ui.errors.hasError = false;
            validationCreateJobEstimation();
            if (state.ui.isPostLoading.createJobEstimation || state.ui.errors.hasError) return false;
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                project_id: state.projectId,
                items: state.selectItems,
                title: state.data.title,

            };
            state.ui.isPostLoading.createJobEstimation = true;
            ethitransService
                .createJobEstimation(payLoad)
                .then((res: any) => {
                    console.log(res);
                    state.estimationUUId = res.data.data.uuid;
                    state.data.successFulMessage = t('create_job_estimation_successfully');
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createJobEstimation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createJobEstimation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.createJobEstimation = false;

                });
        }

        function addNewSubItem(index: any) {
            state.selectItemId = '';
            state.ui.isLoading.addNewSubItem = !state.ui.isLoading.addNewSubItem
            $(`.job-item-dropdown${index}`).dropdown('toggle');
            $(`.job-item-dropdown${index}`).dropdown('hide');

            // $('.job-item-dropdown').dropdown('toggle');
            // $('.job-item-dropdown').dropdown('hide')
        }

        function saveItem() {
            state.ui.isLoading.addNewSubItem = !state.ui.isLoading.addNewSubItem
            getItemList();
            getAllUnits();
        }

        function getJobestimationDetail() {
            state.ui.isLoading.getItemList = true
            ethitransService
                .getJobestimationDetail(props.editId)
                .then((res: any) => {
                    state.selectItems = [];
                    state.data.title = res.data.data.title;
                    state.data.createDate = moment(res.data.data.created_date).format("Y-MM-DD");
                    res.data.data.items.forEach((subItem: any) => {
                        subItem.isChecked = true;
                        subItem.isAllowance = false;
                        subItem.mainName = subItem.main_item_name;
                        subItem.subTotal = parseFloat(subItem.cost) * parseFloat(subItem.quantity);
                        subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                        if (subItem.markup_type == 1) {
                            const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
                            subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                        } else {
                            subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
                        }
                    });
                    state.selectItems = res.data.data.items;
                    state.selectedRows = state.selectItems.map((item: any) => item.main_item_id);
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getItemList = false;
                });
        }

        function updateJobEstimation() {
            state.ui.isPostLoading.createJobEstimation = false;
            state.ui.errors.hasError = false;
            validationCreateJobEstimation();
            if (state.ui.isPostLoading.createJobEstimation || state.ui.errors.hasError) return false;
            const newItem = [] as any;
            const oldItem = [] as any;
            state.selectItems.forEach((item: any) => {
                if (item.isNew || item.isCopy) {
                    newItem.push(item);
                } else {
                    oldItem.push(item);
                }
            });
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                project_id: state.projectId,
                // eslint-disable-next-line @typescript-eslint/camelcase
                estimation_id: props.editId,
                title: state.data.title,
                items: oldItem,
                // eslint-disable-next-line @typescript-eslint/camelcase
                new_item: newItem,

            };
            state.ui.isPostLoading.createJobEstimation = true;
            ethitransService
                .updateJobEstimation(payLoad)
                .then((res: any) => {
                    state.estimationUUId = res.data.data.uuid;
                    state.data.successFulMessage = "Update Job Estimation Successfully.";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createJobEstimation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createJobEstimation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.createJobEstimation = false;

                });
        }

        function saveSend() {
            if (state.ui.isLoading.isEdit) {
                state.ui.isLoading.isSaveSend = true;
                updateJobEstimation();
            } else {
                state.ui.isLoading.isSaveSend = true;
                creatJobEstimation();
            }

        }

        onBeforeRouteLeave((to, from) => {
            addUniteClose()
            deleteItemClose()
            $("#SuccessfulModalCustom").modal("hide");
        })

        function startRecording(index: any, newValue: any) {
            state.selectItems[index].description = state.selectItems[index].description + ' ' + newValue;
        }
        function updateItem(index: any, newValue: any) {

            state.selectItems[index].cost = newValue.replace(/[$,]/g, '')
        }

        function updateItemMarkup(index: any, newValue: any) {
            state.selectItems[index].markup = newValue.replace(/[$,]/g, '');
        }

        function updateItemAllowance(index: any, newValue: any) {
            state.selectItems[index].allowance = newValue.replace(/[$,]/g, '');
        }

        function gotoCreateLineItem() {
            router.push({
                name: "list-line-item",
            });
        }

        const formatCurrency = (value: any) => {
            if (value && typeof value === 'string') {
                const numericValue = parseFloat(value.replace(/[^\d.]/g, '')) || 0;
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                return formatter.format(numericValue);
            } else {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                return formatter.format(value);
            }
            // Format as currency using Intl.NumberFormat



        };
        const formatPrice = (index: any) => {
            // Update the price format when the input changes
            state.selectItems[index].cost = formatCurrency(state.selectItems[index].cost);
        };



        const applyFilter = () => {
            if (state.data.searchName === '') {
                // If filter is empty, reset to original data
                tabulator.value.setData(state.tableData);
            } else {
                const currentData = tabulator.value.getData();
                const filteredData = currentData.filter((row: any) =>
                    Object.values(row).some((value) =>
                        String(value).toLowerCase().includes(state.data.searchName.toLowerCase())
                    )
                );
                tabulator.value.setData(filteredData);
            }
        };


        function userStatus() {

            const payLoad = {
                email: localStorage.getItem("current_user_email"),
            };
            ethitransService
                .userStatus(payLoad)
                .then((res: any) => {
                    defMarkUpType.value = res.data.data.markup_type;
                })
                .catch((error: any) => {
                    //
                })
                .finally(() => {
                    //   state.ui.isLoading.userStatus = true;
                });
        }

        function showAddDetails(index: any) {
            $("#addDetails" + index).modal("show");
        }
        onMounted(() => {
            userStatus();
            getItemList();
            getAllUnits();
            const url: any = window.location.href;
            const page: any = url.split("#/").pop().split("/")[0];
            if (props.editId) {
                state.ui.isLoading.isEdit = true
                getJobestimationDetail();
            }
            resizeTable();
        });
        // Method to handle checkbox change
        const handleCheckboxChange = (item: any, index: any, event: any) => {
            console.log("HELLO >>> ", item);
            console.log("INDEX >>> ", index);
            console.log("EVEN CHECK  >>> ", event);
            // Set the isChecked property based on the checkbox state
            item.isAllowance = event.target.checked;

            // If checked, set the Allowance to subTotal
            if (item.isAllowance) {
                state.selectItems[index].allowance = item.subTotal;
                state.selectItems[index].isAllowance = event.target.checked;
            } else {
                // Optionally, reset Allowance if unchecked (you can define your own behavior here)
                state.selectItems[index].allowance = 0;
                state.selectItems[index].isAllowance = event.target.checked;
            }
        };
        return {
            state,
            handleCheckboxChange,
            // updateAllowance,
            getItemList,
            checkSubItem,
            checkItem,
            closeSelected,
            removeSelectedSubItem,
            addMoreSubItems,
            checkInputNumber,
            calculateTotal,
            addUnitShow,
            addUniteClose,
            changeMarkupType,
            changeUnit,
            addNewUnit,
            editUnit,
            deleteUnitList,
            deleteUnit,
            createUnit,
            updateUnit,
            total,
            totalMarkUP,
            clearAllJobItem,
            creatJobEstimation,
            gotoJobEstimation,
            closeSuccessCustomModal,
            addNewSubItem,
            saveItem,
            validationCreateJobEstimation,
            getJobestimationDetail,
            updateJobEstimation,
            saveSend,
            gotoJobEstimationSend,
            deleteItemClose,
            deleteItemShow,
            updateItem,
            updateItemMarkup,
            updateItemAllowance,
            usdPriceFormate,
            cloneItem,
            formatPrice,
            formatCurrency,
            tabulator,
            table,
            resizeTable,
            applyFilter,
            filterValue,
            onToggleChooseItem,
            iconFormatter,
            addSubItem,
            gotoCreateLineItem,
            updateMarkupType,
            userStatus,
            defMarkUpType,
            copyTotalAmount,
            showAddDetails,
            startRecording,
            isEditMode
        };
    },
});
