
import { defineComponent, reactive, onMounted, computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
// import TaxDisplay from "@/components/todo/TaxDisplay.vue";
import PriceInputField from "@/common/PriceInputField.vue";

import moment from "moment";

// import { Data } from "@/components/todo/AddMember.ts";

import { config } from "@/Config";
import Pusher from "pusher-js";

export default defineComponent({
  name: "GeneratedInvoice",
  props:{
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    // TaxDisplay,
    ErrorList,
    PriceInputField,
    SuccessfulModalCustom,
  },
  setup(props, {emit}) {
    const disableDiscountInput = ref(false);
    const router = useRouter();
    const routerQuery = useRoute();
    // const {
    //   params: { id },
    // } = useRoute();
    const state = reactive({
      data: {
        activeUser: "" as any,
        breadcrumb: [] as any,
        invoices: [] as any,
        invoiceReceiverList: [] as any,
        invoiceDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          to_role: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_items: [] as any,
        } as any,
        addItemInInvoice: false,
        isPayActive: false,
        stripeSuccess: false,
        isIndividual: false,
        showSuccesCustomModal: false,
        stripeAccount: false,
        successFulMessage: "",
        selectedInvoice: {} as any,
        invoiceBillTo: {
          id: 0,
          email: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          full_name: "",
        } as any,
        stripeUrl: "",
        invoice: {
          total: 0,
          readTotal: "",
          subTotal: 0,
          readSubTotal: "",
          tax: 0,
          discount: 0,
          status: 1,
          mode: 1,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getInvoiceByPhaseOrTask: [] as any,
            invoiceReceiverList: [] as any,
          },
        },
        isLoading: {
          getInvoiceByPhaseOrTask: false,
          stripeAccountCheck: false,
          phaseInvoiceListById: false,
        },
        isPostLoading: {
          showInvoiceReceiverModal: false,
          generateInvoice: false,
          stripeAccount: false,
          confirmPayment: false,
          saveAsDraft: false,
          insertSelectedInvoice: false,
        },
        isActive: {
          createNewInvoice: false,
        },
      },
    });

    watch(() => state.data.invoice.discount, (newVal: any, oldVal: any) => {
  disableDiscountInput.value = newVal >= state.data.invoice.subTotal;
});
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceTotal = computed(() => {
      return Number(state.data.invoice.total).toFixed(2);
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceCalculate = computed(() => {
      if (
        // eslint-disable-next-line @typescript-eslint/camelcase
        state.data.invoiceDetails.invoice_items &&
        state.data.invoiceDetails.invoice_items.length
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.subTotal = 0;
        state.data.invoiceDetails.invoice_items.forEach((invoice: any) => {
          const calculation = invoice.cost * invoice.quantity;
          invoice.readAmount = `$${Number(calculation).toFixed(2)}`;
          invoice.amount = calculation;
          const number = invoice.amount;
          state.data.invoice.subTotal += number;
          state.data.invoice.readSubTotal = `$${Number(
            state.data.invoice.subTotal
          ).toFixed(2)}`;
        });
        const str: any = state.data.invoice.discount ? state.data.invoice.discount : 0;
        let discount = 0;
        if (typeof str === "string") {
          console.log("Variable is a string");
          discount = parseFloat(str.replace("$", ""));
        } else {
          discount = str ? str : 0;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.total =
          state.data.invoice.subTotal +
          (state.data.invoice.subTotal * state.data.invoice.tax) / 100 -
          discount;

        return state.data.invoiceDetails.invoice_items;
      }
    });
    // const invoiceSubtotal = invoiceCalculate.value.reduce((accumulator: any, selected: any)=> {
    //   return accumulator + selected.amount;
    // }, 0)
    // eslint-disable-next-line vue/return-in-computed-property
    const isAuthEdit = computed(() => {
      // if(state.data.invoiceDetails.draft_uuid) return false;
      if (state.ui.isActive.createNewInvoice) return true;
      if (state.data.invoiceDetails.generated === 1) return false;
      if (state.data.invoiceDetails && state.data.invoiceDetails.user_role) {
        // ROLE  1 = owner,4 = subcontractor, 2 = payer, 3 = member,
        if (state.data.invoiceDetails.user_role === 2) {
          // console.log("WOWOW ROLE");
          // billed_from && user_id
          // return true; // temporary
          return false;
        } else if (
          state.data.invoiceDetails.user_id ===
          state.data.invoiceDetails.billed_from
        ) {
          if (routerQuery.query.mode === "1") {
            return true;
          } else {
            return false;
          }
        }
        // } else if (
        //   state.data.invoiceDetails.user_role === 1 ||
        //   state.data.invoiceDetails.user_role === 4
        // ) {
        //   // console.log("HELLO");
        //   if (state.data.isIndividual) {
        //     // console.log("INDI TR");
        //     if (state.data.invoiceDetails.draft_uuid) {
        //       return false;
        //     } else {
        //       return true;
        //     }
        //   } else {
        //     // console.log("INDI FA");
        //     return false;
        //   }
        // }
      }
    });
    function updateItem(index: any, newValue: any) {
      state.data.invoiceDetails.invoice_items[index].cost = newValue;
    }
    function updateDiscount(newValue: any) {
      state.data.invoice.discount = newValue;
    }
    function checkInputNumber(item: any) {
      const data = item
        .toString()
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1");
      return data;
    }

    function calculateTotal(item: any) {
      console.log("CHECK THE VALUE >>>", item);
      const selectedIndex = common.findIndex(
        state.data.invoiceDetails.invoice_items,
        item
      );
      state.data.invoiceDetails.invoice_items[selectedIndex].cost =
        checkInputNumber(item.cost);
      // state.data.invoiceDetails.invoice_items[selectedIndex].quantity = checkInputNumber(item.quantity);
      // const total = computed(() => {
      //   // if (item.markup_type == 1) {
      //     const prCal = ((state.data.invoiceDetails.invoice_items[selectedIndex].cost ? parseFloat(state.data.invoiceDetails.invoice_items[selectedIndex].cost) : 0) * (state.data.invoiceDetails.invoice_items[selectedIndex].markup ? parseFloat(state.data.invoiceDetails.invoice_items[selectedIndex].markup) : 0)) / 100;
      //     return prCal + (parseFloat(item.cost) ? parseFloat(item.cost) : 0);
      //   // } else {

      //   //   return (state.data.invoiceDetails.invoice_items[selectedIndex].cost ? parseFloat(state.data.invoiceDetails.invoice_items[selectedIndex].cost) : 0) + (state.data.invoiceDetails.invoice_items[selectedIndex].markup ? parseFloat(state.data.invoiceDetails.invoice_items[selectedIndex].markup) : 0);
      //   // }

      // });
      // state.data.invoiceDetails.invoice_items[selectedIndex].amount = common.usdPriceFormate(total.value);
    }
    function validateInsertInvoice() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      // if (!state.data.invoiceBillTo.id) {
      const hasUncheckedInvoice = state.data.invoices.some((invoice: any) => {
        if (invoice.generated === 0) {
          // If generated is 0, then check isChecked
          return invoice.isChecked === false;
        }
        // If generated is not 0, return false to skip this invoice
        return false;
      });
      console.log("CHECKING >>>> ", hasUncheckedInvoice);
      if (hasUncheckedInvoice) {
        state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
          "Select atleast 1 Invoice."
        );
      }
      if (
        !state.ui.error.errorList.getInvoiceByPhaseOrTask &&
        !state.ui.error.errorList.getInvoiceByPhaseOrTask.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.getInvoiceByPhaseOrTask.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function insertSelectedInvoice() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      state.ui.isPostLoading.insertSelectedInvoice = false;
      validateInsertInvoice();
      if (
        state.ui.error.errorList.getInvoiceByPhaseOrTask &&
        state.ui.error.errorList.getInvoiceByPhaseOrTask.length > 0
      )
        return false;
      const checkedUuids = state.data.invoices
        .filter((invoice: any) => invoice.isChecked && invoice.id)
        .map((invoice: any) => invoice.id);
      console.log("CHECK INSERT >>> ", checkedUuids);
      if (checkedUuids.length === 0) return false;
      // invoiceList
      const data = {
        id: props.id,
        ids: checkedUuids,
      };
      console.log("PAYLOAD CHECK >>> ", data);
      if (state.ui.isPostLoading.insertSelectedInvoice) return false;
      ethitransService
        .invoiceList(data)
        .then((res: any) => {
          state.ui.isPostLoading.insertSelectedInvoice = false;
          // console.log("invoiceList Check:>>> ", res.data.data.invoice.invoice_items);
          if (res.data.data.invoice.invoice_items) {
            res.data.data.invoice.invoice_items.forEach((item: any) => {
              console.log("Insert >>> ", item);
              // invoice.cost * invoice.quantity;
              // item.cost = item.total;
              // item.quantity = 1;
              // item.quantity = 1;
              // item.cost = item.total ;
              state.data.invoiceDetails.invoice_items.push(item);
            });
            // Loop through each object in the array
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.data.invoiceDetails.invoice_items =
              state.data.invoiceDetails.invoice_items.filter((item: any) => {
                // Check if every key is empty
                return !(
                  item.amount === 0 &&
                  item.cost === 0 &&
                  item.quantity === 0 &&
                  item.title === ""
                );
              });
          }
          // state.data.invoiceReceiverList = res.data.data.receivers;
          // state.ui.isPostLoading.showInvoiceReceiverModal = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.invoiceReceiverList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.invoiceReceiverList.push(value[0]);
                }
              }
            });
        });
    }
    function hasMissingValues(invoiceItems: any) {
      const objectsWithEmptyValues = [];
      for (let i = 0; i < invoiceItems.length; i++) {
        const item = invoiceItems[i];
        const emptyKeys = [];
        if (item.amount === 0) {
          emptyKeys.push("amount");
        }
        if (item.cost === 0) {
          emptyKeys.push("cost");
        }
        if (item.quantity === 0) {
          emptyKeys.push("quantity");
        }
        if (item.title === "") {
          emptyKeys.push("title");
        }
        if (emptyKeys.length > 0) {
          const row =
            i === 0
              ? "first"
              : i === 1
              ? "second"
              : i === 2
              ? "third"
              : `${i + 1}th`;
          objectsWithEmptyValues.push(
            `The ${row} row has empty values ${emptyKeys.join(", ")}`
          );
        }
      }
      if (objectsWithEmptyValues.length > 0) {
        return objectsWithEmptyValues;
      }
      return false;
    }

    function cancelSelectedInvoice() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invoiceDetails.invoice_items = [];
      state.ui.isActive.createNewInvoice = !state.ui.isActive.createNewInvoice;
    }
    function validateGenerateInvoice() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (!state.data.invoiceBillTo.id) {
        state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
          "Billed To is required."
        );
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (hasMissingValues(state.data.invoiceDetails.invoice_items)) {
        const errors: any = hasMissingValues(
          state.data.invoiceDetails.invoice_items
        );
        // state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
        //   hasMissingValues(state.data.invoiceDetails.invoice_items)
        // );
        for (const item of errors) {
          state.ui.error.errorList.getInvoiceByPhaseOrTask.push(item);
        }
        console.log(
          "CHECK VALIDATION >>> ",
          typeof hasMissingValues(state.data.invoiceDetails.invoice_items)
        );
      }
      if (
        !state.ui.error.errorList.getInvoiceByPhaseOrTask &&
        !state.ui.error.errorList.getInvoiceByPhaseOrTask.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.getInvoiceByPhaseOrTask.length != 0) {
        state.ui.error.hasError = true;
      }
    }

    function showGenerateConfirmation() {
      validateGenerateInvoice();
      // state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      // state.ui.error.hasError = false;
      if (
        state.ui.error.errorList.getInvoiceByPhaseOrTask &&
        state.ui.error.errorList.getInvoiceByPhaseOrTask.length === 0
      ) {
        $("#generateConfirmationModal").modal("show");
      }
    }
    function hideGenerateConfirmation() {
      $("#generateConfirmationModal").modal("hide");
    }
    function redirectToRequestedDraftInvoice(
      invoiceId: string,
      selectedMode: string
    ) {
      router.push({
        name: "admin-billing-draft-invoice",
        params: { phaseId: props.id },
        // query: { mode: selectedMode },
        query: { mode: selectedMode, invoiceId: invoiceId },
      });
    }
    function downloadFile() {
      window.open(state.data.invoiceDetails.pdf_url, "_blank");
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      state.data.stripeSuccess = false;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function isAddItemInInvoice() {
      state.data.addItemInInvoice = false;
    }
    function addItemInInvoice() {
      state.data.addItemInInvoice = true;
      state.data.invoiceDetails.invoice_items.push({
        amount: 0,
        cost: 0,
        quantity: 0,
        title: "",
      });
      setTimeout(isAddItemInInvoice, 2000);
    }
    function createNewInvoice() {
      state.data.selectedInvoice = {};
      state.ui.isActive.createNewInvoice = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invoiceDetails.invoice_items = [];
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      state.data.invoices.forEach((item: any) => {
        item.isChecked = false;
      });
      addItemInInvoice();
    }
    function removeSelectedItemInInvoice(item: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invoiceDetails.invoice_items =
        state.data.invoiceDetails.invoice_items.filter((e: any) => e != item);
    }
    function choosePaymentMode() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (!state.data.invoiceDetails.uuid) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: state.data.invoiceDetails.uuid,
        amount: state.data.invoiceDetails.total,
      };
      console.log("Paylaod Check >>> ", payLoad);
      state.ui.isPostLoading.confirmPayment = true;
      ethitransService
        .payInvoiceByStripe(payLoad)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 1) {
            console.log("Project Todo Response Check:>>> ", res.data.data.link);
            window.location.replace(`${res.data.data.link}`);
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.confirmPayment = false;
        });
    }
    function invoiceSuccess() {
      state.data.successFulMessage = "Invoice Generated Successfully.";
      // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      if (routerQuery.query.account === "success") {
        router.push({
          name: "admin-billing-invoice",
          // params: { invoiceId: invoiceId },
          query: { account: "" },
        });
      }
    }
    function hideAllInvoiceGenerate() {
      $("#allInvoiceGenerate").modal("hide");
    }
    function generateInvoice(status: number) {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.generateInvoice) return false;
      if (state.ui.isPostLoading.saveAsDraft) return false;
      if (!props.id) return false;
      let discount: any = 0
      if (typeof state.data.invoice.discount === "string") {
        const localDiscount: any  = state.data.invoice.discount
           discount = Number(parseFloat(localDiscount.replace("$", "")));
        } else {
          discount = state.data.invoice.discount;
        }
      // state.data.invoiceBillTo
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        receiver_id: state.data.invoiceBillTo.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: props.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: state.data.invoiceDetails.uuid,
        items: invoiceCalculate.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sub_total: state.data.invoice.subTotal,
        tax: state.data.invoice.tax,
        discount: discount,
        total: state.data.invoice.total,
        status: status,
        // status: state.data.invoice.status,
      };
      console.log("ID CHECK  WITH PAYLOAD >>>", payLoad);
      if (state.data.invoice.status === 4) {
        state.ui.isPostLoading.saveAsDraft = true;
      } else {
        state.ui.isPostLoading.generateInvoice = true;
      }
      ethitransService
        .phaseGenerateInvoice(payLoad)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          hideAllInvoiceGenerate();
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateOnly(
              res.data.data.invoice.invoice_date
            );
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.new_items && res.data.data.new_items.length) {
            console.log("New Items");
            if (
              res.data.data.invoice &&
              res.data.data.invoice.invoice_items &&
              res.data.data.invoice.invoice_items.length
            ) {
              console.log("invoice items");
              res.data.data.new_items.forEach((item: any) => {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              });
            }
          }

          state.data.invoiceDetails = res.data.data.invoice;
          closeSuccessCustomModal();
          hideGenerateConfirmation();
          console.log("generateInvoice Response Check:>>> ", res.data.data);
          setTimeout(invoiceSuccess, 1000);
        })
        // .then((res: any) => {
        //   closeSuccessCustomModal();
        //   console.log("generateInvoice Response Check:>>> ", res.data.data);
        //   setTimeout(invoiceSuccess, 1000);
        // })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          if (state.data.invoice.status === 4) {
            state.ui.isPostLoading.saveAsDraft = false;
          } else {
            state.ui.isPostLoading.generateInvoice = false;
          }
        });
    }
    function redirectToStripeAccount() {
      state.ui.isPostLoading.stripeAccount = true;
      window.location.replace(`${state.data.stripeUrl}`);
      state.ui.isPostLoading.stripeAccount = false;
    }
    function showAllInvoiceGenerate() {
      $("#allInvoiceGenerate").modal("show");
    }

    function invoiceWithGenerated(isAllowed: number) {
      if (isAllowed === 0) {
        if (
          state.data.invoiceDetails.invoice_items &&
          state.data.invoiceDetails.invoice_items.length
        ) {
          const filteredInvoice: any = [];
          // invoice_uuid
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.invoiceDetails.invoice_items.forEach((item: any) => {
            if (!item.invoice_uuid || item.invoice_uuid === null) {
              filteredInvoice.push(item);
            }
          });
          console.log("CHECK Filtered INVOICE ", filteredInvoice);
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.invoiceDetails.invoice_items = filteredInvoice;
          generateInvoice(1);
        }
      }
      if (isAllowed === 1) {
        generateInvoice(1);
      }
    }
    function stripeAccountCheck() {
      // alert("HELLO");
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.stripeAccountCheck) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.getInvoiceByPhaseOrTask.length != 0
      )
        return false;
      state.ui.isLoading.stripeAccountCheck = true;
      ethitransService
        .stripeAccountCheck(state.data.invoiceDetails.uuid)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 0) {
            state.data.stripeUrl = res.data.data.link;
            console.log("Project Todo Response Check:>>> ", res.data.data);
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Stripe Account Checked";
          } else if (res.data.data.status === 1) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (
              state.data.selectedInvoice &&
              state.data.selectedInvoice.invoice_no != "All"
            ) {
              generateInvoice(1);
            } else {
              showAllInvoiceGenerate();
            }
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.stripeAccountCheck = false;
        });
    }
    // phaseInvoiceReceivedByPhaseId
    function getInvoiceByPhaseOrTask(selected: any) {
      const mode: number = selected.mode;
      let invoiceId = "";
      if (mode === 1) {
        invoiceId = selected.uuid;
      } else {
        invoiceId = props.id.toString();
      }
      // console.log("Choose Payment Method", invoiceId);
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;

      state.ui.isLoading.getInvoiceByPhaseOrTask = true;
      ethitransService
        // .invoiceByPhaseOrTask(phaseId.toString())
        .invoiceByPhaseOrTask(invoiceId.toString(), mode)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice && res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateOnly(
              res.data.data.invoice.invoice_date
            );
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.new_items && res.data.data.new_items.length) {
            console.log("New Items");
            if (
              res.data.data.invoice &&
              res.data.data.invoice.invoice_items &&
              res.data.data.invoice.invoice_items.length
            ) {
              console.log("invoice items");
              res.data.data.new_items.forEach((item: any) => {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              });
            }
          }
          res.data.data.breadcrumb.reverse();
          state.data.invoiceDetails = res.data.data.invoice
            ? res.data.data.invoice
            : {};
          state.data.breadcrumb = res.data.data.breadcrumb
            ? res.data.data.breadcrumb
            : [];
          if (
            state.data.invoiceDetails.invoice_items &&
            !state.data.invoiceDetails.invoice_items.length
          ) {
            addItemInInvoice();
          }
          if (
            localStorage.getItem("current_user_id") &&
            state.data.invoiceDetails.billed_from
          ) {
            const userId: any = localStorage.getItem("current_user_id");
            console.log("USER ID >>> ", userId);
            if (
              state.data.invoiceDetails.status === 1 ||
              state.data.invoiceDetails.status === 4
            ) {
              // if (
              //   parseInt(userId) !=
              //   parseInt(state.data.invoiceDetails.billed_from)
              // ) {
              state.data.isPayActive = true;
            } else {
              state.data.isPayActive = false;
            }
          }
          if (routerQuery.query.account === "success") {
            state.data.stripeSuccess = true;

            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Stripe Account Setup Completed.";
            // generateInvoice();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getInvoiceByPhaseOrTask = false;
        });
    }
    function invoiceeReceiverList() {
      state.ui.error.errorList.invoiceReceiverList = [];
      state.ui.error.hasError = false;
      // generateInvoice()
      if (state.ui.isPostLoading.showInvoiceReceiverModal) return false;
      state.ui.isPostLoading.showInvoiceReceiverModal = true;
      const data = {
        phase: props.id,
      };
      ethitransService
        .invoiceReceiverList(data)
        .then((res: any) => {
          console.log("invoiceReceiverList Check:>>> ", res.data.data);
          state.data.invoiceReceiverList = res.data.data.receivers;
          if (
            state.data.invoiceReceiverList &&
            state.data.invoiceReceiverList.length === 1
          ) {
            state.data.invoiceBillTo = state.data.invoiceReceiverList[0];
          }
          state.ui.isPostLoading.showInvoiceReceiverModal = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.invoiceReceiverList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.invoiceReceiverList.push(value[0]);
                }
              }
            });
        });
    }
    function phaseInvoiceListById() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.phaseInvoiceListById) return false;
      const payLoad: any = {
        id: props.id,
        status: "",
      };
      state.ui.isLoading.phaseInvoiceListById = true;
      ethitransService
        .phaseInvoiceReceivedByPhaseId(payLoad)
        .then((res: any) => {
          state.ui.isLoading.phaseInvoiceListById = false;
          invoiceeReceiverList();
          console.log(
            "phaseInvoiceReceivedByPhaseId Response Check:>>> ",
            res.data.data
          );

          res.data.data.reverse();
          state.data.invoices = res.data.data ? res.data.data : [];
          state.data.invoiceDetails = [];
          if (state.data.invoices && state.data.invoices.length) {
            state.data.invoices.forEach((invoice: any) => {
              invoice.mode = 1;
              invoice.isChecked = false;
              // eslint-disable-next-line @typescript-eslint/camelcase
              invoice.invoice_date = common.localDateAndTime(
                invoice.invoice_date
              );
            });
          }
          // if (routerQuery.query.mode === "1") {
          //   state.data.isIndividual = true;
          // } else {
          //   state.data.isIndividual = false;
          // }
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.invoices.unshift({
            // eslint-disable-next-line @typescript-eslint/camelcase
            invoice_no: "All",
            mode: 2,
            isChecked: false,
            // eslint-disable-next-line @typescript-eslint/camelcase
            invoice_date: "All Invoices For Phase",
          });
          // state.data.selectedInvoice = state.data.invoices[state.data.invoices.length-1].uuid
          // used in another
          // console.log("CHECK THE INVOICE ID >>> ", routerQuery.query.invoiceId)
          if (routerQuery.query.invoiceId) {
            const invoiceId: any = routerQuery.query.invoiceId;
            state.data.invoices.forEach((item: any) => {
              if (invoiceId === item.uuid) {
                state.data.selectedInvoice = item;

                // console.log("CHECK INVOICE >>>",invoiceId);
                // console.log("CHECK UUID >>>",item.uuid);
                getInvoiceByPhaseOrTask(state.data.selectedInvoice);
              }
            });
            // state.data.selectedInvoice = state.data.invoices.filter((e: any) => e.uuid === invoiceId);
            // console.log("QUERY >>>", state.data.selectedInvoice)
          } else {
            console.log("AUTOSET >>>");
            const invoiceId: any = state.data.invoices[1];
            state.data.selectedInvoice = invoiceId;
            // console.log("Length >>> ", 0);
            // console.log("hello >>> ", invoiceId);
            getInvoiceByPhaseOrTask(state.data.selectedInvoice);
            //   state.data.invoices[state.data.invoices.length - 1].uuid
            // );
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        });
    }
    function saveAsDraft() {
      state.data.invoice.status = 4;
      generateInvoice(4);
    }
    watch(
      // () => watchTour,
      () => state.data.addItemInInvoice,
      (newvalue: any, oldvalue: any) => {
        if (newvalue === true) {
          console.log("INVOICE CHANGED >>>", newvalue);
          state.data.invoiceDetails.status = 1;
          // toggleTourGuide();
        }
      }
    );
    onMounted(() => {
      phaseInvoiceListById();
      // getInvoiceByPhaseOrTask();
      state.data.activeUser = localStorage.getItem("current_user_info");
      state.data.activeUser = JSON.parse(state.data.activeUser);
    });
    return {
      state,
      disableDiscountInput,
      updateItem,
      updateDiscount,
      checkInputNumber,
      calculateTotal,
      validateInsertInvoice,
      hasMissingValues,
      validateGenerateInvoice,
      cancelSelectedInvoice,
      createNewInvoice,
      insertSelectedInvoice,
      hideGenerateConfirmation,
      showGenerateConfirmation,
      invoiceWithGenerated,
      showAllInvoiceGenerate,
      hideAllInvoiceGenerate,
      redirectToRequestedDraftInvoice,
      getInvoiceByPhaseOrTask,
      phaseInvoiceListById,
      isAddItemInInvoice,
      downloadFile,
      saveAsDraft,
      invoiceTotal,
      redirectToStripeAccount,
      stripeAccountCheck,
      closeSuccessCustomModal,
      choosePaymentMode,
      isAuthEdit,
      invoiceCalculate,
      addItemInInvoice,
      removeSelectedItemInInvoice,
      generateInvoice,
      invoiceSuccess,
    };
  },
});
